.task {
    padding: 12px;
    border: 1px solid transparentize($color-timberwolf, $inactive-opacity);
    background-color: transparentize($color-white, $inactive-opacity);
    margin: 0 0 20px;
    color: $color-slate;

    &.active {
        border: 1px solid $color-timberwolf;
        background-color: $color-white;

        .task-title {
            .progress,
            .task-title-text {
                opacity: 1;
            }
        }
        .task-content {
            max-height: 12000px;
        }
        .task-title-selection {
            opacity: 1;
        }
    }
    &.item {
        padding: 10px 30px 0 15px;
    }
    &.align-title {
        .task-content {
            margin-left: 26px;
        }
    }

    .task-content {
        position: relative;
        max-height: 0;
        margin: 15px 8px 0 68px;
        padding: 0;

        transition: all 0.5s;

        &.content-only {
            margin: 45px;
        }
        .primary-instructions {
            color: $color-elephant;
            margin: -10px 0 10px -20px;
            font-size: 15px;
        }
        textarea {
            max-width: 600px;
        }
    }
    .task-title {
        font-size: 18px;
        line-height: 1em;
        height: 33px;
        @include font-pn-light;
        color: $color-slate;
        text-transform: uppercase;

        .progress,
        .task-title-text {
            opacity: $inactive-opacity;
        }
        .progress {
            text-transform: none;
            margin: 0 15px 0 0;
            @include font-pn-semibold;
            i {
                font-style: normal;
            }
        }
        .help-tooltip {
            margin-left: 10px;
            vertical-align: 1px;
            .icon-help {
                width: 17px;
            }
        }
    }
    .task-title-selection {
        padding: 0 0 0 6px;
        @include font-pn-regular;
        text-transform: initial;

        opacity: $inactive-opacity;
    }
    .task-edit-link {
        margin: 0 0 0 20px;
        font-size: 16px;
        text-transform: capitalize;
    }
    .sub-task {
        margin: 0 0 45px;

        &:last-of-type {
            margin-bottom: 0;
        }
        .input-row,
        > label,
        .input-image-upload {
            margin-left: 20px;
            &.section-heading {
                margin-left: 0;
            }
        }
        .hide {
            display: none;
        }
    }
    .sub-task-title {
        font-size: 15px;
        line-height: 1em;
        padding: 0 0 5px;
        margin: 40px 0 25px;
        color: $color-blue-grey;
        border-bottom: 1px solid $color-stone;
        @include font-pn-bold;

        .help-tooltip {
            margin: 0 0 0 10px;
            line-height: 1em;
            height: 18px;
            width: 18px;
            vertical-align: middle;
        }
    }
    .indent {
        padding-left: 50px;
        .normal {
            font-weight: normal;
        }
    }
    .loading-message,
    .complete-message {
        text-align: center;
        margin: 0 0 30px -50px;
    }
    .loading-message {
        font-size: 19px;
        font-weight: bold;
        color: $color-elephant;

        .loader {
            margin: 0 10px -4px 0;
        }
    }
    .complete-message {
        color: $color-success;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: bold;

        &:before {
            font-family: $icon-font;
            content: $icon-success;
            text-transform: none;
            font-size: 18px;
            margin: 0 10px 0 0;
        }
    }
    ol.task-list {
        counter-reset: step-counter;
        margin: 30px 0 0;

        > li {
            $size: 20px;
            $indent: $size + 10;

            counter-increment: step-counter;
            position: relative;
            margin: 0 0 15px $indent;
            padding-top: 1px;

            .task-list-title {
                display: block;
                text-transform: uppercase;
                margin-bottom: 25px;
                color: $color-slate;
                font-size: 16px;

                .remove {
                    display: inline-block;
                    margin: 0 0 0 30px;
                    text-transform: capitalize;
                    font-size: 14px;
                }
            }
            &:before {
                content: counter(step-counter);
                position: absolute;
                top: -1px;
                left: 0;
                font-size: 12px;
                background-color: $color-midnight;
                color: $color-white;
                height: $size;
                width: $size;
                line-height: $size + 2;
                text-align: center;
                margin: 0 0 0 #{-$indent};
                font-weight: 100;

                border-radius: #{ceil($size/2)};
            }
        }
        &.divided {
            li {
                border-bottom: 1px solid $color-stratus;
                padding-bottom: 15px;
                margin-bottom: 30px;

                &:last-of-type {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }
    }
    .buttons {
        margin: 20px 0 0;
        text-align: right;

        @include button-spacing();
    }

    &.single-step {
        padding: 25px;
    }
}
