.mapped-attributes {
    &__from {
        background-color: #fcdcb4;
        border-radius: 3px;
        padding: 4px;
    }

    &__to {
        background-color: $color-neutral-90;
        border-radius: 3px;
        padding: 4px;
    }

    &__required {
        @include break-word;
        background-color: $color-accent-neutral-10;
        border-radius: 3px;
        color: $color-accent-neutral-90;
        font-size: $font-size-reduced;
        padding: 3px 5px 2px;
        text-transform: uppercase;
        display: inline-block;
    }
}
