.hero-chart {
    position: relative;
    min-height: 375px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px 25px 110px;
    background-color: $color-strong-neutral-10;
    text-align: center;
    margin: -25px -40px 0 -40px; // negative margins used to make the graphic full-width by negating the padding

    .recharts-wrapper {
        margin: 0 0 0 50%;
        transform: translateX(-50%);
        z-index: $zindex__elevated;
    }
    .recharts-tooltip-wrapper {
        border-radius: 5px !important;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    }

    &__greeting {
        color: $color-white;
        position: absolute;
        top: 25px;
        left: 25px;
        text-transform: uppercase;
        font-size: 18px;
        &--hidden {
            visibility: hidden;
        }
    }

    &__center-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-white;
        @include font-pn-light;
        margin: 0 0 15px;
    }

    &__title {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1em;
        color: $color-white;
    }

    &__value {
        font-size: 55px;
        line-height: 1em;
        min-height: 1em;
        margin: 5px 0;
        @include font-pn-semibold;
    }

    &__subtitle {
        font-size: 16px;
        line-height: 1em;
    }

    &__error {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-70%);
        text-align: center;
        color: $color-white;
    }

    &__error-text {
        font-size: 14px;
        line-height: 1.3em;
        max-width: 200px;
    }

    &__error-icon {
        font-size: 70px;
        margin: 0 0 $spacing-md;
    }

    &__top-chart,
    &__bottom-chart {
        max-width: 850px;
        margin: 0 auto;
    }

    &__top-chart {
        .recharts-tooltip-wrapper {
            margin-top: -5px;
        }
        .recharts-surface {
            overflow: visible;
        }
    }

    &__bottom-chart {
        margin-top: $spacing-xs;
    }

    &__rocker {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 366px;
    }

    &__stat-cards,
    &__dashboard-cards {
        margin-top: -73px;
    }

    .page-loader__box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-70%);
        margin: 0;
    }

    &--astro {
        $astro-stack: 'Helvetica Neue', Helvetica, 'Segoe UI', Roboto, sans-serif;
        background: linear-gradient(to bottom, #f4f6fc 0%, #e5e9f8 100%);
        font-family: $astro-stack;
        .hero-chart {
            &__title,
            &__value,
            &__greeting {
                color: #253746;
                font-weight: 700;
                font-family: $astro-stack;
            }
            &__greeting {
                text-transform: none;
                font-size: 23px;
            }
            &__subtitle,
            &__error {
                color: #515f6b;
                font-weight: 500;
                font-family: $astro-stack;
            }
            &__center-text {
                font-family: $astro-stack;
            }
            &__value {
                font-size: 50px;
            }
            &__subtitle {
                font-size: 15px;
            }
            &__rocker {
                box-shadow: 0 1px 14px rgba(#253746, 0.15);
                &[class*='sel']:after {
                    background-color: #303f5f;
                }
                .rocker-button__button {
                    font-family: $astro-stack;
                    color: #303f5f;
                    font-weight: 500;

                }
            }

        }
        .hero-tooltip {
            color: #3c5080;
            &__bottom-value {
                color: rgba(163, 19, 0, 0.6);
            }
        }


    }
}


.hero-tooltip {
    font-size: 14px;
    text-transform: uppercase;
    color: $color-accent-neutral-10;
    &__bottom-value {
        color: $color-charting-orange;
    }
}
