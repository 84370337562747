.ellipsis-loader-button {
    .icon-ellipsis {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
    }
    &.loading {
        color: transparent !important;
        cursor: default;

        .icon-ellipsis {
            display: block;
        }
        &.primary {
            &:hover {
                background-color: $color-active-blue;
            }
        }
        &:hover {
            color: transparent;
        }
    }

    &.primary,
    &.success,
    &.cancel {
        .icon-ellipsis {
            span,
            &:before,
            &:after {
                background-color: $color-white;
            }
            &.ie9 {
                background-image: url('#{$img-path}ellipsis.gif');
                opacity: 0.6;
            }
        }
    }
}
