
.rocker-button--chart-rocker {
    $button-height: 28px;
    $button-width: 80px;
    $bg-color: $color-strong-neutral-90;
    $border-radius: $button-height / 2;
    $selected-color: #193967;
    $transition-timing: 0.2s;

    background-color: transparent;

    height: $button-height;
    border: none;
    border-radius: $border-radius;
    padding: 1px;

    button,
    .help-tooltip > .help-tooltip-target > button {
        z-index: $zindex__elevated + 1;
        border: none;
        height: 100%;
        line-height: $button-height + 2;
        padding: 0;
        width: $button-width;
        color: $selected-color;
        font-size: 14px;
        background-color: transparent;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $bg-color;
        border-radius: $border-radius;
    }

    &.sel-0 > button:nth-of-type(1),
    &.sel-1 > button:nth-of-type(2),
    &.sel-2 > button:nth-of-type(3),
    &.sel-3 > button:nth-of-type(4),
    &.sel-4 > button:nth-of-type(5),
    &.sel-5 > button:nth-of-type(6),
    &.sel-6 > button:nth-of-type(7),
    &.sel-7 > button:nth-of-type(8),
    &.sel-8 > button:nth-of-type(9),
    &.sel-9 > button:nth-of-type(10),
    &.sel-10 > button:nth-of-type(11),
    &.sel-11 > button:nth-of-type(12),
    &.sel-12 > button:nth-of-type(13),
    &.sel-13 > button:nth-of-type(14),
    &.sel-14 > button:nth-of-type(15),
    &.sel-15 > button:nth-of-type(16),
    &.sel-16 > button:nth-of-type(17),
    &.sel-17 > button:nth-of-type(18),
    &.sel-18 > button:nth-of-type(19),
    &.sel-19 > button:nth-of-type(20) {
        transition: $transition-timing color ease-out;

        background-color: transparent;
        color: $color-white;
    }

    &.sel-0 > .help-tooltip:nth-of-type(1),
    &.sel-1 > .help-tooltip:nth-of-type(2),
    &.sel-2 > .help-tooltip:nth-of-type(3),
    &.sel-3 > .help-tooltip:nth-of-type(4),
    &.sel-4 > .help-tooltip:nth-of-type(5),
    &.sel-5 > .help-tooltip:nth-of-type(6),
    &.sel-6 > .help-tooltip:nth-of-type(7),
    &.sel-7 > .help-tooltip:nth-of-type(8),
    &.sel-8 > .help-tooltip:nth-of-type(9),
    &.sel-9 > .help-tooltip:nth-of-type(10),
    &.sel-10 > .help-tooltip:nth-of-type(11),
    &.sel-11 > .help-tooltip:nth-of-type(12),
    &.sel-12 > .help-tooltip:nth-of-type(13),
    &.sel-13 > .help-tooltip:nth-of-type(14),
    &.sel-14 > .help-tooltip:nth-of-type(15),
    &.sel-15 > .help-tooltip:nth-of-type(16),
    &.sel-16 > .help-tooltip:nth-of-type(17),
    &.sel-17 > .help-tooltip:nth-of-type(18),
    &.sel-18 > .help-tooltip:nth-of-type(19),
    &.sel-19 > .help-tooltip:nth-of-type(20) {
        .help-tooltip-target {
            button {
                transition: $transition-timing color ease-out;

                background-color: transparent;
                color: $color-white;
            }
        }
    }

    &.sel-0,
    &.sel-1,
    &.sel-2,
    &.sel-3,
    &.sel-4,
    &.sel-5,
    &.sel-6,
    &.sel-7,
    &.sel-8,
    &.sel-9,
    &.sel-10,
    &.sel-11,
    &.sel-12,
    &.sel-13,
    &.sel-14,
    &.sel-15,
    &.sel-16,
    &.sel-17,
    &.sel-18,
    &.sel-19 {
        &:after {
            transition: $transition-timing left ease-out;

            content: '';
            position: absolute;
            height: $button-height;
            width: $button-width;
            background-color: $selected-color;
            border-radius: $border-radius;
        }
    }

    &.sel-0 button:nth-of-type(1),
    &.sel-1 button:nth-of-type(2),
    &.sel-2 button:nth-of-type(3),
    &.sel-3 button:nth-of-type(4),
    &.sel-4 button:nth-of-type(5),
    &.sel-5 button:nth-of-type(6),
    &.sel-6 button:nth-of-type(7),
    &.sel-7 button:nth-of-type(8),
    &.sel-8 button:nth-of-type(9),
    &.sel-9 button:nth-of-type(10),
    &.sel-10 button:nth-of-type(11),
    &.sel-11 button:nth-of-type(12),
    &.sel-12 button:nth-of-type(13),
    &.sel-13 button:nth-of-type(14),
    &.sel-14 button:nth-of-type(15),
    &.sel-15 button:nth-of-type(16),
    &.sel-16 button:nth-of-type(17),
    &.sel-17 button:nth-of-type(18),
    &.sel-18 button:nth-of-type(19),
    &.sel-19 button:nth-of-type(20) {
        background: transparent;
    }

    @for $i from 0 through 20 {
        &.sel-#{$i} {
            &:after {
                left: 1px + $i*$button-width;
            }
        }
    }
}

.hero-chart__rocker {
    border-radius: 14px;
    &:before {
        background-color: $color-white;
    }
}

.rocker-button--icon-rocker {
    $button-height: 40px;
    $button-width: 50px;
    $border-radius: 3px;
    $bg-color: $color-strong-neutral-90;
    $selected-color: #193967;
    $transition-timing: 0.2s;

    background-color: transparent;
    height: $button-height;
    border: none;
    border-radius: $border-radius;
    padding: 1px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $bg-color;
        border-radius: $border-radius;
    }

    button,
    .help-tooltip > .help-tooltip-target > button {
        z-index: $zindex__elevated + 1;
        border: none;
        height: 100%;
        padding: 0;
        width: $button-width;
        line-height: $button-height + 2;
        color: $selected-color;
        font-size: 14px;
        background-color: transparent;
    }

    &.sel-0 > button:nth-of-type(1),
    &.sel-1 > button:nth-of-type(2),
    &.sel-2 > button:nth-of-type(3),
    &.sel-3 > button:nth-of-type(4),
    &.sel-4 > button:nth-of-type(5),
    &.sel-5 > button:nth-of-type(6),
    &.sel-6 > button:nth-of-type(7),
    &.sel-7 > button:nth-of-type(8),
    &.sel-8 > button:nth-of-type(9),
    &.sel-9 > button:nth-of-type(10),
    &.sel-10 > button:nth-of-type(11),
    &.sel-11 > button:nth-of-type(12),
    &.sel-12 > button:nth-of-type(13),
    &.sel-13 > button:nth-of-type(14),
    &.sel-14 > button:nth-of-type(15),
    &.sel-15 > button:nth-of-type(16),
    &.sel-16 > button:nth-of-type(17),
    &.sel-17 > button:nth-of-type(18),
    &.sel-18 > button:nth-of-type(19),
    &.sel-19 > button:nth-of-type(20) {
        transition: $transition-timing color ease-out;

        background-color: transparent;
        color: $color-white;
    }

    &.sel-0 > .help-tooltip:nth-of-type(1),
    &.sel-1 > .help-tooltip:nth-of-type(2),
    &.sel-2 > .help-tooltip:nth-of-type(3),
    &.sel-3 > .help-tooltip:nth-of-type(4),
    &.sel-4 > .help-tooltip:nth-of-type(5),
    &.sel-5 > .help-tooltip:nth-of-type(6),
    &.sel-6 > .help-tooltip:nth-of-type(7),
    &.sel-7 > .help-tooltip:nth-of-type(8),
    &.sel-8 > .help-tooltip:nth-of-type(9),
    &.sel-9 > .help-tooltip:nth-of-type(10),
    &.sel-10 > .help-tooltip:nth-of-type(11),
    &.sel-11 > .help-tooltip:nth-of-type(12),
    &.sel-12 > .help-tooltip:nth-of-type(13),
    &.sel-13 > .help-tooltip:nth-of-type(14),
    &.sel-14 > .help-tooltip:nth-of-type(15),
    &.sel-15 > .help-tooltip:nth-of-type(16),
    &.sel-16 > .help-tooltip:nth-of-type(17),
    &.sel-17 > .help-tooltip:nth-of-type(18),
    &.sel-18 > .help-tooltip:nth-of-type(19),
    &.sel-19 > .help-tooltip:nth-of-type(20) {
        .help-tooltip-target {
            button {
                transition: $transition-timing color ease-out;

                background-color: transparent;
                color: $color-white;
            }
        }
    }

    &.sel-0,
    &.sel-1,
    &.sel-2,
    &.sel-3,
    &.sel-4,
    &.sel-5,
    &.sel-6,
    &.sel-7,
    &.sel-8,
    &.sel-9,
    &.sel-10,
    &.sel-11,
    &.sel-12,
    &.sel-13,
    &.sel-14,
    &.sel-15,
    &.sel-16,
    &.sel-17,
    &.sel-18,
    &.sel-19 {
        &:after {
            transition: $transition-timing left ease-out;
            content: '';
            position: absolute;
            height: calc(#{$button-height} - 4px);
            width: calc(#{$button-width} - 4px);
            border-radius: $border-radius;
            background-color: #2a95cc;
            transform: translate(2px, 2px);
        }
    }

    &.sel-0,
    &.sel-1,
    &.sel-2,
    &.sel-3,
    &.sel-4,
    &.sel-5,
    &.sel-6,
    &.sel-7,
    &.sel-8,
    &.sel-9,
    &.sel-10,
    &.sel-11,
    &.sel-12,
    &.sel-13,
    &.sel-14,
    &.sel-15,
    &.sel-16,
    &.sel-17,
    &.sel-18,
    &.sel-19 {
        &:after {
            height: calc(#{$button-height} - 4px);
            width: calc(#{$button-width} - 4px);
            border-radius: $border-radius;
            background-color: #2a95cc;
            transform: translate(2px, 2px);
        }
    }

    &.sel-0 button:nth-of-type(1),
    &.sel-1 button:nth-of-type(2),
    &.sel-2 button:nth-of-type(3),
    &.sel-3 button:nth-of-type(4),
    &.sel-4 button:nth-of-type(5),
    &.sel-5 button:nth-of-type(6),
    &.sel-6 button:nth-of-type(7),
    &.sel-7 button:nth-of-type(8),
    &.sel-8 button:nth-of-type(9),
    &.sel-9 button:nth-of-type(10),
    &.sel-10 button:nth-of-type(11),
    &.sel-11 button:nth-of-type(12),
    &.sel-12 button:nth-of-type(13),
    &.sel-13 button:nth-of-type(14),
    &.sel-14 button:nth-of-type(15),
    &.sel-15 button:nth-of-type(16),
    &.sel-16 button:nth-of-type(17),
    &.sel-17 button:nth-of-type(18),
    &.sel-18 button:nth-of-type(19),
    &.sel-19 button:nth-of-type(20) {
        background: transparent;
    }

    @for $i from 0 through 20 {
        &.sel-#{$i} {
            &:after {
                left: 1px + $i*$button-width;
            }
        }
    }
}

.rocker-button--chart-rocker-small {
    $button-width: 64px;
    $button-height: 22px;
    @include font-pn-regular;
    font-size: 10px;
    height: $button-height;
    button {
        line-height: $button-height + 1;
        width: $button-width;
    }
    &.sel-0,
    &.sel-1,
    &.sel-2,
    &.sel-3,
    &.sel-4,
    &.sel-5,
    &.sel-6,
    &.sel-7,
    &.sel-8,
    &.sel-9,
    &.sel-10,
    &.sel-11,
    &.sel-12,
    &.sel-13,
    &.sel-14,
    &.sel-15,
    &.sel-16,
    &.sel-17,
    &.sel-18,
    &.sel-19,
    &.sel-20 {
        &:after {

            content: '';
            position: absolute;
            height: $button-height;
            width: $button-width;
        }
    }

    @for $i from 0 through 20 {
        &.sel-#{$i} {
            &:after {
                left: 1px + $i*$button-width;
            }
        }
    }
}

.line-chart {
    .recharts-line {
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}

.column-chart,
.line-chart,
.pie-chart {
    &__error {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -100%);
        color: $color-accent-neutral-10;
        font-size: 20px;
        text-transform: uppercase;
    }

    &__loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -100%);
        text-align: center;

        &-text {
            color: $color-accent-neutral-10;
            font-size: 13px;
            text-transform: uppercase;
            margin-top: $spacing-sm;
        }
    }
}

.heatmap {
    $cell-size: 20px;

    display: inline-block;
    text-align: center;

    &__row {
        display: table-row;
    }

    .help-tooltip,
    &__xlabel,
    &__ylabel,
    &__spacer {
        display: table-cell;
        border-style: solid;
        border-width: 0 1px 1px 0;
    }

    .help-tooltip {
        box-sizing: border-box;
        padding-top: 12px;
        position: relative;
        height: $cell-size;
        width: $cell-size;
        min-width: $cell-size;
        border-color: $color-white;
    }

    &__xlabel,
    &__ylabel,
    &__spacer {
        font-size: 12px;
        text-align: center;
        line-height: 1em;
        border-color: $color-white;
        background-color: $color-white;
    }

    &__xlabel {
        padding: 0 0 5px;
    }

    &__ylabel {
        padding: 0 15px 0 0;
        line-height: $cell-size - 1;
    }

    &__cell-color,
    .help-tooltip-target {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.chart-tooltip {
    &--align-center {
        text-align: center;
    }

    &--align-left {
        text-align: left;
    }

    &--align-right {
        text-align: right;
    }

    &__label {
        font-size: 11px;
        line-height: 1em;

        &--horizontal {
            text-align: center;
        }
    }

    &__values {
        &--horizontal {
            display: inline-flex;
            justify-content: space-between;

            & > *:not(:last-child) {
                margin-right: $spacing-sm;
            }
        }
    }

    &__value-container {
        margin-bottom: $spacing-sm;
        &:last-child {
            margin-bottom: 0;
        }

        &--horizontal {
            margin-bottom: 0;
        }
    }

    &__value {
        font-size: 23px;
        line-height: 1em;
        @include font-pn-regular;
        margin: 3px 0 0;
    }

    &__subtitle {
        font-size: 11px;
        line-height: 1em;
    }
}
