.color-picker-simple__popover-title {
    font-size: 18px;
    margin-top: $spacing-xs;
    color: $color-strong-neutral-10;
    font-weight: 600;
    margin-bottom: $spacing-sm;
}

.input-color-picker {
    position: relative;
    display: inline-block;

    &.color-picker-error {
        .color-picker:after {
            right: 30px;
        }
    }

    .color-picker-simple {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__label {
            .label-text {
                font-size: 13px;
                margin-top: $spacing-xs;
                color: $color-strong-neutral-10;
                text-transform: none;
                font-weight: 500;
                width: 75px;
                @include break-word;

                .label-container {
                    justify-content: center;
                    text-align: center;
                }
            }
        }

        &__dot {
            width: 70px;
            height: 70px;
            margin: 5px;
            border-radius: 6px;
            box-shadow: 0 2px 2px 0 rgba($color-black, 0.2);
            border-width: 0;
            position: relative;
            cursor: pointer;

            &:hover {
                &:after {
                    content: '';
                    width: 70px;
                    height: 70px;
                    padding: 6px;
                    border: 1px solid;
                    border-radius: 6px;
                    border-color: inherit;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &--small {
                height: 24px;
                width: 24px;

                &:hover {
                    &:after {
                        height: 24px;
                        width: 24px;
                        padding: 1px;
                    }
                }
            }
        }
    }

    .color-picker {
        position: relative;
        display: inline-block;

        &:after {
            position: absolute;
            content: 'v';
            font-family: $icon-font;
            font-size: 9px;
            text-transform: none;
            right: $spacing-sm - 1px;
            top: 11px;
            line-height: 1em;
            color: $color-midnight;
            padding: 5px;
            pointer-events: none;
            transition: all 0.3s;
        }
    }
    &.open {
        .color-picker {
            .colorpicker-container {
                display: block;
            }
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    label {
        visibility: visible;
        display: block;
    }

    .colors-label,
    .colors-label .label-text {
        margin: 0;
    }
    .colors-input {
        width: 137px;
        padding: 0 5px 0 39px;
        font-size: 14px;
        text-transform: uppercase;
    }
    .colors-swatch {
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 8px;
        width: 24px;

        > span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;

            border-radius: 3px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                box-sizing: border-box;
                border: 1px solid rgba($color-black, 0.1);

                background-clip: content-box;
                border-radius: 3px;
            }
        }
    }
    .colorpicker-container {
        //much taken from details tooltip styles
        $arrow-height: 15px;
        $arrow-offset: 30px;
        $arrow-width-offset: 6px;
        $border-radius: 4px;
        $box-shadow: 0 5px 15px 0 rgba($color-black, 0.2);
        $text-shadow: 0 -3px 7px rgba(128, 128, 128, 0.1);

        $border: 1px solid $color-timberwolf;


        display: none;
        position: absolute;
        top: calc(40px + #{$arrow-height});
        left: 0;
        z-index: $zindex__menu;
        padding: 10px;
        background: $color-white;



        box-shadow: $box-shadow;
        border-radius: $border-radius;
        transition: 0.3s ease;

        &:before {
            content: '▲';
            // text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
            text-shadow: $text-shadow;
            position: absolute;
            top: -$arrow-height;
            bottom: auto;
            left: $arrow-offset;
            right: auto;

            font-size: 20px;
            line-height: 1em;
            color: $color-white;

            transform: scale(1.9, 1);
        }
    }
}

.chrome-picker {
    box-shadow: none !important;
}
