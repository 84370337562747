.row-selector {

    &__columns,
    &__search-row {
        display: flex;
    }

    &__search {
        flex-grow: 1;
        margin-bottom: $spacing-lg;
        flex-basis: 0;

        &:last-child {
            margin-right: 0;
            flex-basis: 0;
        }
    }

    &__right-accessories {
        flex-grow: 1;
        flex-basis: 0;
        margin-bottom: $spacing-sm;
    }

    &__right-accessories + &__search {
        flex-grow: 0;
    }

    &--disabled {
        opacity: $inactive-opacity;

        .row-selector__available,
        .row-selector__added {
            pointer-events: none;

            user-select: none;
        }
    }

    &__category-selector {
        padding-left: $spacing-xs;
    }

    &__column-count {
        color: $color-neutral-20;
        display: inline-block;
        font-size: 15px;
        min-width: 25px - (2 * $spacing-xs);
        padding: 0 $spacing-xs;
        text-align: center;
        position: relative;
        z-index: $zindex__base;

        &:before {
            content: '';
            left: 0;
            right: 0;
            top: -4px;
            height: 24px - 2px;
            position: absolute;
            border: 1px solid $color-neutral-60;
            background: $color-white;
            z-index: $zindex__base - 1;

            border-radius: 7px;
        }
    }

    // needed for specifity to override inputs
    label.search,
    .input-search {
        width: 100%;
        padding-right: $spacing-md;
        input[type=text] {
            width: 100%;
        }
    }

    &__available,
    &__added {
        box-sizing: border-box;
        width: 50%;
        padding: 0 $spacing-lg 0 0;
        .items {
            border-top: 1px solid $color-rabbit-fur;
            border-bottom: 1px solid $color-rabbit-fur;
            .no-items {
                border: none;
            }
            .drag-drop-item {
                &:first-of-type {
                    .item {
                        border-top: none;
                    }
                }
            }
        }
    }
    &__column-header {
        display: flex;
        margin-bottom: $spacing-md;
    }

    &__column-title,
    &__filters {
        color: $color-slate;
        text-transform: uppercase;
        margin-right: 10px;
    }

    &__filters {
        margin-left: auto; //align filters to right in flexbox
        margin-right: 0;
    }

    &__filter-label {
        display: inline-block;
    }

    &__column-helptext {
        margin: 0 0 0 $spacing-xs;
    }

    &--resizable {
        .items {
            max-height: none !important;
            height: 400px;
            resize: vertical;
        }
    }
    .items {
        max-height: 400px;
        overflow: auto;

        > * {
            margin-bottom: $spacing-xs;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .no-items {
        padding: 14px 0;
        margin-bottom: 4px;
        text-align: center;
        font-size: 14px;
        border: 1px dashed $color-rabbit-fur;
        left: 0;
        right: 0;
        z-index: $zindex__elevated;
        background-color: $color-white;
        min-height: 50px;

        box-sizing: border-box;

        &.preview {
            border: 1px dotted $color-active-blue;
        }
    }

    /* LEGACY CSS FOR DEMO ROW */
    .item {
        position: relative;
        vertical-align: middle;
        background-color: $color-white;
        border: 1px solid $color-rabbit-fur;
        color: $color-text;
        margin: 0;
        padding: 15px 5px;

        box-sizing: border-box;
        @include grab-cursor;
        border-radius: 2px;

        .icon-grip {
            display: inline-block;
            margin-right: 20px;
            float: left;

            &:before {
                font-size: 16px;
                color: $color-accent-neutral-10;
            }
        }
        .item-image,
        .item-icon,
        .item-count {
            position: absolute;
        }
        &.item-decoration {
            .name {
                margin-left: 65px;
            }
        }
        .item-image {
            background-position: center;
            top: 8px;
            left: 30px;
            width: 32px;
            height: 32px;

            background-size: cover;
        }
        .item-icon {
            left: 30px;
            top: 13px;
            font-size: 24px;
        }
        .item-count.count {
            top: 15px;
            left: 30px;
        }
        .name {
            font-size: 18px;
            color: $color-midnight;
            margin-left: 0;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 65px);

            transition: all 0.3s;
        }
        a.button {
            position: absolute;
            right: 0;
            top: 8px;
        }
        button {
            position: absolute;
            right: 0;
            top: 10px;
            &.inline {
                &.plus,
                &.remove {
                    padding: 0;
                    right: 5px;
                    margin-right: 0;
                    &:hover {
                        border-color: $color-rhino;
                        &:before {
                            color: $color-active-blue;
                        }
                    }
                }
            }
        }
        &.preview {
            border: 1px dotted $color-active-blue;
            height: 49px;
        }
        &.item-disabled {
            background: $color-dolphin;
            cursor: default;
        }
    }
}

.selector-row {
    display: flex;
    border-radius: 2px;
    @include grab-cursor;
    align-items: center;
    padding: $spacing-sm;
    background-color: $color-white;
    border: 1px solid $color-strong-neutral-70;
    overflow: hidden;

    :first-child > & {
        margin-top: -1px;
    }

    &__grip,
    &__icon,
    &__image,
    &__count {
        margin-right: $spacing-md;
    }

    &__grip {
        color: $color-midnight;
        font-size: 17px;
    }

    &__icon {
        color: $color-elephant;
        font-size: 24px;
    }

    &__image {
        width: 32px;
        height: 32px;
        background-size: cover;
        background-position: center;
    }

    &__count {
        border-radius: 12px;
        color: $color-midnight;
        border: 1px solid $color-midnight;
        min-width: 23px;
        height: 23px;
        line-height: 25px;
        text-align: center;
    }

    &__labels {
        flex-grow: 1;
        overflow: hidden;
        margin: 0 35px 0 0;
    }

    &__name {
        color: $color-midnight;
        font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &--error {
        border: 1px solid $color-critical-red;
        .icon-grip,
        .selector-row__name,
        .selector-row__category {
            color: $color-critical-red;
        }
    }

    &__category {
        color: $color-elephant;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.row-selector__added {
    .selector-row {
        background-color: $color-midnight;
        border-color: $color-midnight;

        &__icon,
        &__grip,
        &__name,
        &__count {
            color: $color-white;
            border-color: $color-white;
        }

        &__category {
            color: $color-neutral-80;
        }

        &.selector-row--error {
            background-color: $color-white;
            border-color: $color-critical-red;
            .icon-grip,
            .selector-row__name,
            .selector-row__category {
                color: $color-critical-red;
            }
        }
    }
}

.selector-row--preview,
.row-selector__added
.selector-row--preview {
    border: 1px solid $color-active-blue;
    background-color: rgba($color-active-blue, 0.25);
    min-height: 30px;
}
