$icomoon-font-path: "../fonts/icons"; // KEEP - designates relative path to icons file where this is imported
$icomoon-font-family: "iconfont"; // KEEP - designates name of font

// This is necessary to make all icons work in IE. Sass will escape all of them by default,
// but IE is unable to handle uppercase characters without a specific text-transform property.
@function add-backslash($icon-code) {
    @return unquote('"') + unquote(str-insert($icon-code,'\\', 1)) + unquote('"');
}

$icon-star-outline: add-backslash("e902");
$icon-star: add-backslash("e901");
$icon-kebab: add-backslash("e900");
$icon-ldap: add-backslash("e947");
$icon-shield-arrow: add-backslash("e946");
$icon-key-three: add-backslash("e944");
$icon-key-two: add-backslash("e945");
$icon-open-lock: add-backslash("e943");
$icon-download-box: add-backslash("e942");
$icon-camera: add-backslash("e935");
$icon-email: add-backslash("e936");
$icon-ghost-disabled: add-backslash("e937");
$icon-ghost-timed-out: add-backslash("e938");
$icon-spinner-alt: add-backslash("e939");
$icon-error: add-backslash("e93a");
$icon-hour-glass: add-backslash("e93b");
$icon-safe: add-backslash("e93c");
$icon-success-alt: add-backslash("e93d");
$icon-ghost-error: add-backslash("e93e");
$icon-ghost-success: add-backslash("e93f");
$icon-incomplete: add-backslash("e940");
$icon-mobile: add-backslash("e941");
$icon-account: add-backslash("61");
$icon-admin-account: add-backslash("74");
$icon-alert: add-backslash("21");
$icon-alert-solid: add-backslash("e905");
$icon-approve: add-backslash("70");
$icon-apps: add-backslash("6c");
$icon-as: add-backslash("e906");
$icon-badge: add-backslash("62");
$icon-bar-chart: add-backslash("e90f");
$icon-bar-line-chart: add-backslash("e913");
$icon-beaker: add-backslash("e90a");
$icon-books: add-backslash("e907");
$icon-browser: add-backslash("e917");
$icon-bullet-list: add-backslash("e920");
$icon-cabinet: add-backslash("24");
$icon-calendar: add-backslash("43");
$icon-cert-connection: add-backslash("e925");
$icon-certificate: add-backslash("e908");
$icon-chat: add-backslash("e90e");
$icon-chat-square: add-backslash("e918");
$icon-circle: add-backslash("2e");
$icon-circle-o: add-backslash("4f");
$icon-clear: add-backslash("58");
$icon-clipboard: add-backslash("e909");
$icon-close: add-backslash("78");
$icon-close-arrow: add-backslash("5e");
$icon-code: add-backslash("e91d");
$icon-cog: add-backslash("67");
$icon-cog-filled: add-backslash("e919");
$icon-cogs: add-backslash("e91c");
$icon-collapse: add-backslash("63");
$icon-connection-status: add-backslash("e931");
$icon-curly-braces: add-backslash("e921");
$icon-delete: add-backslash("64");
$icon-desktop: add-backslash("e916");
$icon-details: add-backslash("e90b");
$icon-device: add-backslash("e90c");
$icon-directory: add-backslash("3d");
$icon-directory-hollow: add-backslash("23");
$icon-docs: add-backslash("e927");
$icon-download: add-backslash("44");
$icon-dropdown-arrow: add-backslash("76");
$icon-earth: add-backslash("e90d");
$icon-edit: add-backslash("65");
$icon-error-triangle: add-backslash("e914");
$icon-expand: add-backslash("45");
$icon-expand-arrow: add-backslash("59");
$icon-facebook: add-backslash("e929");
$icon-file: add-backslash("2c");
$icon-filter: add-backslash("66");
$icon-fingerprint: add-backslash("e910");
$icon-globe: add-backslash("a1");
$icon-grip: add-backslash("47");
$icon-group: add-backslash("e911");
$icon-help: add-backslash("68");
$icon-help-rounded: add-backslash("48");
$icon-help-solid: add-backslash("e91e");
$icon-idp: add-backslash("6a");
$icon-image: add-backslash("69");
$icon-info: add-backslash("49");
$icon-key: add-backslash("4b");
$icon-left: add-backslash("3c");
$icon-line-chart: add-backslash("e92c");
$icon-link: add-backslash("6b");
$icon-linkedin: add-backslash("e92b");
$icon-lock: add-backslash("42");
$icon-lock-large: add-backslash("4c");
$icon-lockout: add-backslash("e912");
$icon-menu: add-backslash("6d");
$icon-minus: add-backslash("2d");
$icon-minus-rounded: add-backslash("5f");
$icon-native-app: add-backslash("e934");
$icon-network: add-backslash("25");
$icon-new-window: add-backslash("e91f");
$icon-next: add-backslash("7d");
$icon-nodes: add-backslash("e915");
$icon-non-interactive: add-backslash("e91a");
$icon-notepad: add-backslash("e91b");
$icon-on-off: add-backslash("e922");
$icon-org-chart: add-backslash("e923");
$icon-overview: add-backslash("6f");
$icon-pin: add-backslash("4e");
$icon-ping-logo: add-backslash("e95d");
$icon-play: add-backslash("e924");
$icon-plus: add-backslash("2b");
$icon-plus-rounded: add-backslash("2a");
$icon-population: add-backslash("e926");
$icon-previous: add-backslash("7b");
$icon-puzzle: add-backslash("7a");
$icon-radar: add-backslash("e928");
$icon-resend: add-backslash("72");
$icon-resource: add-backslash("e92a");
$icon-right: add-backslash("3e");
$icon-robot: add-backslash("e92d");
$icon-saml: add-backslash("e930");
$icon-scale-balance: add-backslash("e95e");
$icon-search: add-backslash("73");
$icon-server: add-backslash("20ac");
$icon-settings: add-backslash("53");
$icon-shield: add-backslash("e92e");
$icon-signpost: add-backslash("e933");
$icon-slider: add-backslash("7e");
$icon-sort-asc: add-backslash("41");
$icon-sort-desc: add-backslash("2200");
$icon-sort-none: add-backslash("7c");
$icon-sp: add-backslash("7f");
$icon-spin-down: add-backslash("30");
$icon-spin-up: add-backslash("31");
$icon-spinner: add-backslash("50");
$icon-success: add-backslash("79");
$icon-success-round: add-backslash("e92f");
$icon-support: add-backslash("71");
$icon-tag: add-backslash("e932");
$icon-terminal: add-backslash("e948");
$icon-thumb: add-backslash("54");
$icon-timer: add-backslash("e949");
$icon-twitter: add-backslash("e94a");
$icon-undo: add-backslash("6e");
$icon-unlink: add-backslash("e94b");
$icon-user: add-backslash("75");
$icon-user_directory: add-backslash("e94c");
$icon-users: add-backslash("55");
$icon-view: add-backslash("56");
$icon-view-hidden: add-backslash("2f");
$icon-walkthrough: add-backslash("4d");
$icon-wand: add-backslash("57");
$icon-welcome: add-backslash("77");

// Ping Fed icons
$icon-pf-applications-integration: add-backslash("e903");
$icon-pf-applications-oauth: add-backslash("e904");
$icon-pf-applications-token-exchange: add-backslash("e94d");
$icon-pf-authentication-integration-path1: add-backslash("e94e");
$icon-pf-authentication-integration-path2: add-backslash("e94f");
$icon-pf-authentication-oauth-path1: add-backslash("e950");
$icon-pf-authentication-oauth-path2: add-backslash("e951");
$icon-pf-authentication-policies: add-backslash("e952");
$icon-pf-authentication-token-exchange: add-backslash("e953");
$icon-pf-security-certificate-key-mgmt: add-backslash("e954");
$icon-pf-security-system-integration: add-backslash("e955");
$icon-pf-system-data-credential-stores: add-backslash("e956");
$icon-pf-system-endpoints: add-backslash("e957");
$icon-pf-system-external-systems: add-backslash("e958");
$icon-pf-system-notifications: add-backslash("e959");
$icon-pf-system-oauth-settings: add-backslash("e95a");
$icon-pf-system-protocol-metadata: add-backslash("e95b");
$icon-pf-system-server: add-backslash("e95c");
