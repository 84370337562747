@mixin no-circle-icon {
    background: none;

    &:before {
        font-size: 150%;
        color: $color-white;
    }
}

.page-messages {
    position: fixed;
    z-index: $zindex__message;
    top: $layout-header-height;
    left: $layout-nav-width;
    right: 0;
    color: $color-white;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding: 0;
    height: 100%; // to keep the larger container from ever blocking clicks
    pointer-events: none;

    &--sidebar-fix {
        // temporary class to fix Messages for the new sidebar
        // this will be the default in our next major version
        left: 205px;
    }

    &.full {
        left: 0;
        .message {
            padding-left: 25px;
        }
    }
    &.inline {
        position: relative;
        margin: 0 -40px 0 -40px;
        top: auto;
        left: auto;
        right: auto;
        z-index: zindex__base;
    }
    .close {
        $size: 16px;

        color: rgba($color-white, 0.7);
        line-height: 1em;
        margin-left: $spacing-lg;

        &:after {
            content: $icon-close;
            font-size: 20px;
            font-family: 'iconfont';
            vertical-align: middle;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .message {
        display: flex;
        pointer-events: auto;
        align-items: center;
        clear: both;
        position: relative;
        overflow: hidden;
        padding: $spacing-sm $spacing-sm $spacing-sm $spacing-xl;
        border-bottom: 1px solid rgba($color-black, 0.1);
        font-size: 14px;
        line-height: 1.3;
        margin: 0 auto;
        width: 100%;
        box-sizing: border-box;
        @include break-word;

        animation: fade-in 0.7s;

        &--corner {
            max-width: 400px;
            min-width: auto;
            margin-right: $spacing-sm;
            margin-top: $spacing-sm;
            padding: $spacing-md;

            @media (max-width: 600px) {
                max-width: auto;
                margin: 0;
            }

            .message__icon {
                display: inline-block;
                width: 32px;
                height: 32px;
                border-radius: 17px;
                line-height: 32px;
                font-size: 20px;
                margin-right: $spacing-md;

                &.icon-error-triangle {
                    line-height: 32px;
                }
            }
        }

        &--center {
            flex: 0 0 170px auto;
            width: auto;
            min-width: auto;
            margin: auto;
            padding: $spacing-lg;
            background: $color-accent-neutral-10;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            transform: translate(-$layout-nav-width/2, -$layout-header-height);

            @media (max-width: 600px) {
                max-width: auto;
                margin: 0;
            }

            .message__icon {
                display: block;
                width: 50px;
                height: 50px;
                border-radius: 17px;
                line-height: 50px;
                font-size: 50px;
                margin-right: 0;
                margin-bottom: $spacing-md;
                background: transparent;
            }

            .message__title {
                font-size: 18px;
                margin-bottom: $spacing-md;
                text-align: center;
            }

            .message__description {
                font-size: 24px;
                text-align: center;
            }
        }

        input[type=button] {
            $height: 24px;

            height: $height;
            line-height: $height - 1;
            margin: 0 0 0 17px;
            border: none;
        }
        &.error {
            background-color: $color-critical-red;
            @include message-primary-button-css ($color-critical-red);

            .message__icon {
                color: $color-critical-red;
            }
        }
        &.success {
            background-color: $color-success;
            @include message-primary-button-css ($color-success);

            .message__icon {
                color: $color-success;
            }
        }
        &.warning,
        &.notice {
            background-color: $color-warning-yellow;
            @include message-primary-button-css ($color-warning-yellow);

            .message__icon {
                color: $color-warning-yellow;
            }
        }
        &.feature,
        &.info {
            background-color: $color-active-blue;
            @include message-primary-button-css ($color-active-blue);

            .message__icon {
                color: $color-active-blue;
            }
        }

        &__text {
            flex-grow: 1;
        }

        &__icon {
            flex-shrink: 0;

            margin-right: $spacing-sm;
            background: $color-white;
            width: 19px;
            height: 19px;
            border-radius: 10px;
            text-align: center;
            line-height: 19px;

            font-size: 13px;

            &.icon-alert,
            &.icon-info,
            &.icon-error-triangle {
                @include no-circle-icon;
            }
        }

        &__progress {
            display: flex;
            flex-direction: row;

            align-items: center;

            &-icon {
                font-size: 10px;
                line-height: 19px;
                margin-right: $spacing-xs;
            }

            &-text {
                @include font-pn-light;
                font-size: 13px;
                line-height: 19px;
                vertical-align: middle;
            }

            &-border {
                border-radius: 2px;

                border: 1px solid $color-white;
                height: 19px;
                margin-left: $spacing-md;
                position: relative;
                width: 90px;
            }

            &-bar {
                background: $color-white;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0;
            }
        }

        &--minimized {
            width: auto;
            right: 0;
            min-width: 0;
            transition: min-width 0.2s;
            justify-content: flex-end;
            margin-left: auto;
            margin-right: 0;
        }
    }
    .show {
        .text {
            margin-top: 0;
            border-bottom: 1px solid rgba($color-black, 0.1);

            transition: margin-top 0.1s;
        }
    }

    @include message-button-css;
    .button,
    button {
        &.inline {
            margin: 0 0 0 $spacing-md;
        }
    }
}

.inline-message {
    @include text-body;

    position: relative;
    display: table;
    padding: 15px;
    margin: 15px 0 $spacing-lg;
    border: 1px solid $color-rabbit-fur;

    border-radius: 3px;

    // TODO: determine primary button styling in non-alternate inline message
    button,
    .button {
        &.inline.primary {
            background: $color-white;
            color: $color-strong-neutral-10;
            border: 1px solid $color-neutral-50;

            &:hover {
                color: $color-active-blue-highlighted;
                border-color: $color-active-blue-highlighted;
            }
        }
    }

    .inline-message-icon,
    .inline-message-text,
    .inline-message-btn {
        display: table-cell;
        vertical-align: middle;
    }
    .inline-message-btn {
        margin: 0 0 0 20px;

        .input,
        .button,
        button {
            display: block;
            margin: 0 auto 0 15px;
        }
    }

    .inline-message-text {
        @include break-word;
    }

    &.warning,
    &.success,
    &.notice,
    &.error {
        padding-left: 55px;

        &:before {
            position: absolute;
            left: 15px;
            font-family: $icon-font;
            line-height: 1em;
        }
    }
    &.error {
        $icon-size: 25px;

        &:before {
            content: '!';
            top: calc(50% - #{floor($icon-size/2)});
            font-size: $icon-size;
            color: $color-critical-red;
        }
    }
    &.success {
        $icon-size: 20px;

        &:before {
            content: 'y';
            top: calc(50% - #{floor($icon-size/2)});
            font-size: $icon-size;
            color: $color-success;
        }
    }
    &.notice,
    &.warning {
        $icon-size: 25px;

        &:before {
            content: '!';
            top: calc(50% - #{floor($icon-size/2)});
            font-size: $icon-size;
        }
    }
    &.warning {
        &:before {
            color: $color-warning-icon-yellow;
        }
    }
    &.notice {
        &:before {
            color: $color-active-blue;
        }
    }

    &--alternate {
        &.warning {
            background-color: $color-warning-icon-yellow;
            @include message-primary-button-css($color-warning-icon-yellow);
        }
        &.error {
            background-color: $color-critical-red;
            @include message-primary-button-css($color-critical-red);
        }
        &.success {
            background-color: $color-success;
            @include message-primary-button-css($color-success);
        }
        &.notice {
            background-color: $color-active-blue;
            @include message-primary-button-css($color-active-blue);
        }

        &.error,
        &.warning,
        &.notice,
        &.success {
            color: $color-white;
            padding-left: 15px;
            border: none;

            &:before {
                content: none;
            }

            .button,
            button {
                &.secondary {
                    background: transparent;
                    border-color: $color-white;
                    color: $color-white;
                }
            }
        }

        @include message-button-css;
    }

    &--fullwidth {
        display: block;
    }

    &--borderless {
        &,
        &.warning,
        &.notice,
        &.error,
        &.success {
            border: none;
            padding: 0 0 0 40px;

            &:before {
                left: 0;
            }
        }
    }

    &--nomargin {
        margin: 0;
    }
}

.inline-message-text--full-width {
    width: 100%;
}
