.no-results {
    margin: 45px 0 25px;
    font-size: 14px;
    line-height: 1.3;
    color: $color-manatee;
    @include font-pn-regular;

    button {
        margin: 0 0 0 30px;
    }
}

.result-count {
    float: right;
    display: inline-block;
    line-height: 26px;
    font-size: 14px;
    color: $color-elephant;
}


// V2 ------------------------------------------------------------------------------------------------------------------
$expandable-row-height: 60px;
$expandable-row-gap: 12px;


.expandable-row {
    $button-height: $expandable-row-height;
    $button-width: 45px;
    $button-top: 22px;
    $button-bottom: 6px;

    position: relative;
    background-color: $color-elderly;
    border: 1px solid $color-elderly;
    margin: 0 0 $expandable-row-gap;
    max-height: $expandable-row-height;

    border-radius: 2px;
    transition: all 0.1s;

    .collapsed-content {
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        min-height: $expandable-row-height;
        padding: 23px 0 0 $spacing-lg;
        margin: 0 45px 0 0;

        &__icon {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            margin: $spacing-sm;
            position: absolute;
            left: 0;
            top: 0;
            height: 40px;
            width: 40px;
        }
    }
    &.has-subtitle {
        .collapsed-content {
            padding-top: 15px;
        }
    }
    .expanded-content {
        position: relative;
        padding: $spacing-md 75px $spacing-xl $spacing-lg;
        min-height: 3 * $button-height - 4px;
    }
    .expanded-content-scroller {
        @include break-word;
        overflow-x: hidden;

        // trying to do some magic here to make sure indentation is always correct
        > *,
        > .flex-row > * {
            margin-left: $spacing-lg;
        }

        .tab-set,
        .rocker-button,
        .rocker-button__container,
        > .flex-row {
            margin-left: 0;
        }

        // fighting against some of the built-in margins for other components
        > :first-child,
        > :first-child > :first-child {
            margin-top: 0;
        }
    }
    &.expanded-content-scroll {
        .expanded-content-scroller {
            max-height: 700px;
            overflow-y: auto;
        }
    }
    &__edit-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        height: calc(100% - #{2 * $button-height} - 2px);
    }
    .item-title {
        font-size: 17px;
        line-height: 1.1em;
        font-weight: normal;
        @include font-pn-regular;
        color: $color-slate;
    }
    .item-sub-title {
        font-size: 13px;
        line-height: 1.1em;
        font-weight: normal;
        @include font-pn-regular;
        color: $color-elephant;
        margin-top: 2px;
    }
    .item-title,
    .item-sub-title {
        @include text-truncate;
    }
    .item-image {
        position: absolute;
        left: 10px;
        top: 10px;
        max-height: 42px;
        max-width: 42px;
    }
    .item-icon {
        position: absolute;
        left: 19px;
        top: 18px;
        font-size: 24px;
    }
    &.has-image,
    &.has-icon {
        .collapsed-content {
            padding-left: 76px;
        }
    }
    &.subtitle-overflow-visible {
        .item-sub-title {
            overflow: visible;
        }
    }
    .row-accessories {
        height: 60px;
        margin: 0 46px 0 0;
        padding: 0 20px 0 0;
    }

    .row-btns {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: $button-width;
    }

    .expand-btn,
    .delete-btn,
    .edit-btn,
    .view-btn,
    .no-edit,
    .btn-fill,
    .expandable-row__edit-wrapper {
        position: relative;
        background-color: $color-chilly;
        text-align: center;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            text-transform: none;
            font-size: 18px;
            font-family: $icon-font;
            color: $color-walrus;

            transform: translateX(-50%) translateY(-50%);
        }
        &:hover {
            &:after {
                opacity: 0.65;
                text-decoration: none;
            }
        }
    }
    .expand-btn {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &:after {
            content: $icon-expand;
        }
    }
    .edit-btn,
    .view-btn,
    .btn-fill, {
        display: none;
        height: calc(100% - #{2 * $button-height} - 2px);
    }
    .edit-btn {
        &:after {
            content: $icon-edit;
        }
    }
    .view-btn {
        &:after {
            content: $icon-view;
            font-size: 13px;
        }
    }
    .delete-btn,
    .edit-btn,
    .view-btn,
    .expand-btn {
        width: $button-width;
        padding: 0;
        border: none;
        border-radius: 0;
        min-height: $button-height;
    }

    .delete-btn {
        display: none;

        &:after {
            content: $icon-delete;
        }
    }
    &.expanded {
        background-color: $color-white;
        max-height: 4000px;
        border: 1px solid $color-rabbit-fur;

        .expanded-content-scroller {
            overflow-y: auto;
            max-height: 3500px;
            box-sizing: border-box;
        }

        //transition: max-height 1s);

        .expand-btn {
            position: relative;
            height: $expandable-row-height;
            top: auto;
            bottom: auto;
            margin: 0 0 1px;

            &:after {
                font-family: 'iconfont';
                content: $icon-collapse;
            }
        }
        .edit-btn,
        .view-btn,
        .btn-fill {
            display: block;
        }

        .delete-btn {
            display: block;
            margin: 1px 0 0;
        }

        .collapsed-content {
            height: auto;
        }

        .item-title,
        .item-sub-title {
            white-space: normal;
            word-break: break-word;
        }
    }
    &.no-edit {
        .edit-btn {
            pointer-events: none;

            &:after {
                content: '';
            }
            &:hover {
                opacity: 1;
            }
        }
    }
    &.no-delete {
        .edit-btn,
        .view-btn {
            height: calc(100% - #{$button-height} - 1px);
        }
        .resend-tooltip {
            position: absolute;
            top: 0;
            right: 0;

            &.bottom {
                position: absolute;
                bottom: 0;
                top: auto;
            }
        }
    }
    &.no-edit.no-delete {
        .btn-fill {
            height: calc(100% - #{$button-height} - 1px);
        }
    }
    &.waiting {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $color-white;

            opacity: 0.6;
        }
    }
    &.draggable {
        .expanded-content {
            padding-left: 45px;
        }
        .item-title,
        .item-sub-title {
            margin-left: 25px;
        }
        .grip {
            position: absolute;
            top: 21px;
            left: 20px;

            @include grab-cursor;

            &:before {
                content: $icon-grip;
                text-transform: none;
                font-size: 18px;
                font-family: 'iconfont';
                color: $color-stone;
            }
        }
        .row.dragTop {
            border-top: 3px solid $color-active-blue;
        }
        .row.dragBottom,
        .row.dragTop.dragBottom {
            border-bottom: 3px solid $color-active-blue;
        }
    }
    &.invited {
        .row-accessories {
            background-color: $color-chilly;
            padding-left: 20px;

            button.inline {
                border-color: $color-chilly;
            }
        }
        &.expanded {
            .button.inline {
                border-color: $color-rhino;
            }
        }
        .invite-info {
            line-height: 1em;

            .invite-status {
                font-size: 14px;
                color: $color-slate;
                margin-bottom: 2px;
            }
            .invite-date {
                font-size: 12px;
                color: $color-rhino;
            }
        }
    }
    &.z-index-priority {
        z-index: $zindex__elevated;
    }
    .item-message {
        display: block;
        text-align: center;
        margin: -15px 0 15px 0;
        padding: 10px 25px;

        .inline-message-text {
            display: block;
        }
    }
    .item-message__buttons {
        margin-left: 25px;

        > .button {
            margin-right: 10px;
        }
    }
}


.result-set {
    position: relative;
    margin: 20px 0 0;

    &__title {
        color: $color-slate;
        font-size: 16px;
        margin-bottom: $spacing-sm;
        margin-top: $spacing-md;
    }

    .item {
        @extend .expandable-row;
    }
    .result-section-title {
        @include font-pn-semibold;
        font-size: 16px;
        color: $color-blue-grey;
        margin: 30px 0 10px;
    }
    .result-set-index,
    .item-head {
        // item-head deprecated, .result-set-index prefered
        margin: 5px auto;
        font-weight: bold;
        text-align: center;
        color: $color-midnight;

        &:empty {
            margin: 0;
        }
    }
}

.edit-btn {
    // these rules handle the case when a DetailsTooltip is used for the edit button
    > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &:after {
        z-index: $zindex__elevated;
    }

    a {
        z-index: $zindex__elevated + 1;
    }
}

.row-help {
    display: inline-block;
    margin: 0;
    padding: 4px 5px 1px;
    font-size: 13px;
    line-height: 1em;
    text-transform: uppercase;
    color: $color-white;
    background-color: $color-elephant;

    border-radius: 3px;
}



.result-set {
    $content-padding: $spacing-xl;

    &--stretched &__scroller {
        > * {
            padding-right: $content-padding;
        }

        margin-right: -$content-padding; // page offset to make scrollbar go all the way to right
        margin-bottom: -2 * $spacing-xl; // page offset to make it look like it is on the bottom

        overflow: scroll visible;
    }
}

@import 'rows/_expandable-row-ordering';

// V1 - TO BE REMOVED OR MOVED - DEPRECATED OR SITE SPECIFIC -----------------------------------------------------------
.result-set {
    $item-height: 75px;
    $button-height: 47px;
    $button-width: 50px;
    $button-top: 28px;

    &.filter {
        min-height: 950px;
        margin-right: 45px;
    }
    .loading {
        .loader-container {
            bottom: 30px;

            opacity: 1;
        }
    }
    .item {
        &.expanded {
            .row-controls {
                background: none;
                border-left: 1px solid $color-rabbit-fur;
                border-bottom: 1px solid $color-rabbit-fur;
            }
            .collapsed-content {
                .row-button {
                    display: block;
                }
                .inline-btn-tooltip {
                    position: absolute;
                    top: 17px;
                    right: 188px;

                    &.bottom {
                        position: absolute;
                        top: auto;
                        bottom: 19px;
                        right: 112px;
                    }
                }
            }
            &.row {
                border: none;
                .collapsed-content {
                    height: 1px;
                    padding: 0;
                }
            }
        }
        &.no-content {
            .row-controls {
                right: 0;
            }
        }
        .loader {
            margin: 0 0 20px 20px;
        }
        .item-link {
            position: absolute;
            right: 80px;
            line-height: 1em;
            font-size: 16px;
            top: 24px;
            &.close-link {
                top: inherit;
                bottom: 24px;
            }
            &.edit-link {
                .edit {
                    right: 120px;
                    top: 3px;
                    position: absolute;
                }
                .icon-view {
                    top: 0;
                    &.hide {
                        opacity: 0.25;
                    }
                    &.disabled {
                        cursor: not-allowed;
                    }
                }
            }
            .deleting {
                display: inline-block;
                margin-left: 30px;
                margin-top: -10px;
                .icon-delete {
                    display: inline-block;
                    margin: 0 0 5px 10px;
                    color: $color-critical-red;
                }
            }
            .undo {
                display: inline-block;
                margin-left: 30px;
                margin-top: -10px;
                .icon-undo {
                    display: inline-block;
                    margin: 0 0 5px 10px;
                    color: $color-active;
                }
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
                .spinner {
                    margin-right: 7px;
                }
            }
        }
        .status-deleting {
            .item-title,
            .item-sub-title,
            .grip,
            .action-status {
                opacity: 0.5;
            }
            .item-title,
            .item-sub-title,
            .action-status {
                font-style: italic;
            }
        }
        .icon-view {
            &.hide {
                display: block;

                opacity: 0.25;
            }
            &.disabled {
                cursor: not-allowed;
            }
        }
        .item-username {
            @include font-pn-regular;
            font-size: 17px;
            color: $color-text;
            padding-top: 7px;
        }
        &.new-user {
            overflow: visible;

            .item-title,
            .item-sub-title {
                font-style: italic;
            }
            .item-title {
                color: $color-rhino;
            }
        }
        .row-controls {
            $btn-width: 75px;
            background: darken($color-rabbit-fur, 3);
            border-bottom: 1px solid $color-rabbit-fur;
            border-left: 1px solid darken($color-rabbit-fur, 8%);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 51px;

            &.noborder {
                border: none;
            }

            .details {
                padding: 15px 15px 15px 25px;
                margin: 0 (2 * $btn-width) 0 0;
                min-width: 132px;
                font-size: 16px;
                height: 32px;

                .title,
                .sub-title {
                    line-height: 1em;
                }
                .title {
                    margin: 7px 0 0;
                    font-size: 16px;
                }
                .sub-title {
                    margin: 2px 0 0;
                    font-size: 12px;
                    font-style: italic;
                }
            }
            .reject-btn,
            .approve-btn,
            .cancel-btn,
            .resend-btn {
                position: absolute;
                top: 0;
                bottom: 0;
                width: $btn-width;
                text-align: center;
                font-size: 16px;

                &:after {
                    font-family: $icon-font;
                    position: absolute;
                    top: 20px;
                    left: 0;
                    right: 0;
                    font-size: 22px;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .reject-btn,
            .cancel-btn {
                right: $btn-width;
            }
            .approve-btn,
            .resend-btn {
                right: 0;
            }
            .cancel-btn {
                padding-top: 27px;
            }
            .reject-btn,
            .approve-btn,
            .resend-btn {
                padding-top: 47px;

                &:hover {
                    opacity: 0.7;
                }
            }
            .reject-btn {
                color: $color-slate;
                background-color: $color-stratus;

                &:after {
                    content: $icon-clear;
                }
            }
            .approve-btn {
                color: $color-white;
                background-color: $color-success;

                &:after {
                    content: $icon-approve;
                }
            }
            .resend-btn {
                color: $color-white;
                background-color: $color-active;

                &:after {
                    content: $icon-resend;
                }
            }
            .row-button {
                position: absolute;
                right: 73px;
                top: 18px;
                display: none;

                transition: all 0.2s;
            }
            .input-toggle {
                position: absolute;
                right: 25px;
                top: 20px;
            }
        }
        .no-edit {
            bottom: 63px;
        }
        .expanded-content {
            .loading-message {
                float: right;
                .spinner {
                    margin-right: 7px;
                }
            }
        }
        .resend-tooltip {
            position: absolute;
            top: 0;
            right: 0;
        }
        .cancel-tooltip {
            position: absolute;
            right: 65px;
        }
    }
}

// POLICY EDITOR
.result-set-list {
    margin: 10px 0 0;
    padding: 20px 0 0 30px;

    .result-set-title {
        text-transform: uppercase;
        color: $color-elephant;
        padding: 0 0 5px;
        margin: 0 0 25px;
        border-bottom: 1px solid $color-elephant;
        @include font-pn-regular;
        font-size: 16px;
        a {
            margin-left: 20px;
            font-size: 14px;
            text-transform: uppercase;
        }
        .actions {
            float: right;
            a {
                text-transform: none;
                color: $color-link-text;
                font-size: 14px;
                margin-left: 0;
            }
        }
    }
    .set-container {
        width: 100%;
        clear: both;
        margin-bottom: 5px;

        &.active {
            display: inline-block;

            transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
        }
        .set {
            width: 100%;
            clear: both;
            display: inline-block;

            .col-1,
            .col-2 {
                width: 50%;
            }
            .col-1 {
                float: left;
            }
            .col-2 {
                float: right;
            }
        }
    }
    .result-set {
        margin-top: 5px;
        .system-status {
            display: inline-block;
        }
        .item {
            overflow: hidden;
            &.expanded {
                overflow-y: scroll;
            }
            .expanded-content {
                .row-status-toggle {
                    left: 0;
                    top: 0;
                    position: relative;
                }
            }
        }
    }
    &.active {
        .set-container {
            display: block;
        }
        .result-set-title {
            border-bottom: none;
        }
        .show-set {
            transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
            transform: rotate(180deg);
        }
    }
}

.show-set,
.show-condition {
    font-size: 12px;
    padding: 0;
    position: absolute;
    left: -4px;
    top: 4px;
    &.expanded {
        transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
        transform: rotate(180deg);
    }
    .data-col-of-4 {
        max-width: 25%;
    }
    .data-col-of-5 {
        max-width: 20%;
    }
}

.condition-title {
    position: relative;
    padding-left: 21px;
    cursor: pointer;

    &.active {
        .show-condition {
            transform: rotate(-180deg);
        }
    }
}

.condition-list {
    margin-right: 20px;
    padding-right: 20px;

    .condition-title {
        margin: 0;
        padding: 15px 0 15px 30px;
        border-style: solid;
        border-width: 1px 0 0;
        border-color: $color-elderly;
        text-transform: uppercase;
        color: $color-rhino;
        font-size: 14px;
        @include font-pn-regular;
        font-weight: 600;

        &:hover {
            cursor: pointer;
        }
        &:last-child {
            border-bottom: 1px solid $color-elderly;
        }
        .show-condition {
            top: 19px;
            left: 5px;
        }
        .show-condition,
        .value {
            text-transform: none;
            color: $color-input-text;
        }
        .value {
            font-weight: normal;
            &:before {
                // only want ':' to show when condition-title followed by value
                content: ': ';
                color: $color-rhino;
                font-size: 14px;
                @include font-pn-regular;
                font-weight: 600;
            }
        }
        &.active {
            .show-condition {
                transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
                transform: rotate(180deg);
            }
            .condition {
                display: block;

                transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
            }
        }
    }
    .short {
        input[type=text] {
            width: 180px;
        }
    }
    .condition {
        display: none;
        margin-right: 10px;
        padding: 15px 0 0 30px;
        position: relative;
        text-transform: none;
        font-weight: normal;
        &.active {
            display: block;

            transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
        }
        &.alone {
            border-bottom: none;
        }
        .remove,
        .remove-rule {
            position: absolute;
            top: -25px;
            right: 0;
            margin-right: 0;
        }
        .condition-fields {
            .menu {
                position: relative;
                &.alone {
                    border-bottom: none;
                }
                input[type=text] {
                    height: 30px;
                    width: 180px;
                    padding-left: 30px;

                    border-radius: 3px;
                }
                .input-row {
                    margin: 0 0 10px;
                }
                .actions {
                    display: inline-block;
                    padding: 7px 0 0;
                    margin-top: 7px;
                    color: $color-elephant;
                    .input-row {
                        margin-top: 0;
                        label {
                            vertical-align: middle;
                        }
                        .clear-search {
                            top: 17px;
                            right: 5px;
                        }
                    }
                    .input-toggle {
                        position: relative;
                        display: inline-block;
                        top: 0;
                        right: 0;
                        .toggle {
                            margin: -5px 5px 0 10px;
                        }
                    }
                    span {
                        color: $color-text;
                    }
                }
            }
            .check-all {
                margin: 0 10px 0 0;
            }
            .conditions-multiselect {
                padding: 10px 5px 10px 0;
                margin-left: 0;
                border: 1px solid $color-elderly;
                .options {
                    width: 99%;
                    height: 133px;
                    max-height: 133px;
                    overflow-y: scroll;
                    padding-left: 10px;
                    label {
                        width: 220px;
                        margin-bottom: 10px;
                        text-transform: none;
                        &.input-checkbox {
                            display: inline-block;
                            padding: 0 0 0 30px;
                            .label-text {
                                display: inline-block;
                                padding-top: 5px;
                            }
                        }
                    }
                    div {
                        display: inline;
                        &.item-head {
                            display: block;
                            color: $color-walrus;
                        }
                    }
                }
            }
            textarea {
                max-width: 700px;
            }
        }
    }
}

.result-search-inputs {
    position: relative;
    margin: 0 0 30px;

    label {
        &.search {
            input {
                width: 432px;
            }
        }
    }
    .search-text {
        width: 270px;
        padding-right: 40px;
    }
    .list-status-select {
        position: absolute;
        top: 0;
        left: 368px;
        margin: 0;
    }

    .add-button,
    .add-button-help {
        position: absolute;
        right: 0;
        top: 0;

        > .tooltip-text {
            bottom: 15px;
        }
    }
    .filter-link {
        display: inline-block;
        line-height: 50px;
        text-decoration: none;

        &:after {
            font-family: $icon-font;
            content: $icon-dropdown-arrow;
            position: absolute;
            font-size: 12px;

            transition: all 0.3s;
        }
        &.show {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    .filter-options {
        padding-top: 0;
        padding-bottom: 0;
        height: 0;
        overflow: hidden;

        opacity: 0;
        transition: all 0.3s;

        &.show {
            padding-top: 30px;
            padding-bottom: 10px;
            height: 24px;

            opacity: 1;
        }
        .input-checkbox {
            padding-bottom: 0;
        }
    }
}


.row-accessories {
    position: relative;
    float: right;
    display: inline-flex;
    align-items: center;

    .help-tooltip {
        vertical-align: middle;
    }
    .count {
        font-size: 13px;
        height: 20px;
        line-height: 20px;
    }
    > *,
    .row-accessories-content > * {
        margin: 0 0 0 20px;
    }
    .row-accessories-content {
        margin: 0;
    }
}


.accessories-line-chart__title,
.accessories-line-chart__trend {
    font-size: 11px;
}

.accessories-line-chart__count-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 70px;
}

.accessories-line-chart__count {
    @include font-pn-semibold;
    font-size: 16px;
    color: $color-accent-neutral-10;
}

.accessories-line-chart__count-label,
.accessories-line-chart__chart-label {
    font-size: 10px;
    color: $color-accent-neutral-30;
}

.accessories-line-chart__hint-wrapper,
.accessories-line-chart__chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .help-tooltip .tooltip-text a {
        text-decoration: none;
    }

    .help-tooltip--light .tooltip-text a {
        color: $color-active-blue;
    }
}

.accessories-line-chart__chart-wrapper {
    width: 130px;
    height: 60px;
    padding-right: $spacing-md;
    .accessories-line-chart__chart {
        width: 50px;
        height: 18px;
    }
    .accessories-line-chart__no-data-chart {
        width: 100%;
        height: 50%;
        border-bottom: 1px solid $color-accent-neutral-30;
    }
    &:hover {
        cursor: pointer;
        background-color: $color-accent-neutral-70;
        .accessories-line-chart__chart,
        .accessories-line-chart__chart-label,
        .accessories-line-chart__trend {
            transform: scale(1.1);
            transition: transform 0.2s;
        }
    }
    &:before {
        content: '';
        display: block;
        height: 35px;
        width: 1px;
        margin-right: $spacing-md;
        background-color: $color-accent-neutral-70;
    }
    .accessories-line-chart__trend {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
    }
}

.expandable-row-section {
    &__title {
        @include text-section-title;
        margin-bottom: $spacing-md;
    }
}
