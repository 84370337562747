
$searchbar-width: 800px;
$searchbar-link-width: 86px;
$searchbar-link-bg-color: lighten($color-accent-neutral-80, 5%);
$searchbar-link-text-color: $color-strong-neutral-10;
$searchbar-link-text-color-hover: $searchbar-link-bg-color;
$searchbar-input-height: 38px;

.searchbar {
    &__bar {
        display: flex;
        flex-direction: row;

        max-width: $searchbar-width;
    }

    &__input {
        flex-grow: 1;

        position: relative;
        display: inline-block;
        vertical-align: middle;

        label {
            margin: 0;
            display: block;
        }
    }

    &__top-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__left-control {
        max-width: $searchbar-width;
        flex-basis: $searchbar-width;
    }

    &__center-control {
        align-self: center;
        margin-left: $spacing-md;
    }

    &__right-control {
        margin-left: $spacing-md;
        text-align: right;
        flex-grow: 1;

        :last-child,
        .button:last-child {
            margin-right: 0;
        }
    }

    &--open &__input {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    }

    &__filter-link {
        box-sizing: border-box;
        line-height: $searchbar-input-height;
        padding: 0 $spacing-md;
        margin: 0;
        vertical-align: middle;
        background-color: $searchbar-link-bg-color;
        color: $searchbar-link-text-color;
        text-align: center;

        .searchbar &:after { // added specificity here to override defaults
            color: $searchbar-link-text-color;
            display: inline-block;
            position: relative;
            right: auto;
            margin: 0 0 0 $spacing-xs;
        }
        &:hover {
            background-color: $searchbar-link-text-color-hover;
            color: $searchbar-link-text-color;
        }
    }

    &--open &__filter-link--open,
    &__filter-link--open:hover {
        background-color: $searchbar-link-bg-color;
    }

    &__filters {
        box-sizing: border-box;
        padding: $spacing-lg;
        background: $searchbar-link-bg-color;

        > * {
            margin-bottom: $spacing-lg;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__filter-actions {
        flex-grow: 1;

        text-align: right;
    }

    &__apply-button {
        margin-top: $spacing-sm;
        text-align: right;

        > *:last-child {
            margin-right: 0;
        }
    }

    &__doc-link {
        margin-top: $spacing-sm;
        text-align: right;
    }

    .input-row .input-timezone a,
    .input-row > a {
        line-height: 38px;
    }

    .page-controls-primary {
        border-top: none;
        padding-bottom: 0;
    }

    .input-message {
        &__icon {
            display: none;
        }
        .input-container {
            padding-right: 0;
        }
    }
}
