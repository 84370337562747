.status {
    $size: 14px;

    height: $size;
    width: $size;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    box-sizing: border-box;

    border-radius: ceil($size/2);

    &.good {
        background-color: $color-success;
    }
    &.error,
    &.failure {
        background-color: $color-critical-red;
    }
    &.warning {
        $border: 2px;
        border: $border solid $color-warning-yellow;
        height: $size;
        width: $size;

        border-radius: ceil(($size + $border) / 2);
    }
}
