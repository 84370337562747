// INDENT
.indent {
    display: flex;
    flex-direction: row;
    justify-content: left;

    position: relative;
    line-height: 1em;
    min-height: 50px;

    &.grouped {
        margin-bottom: 10px;
    }
    .count {
        background-color: $color-white;
        min-width: 15px;
        height: 15px;
        line-height: 15px;
        font-size: 10px;
        margin-right: 7px;
        padding: 0 3px;
    }
    .indent-title-container {
        flex-shrink: 0;

        position: relative;
        width: 20px;

        .title {
            display: flex;
            transform: rotate(180deg);

            -ms-flex-direction: column;

            top: 0;
            right: 0;
            left: 3px;
            bottom: 0;

            color: $color-elephant;
            text-align: center;
            text-transform: uppercase;
            font-size: 12px;
            overflow: hidden;
            position: absolute;
            writing-mode: vertical-rl;
            -ms-writing-mode: tb-lr;

            &:before,
            &:after {
                border-left: 1px solid $color-neutral-80;
                content: '';
                display: inline-block; // for IE10
                height: 5000px;

                transform: translateX(10px);
            }

            &:before {
                margin-bottom: $spacing-xs;
            }
            &:after {
                margin-top: $spacing-xs;
            }
        }

        .border {
            position: absolute;
            top: 0;
            right: 0;
            left: 9px;
            bottom: 0;
            border-left: 1px solid $color-neutral-80;
        }

        // Although most of the rules for this are below, declaring this rule here due to specificity.
        // Would have to use !important on everything otherwise.
        .indent--with-colors__title {
            &:before,
            &:after {
                border-color: $color-charting-grape;
            }
        }
    }
    .indent-content {
        margin-left: 5px;
        display: block;
        align-self: center;

        &.no-border {
            border-left: none;
        }
        .content-link {
            padding: 7px 3px 5px;

            &:first-of-type {
                padding-top: 0;
            }
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    &--detached {
        min-height: 0;
        margin: $spacing-sm 0;

        .indent-content {
            margin-left: 1px;
        }
    }

    // nested
    .indent {
        padding: 3px 0 0;
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0;
        }
        .indent-content {
            .link {
                padding-top: 0;
            }
        }
    }

    &__title-content {
        white-space: nowrap;
    }

    &--with-colors {
        &__title {
            // Necessary for very small indents; will not be centered otherwise.
            justify-content: center;
        }

        &__title-content {
            align-self: center;
            background-color: $color-charting-grape;
            border-radius: 2px;
            color: $color-white;
            padding: $spacing-md $spacing-xs;
        }

        // Change the indent colors for each level of nesting
        & .indent--with-colors {
            & .indent--with-colors__title {
                &:before,
                &:after {
                    border-color: $color-charting-orange;
                }
            }

            & .indent--with-colors__title-content {
                background-color: $color-charting-orange;
            }

            & .indent--with-colors {
                & .indent--with-colors__title {
                    &:before,
                    &:after {
                        border-color: $color-charting-blue;
                    }
                }

                & .indent--with-colors__title-content {
                    background-color: $color-charting-blue;
                }
            }
        }
    }

    // Nested color styling
}
