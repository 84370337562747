@function circular-progress($maxPercent, $percent, $color-bar, $color-back) {
    $increment: 360 / $maxPercent;
    $half: round($maxPercent / 2);
    $background-image: '';

    @if $percent < $half {
        $nextdeg: 90deg +  ($increment * $percent);

        $background-image: 'linear-gradient(90deg, #{$color-back} 50%, transparent 50%, transparent), linear-gradient(#{$nextdeg}, #{$color-bar} 50%, #{$color-back} 50%, #{$color-back})';

    }
    @else {
        $nextdeg: -90deg + ($increment * ($percent - $half));

        $background-image: 'linear-gradient(#{$nextdeg}, #{$color-bar} 50%, transparent 50%, transparent), linear-gradient(270deg, #{$color-bar} 50%, #{$color-back} 50%, #{$color-back})';
    }
    @return unquote($background-image);
}

@mixin progress-icon ($total) {
    $increment: 100 / $total;
    &.of#{$total} {
        @for $i from 1 through $total {
            &.step#{$i}:before {
                background: circular-progress(100, $increment * $i, $color-midnight, #ccc);
            }
        }
    }
}


.progress {
    $progress-size: 36px;
    $border-size: 10px;

    position: relative;
    display: inline-block;
    text-align: center;
    height: $progress-size;
    width: $progress-size;
    padding-top: 2px;
    line-height: 2.43em;
    font-size: 14px;
    font-weight: 500;
    color: $color-midnight;
    border-radius: 50%;
    z-index: 1;


    &:before,
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        content: '' !important;
        z-index: -1;
    }
    &:before {
        width: $progress-size;
        height: $progress-size;
        display: block;

    }
    &:after {
        width: $progress-size - $border-size;
        height: $progress-size - $border-size;
        top: $border-size / 2;
        left: $border-size / 2;
        background: #fff;
    }

    &.done {
        i {
            display: inline-block;
            height: $progress-size;
            width: $progress-size;
            color: transparent;
            font-size: 0;
            font-style: normal;

            &:after {
                font-family: $icon-font;
                content: $icon-success;
                height: $progress-size;
                width: $progress-size;
                color: $color-midnight;
                font-size: 12px;
            }
        }
    }
    @include progress-icon(1);
    @include progress-icon(2);
    @include progress-icon(3);
    @include progress-icon(4);
    @include progress-icon(5);
    @include progress-icon(6);
    @include progress-icon(7);
    @include progress-icon(8);
    @include progress-icon(9);
    @include progress-icon(10);

}
