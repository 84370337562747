@import 'message-button';
@import 'ellipsis-loader-button';

$enabled-hover: '&:hover:not(.disabled), &:hover:not([disabled])';

@mixin base-button-styles {
    @include text-style($color-slate, $font-size-primary);

    position: relative;
    display: inline-block;
    background-color: $color-white;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }
}

@mixin default-button-styles {
    background: $color-white;

    &:hover {
        color: $color-slate;
    }
}

@mixin button-with-icon($content: $icon-plus) {
    padding-left: $spacing-md + $spacing-sm + $spacing-sm;

    &:before,
    &:after {
        font-family: $icon-font;
    }
    &:before {
        content: $content;
        position: absolute;
        left: $spacing-md;
        width: $spacing-md;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        font-size: 12px;
        line-height: 1em;
    }
}

input[type=button],
input[type=submit],
button,
.button {
    $button-types: '.primary', '.success', '.cancel', '.danger';
    $height: 36px;

    padding: 0 $spacing-lg;
    margin-right: $spacing-sm;
    height: $height;
    line-height: $height;
    border: 1px solid $color-rhino;
    box-sizing: border-box;

    border-radius: 2px;
    @include base-button-styles;
    @include default-button-styles;

    &.button--no-spacing {
        margin-right: 0;
    }

    #{$enabled-hover} {
        color: $color-active;
        border-color: $color-active;
        &.inline .badge {
            border-color: $color-active;
        }
    }
    &:focus {
        border: 1px solid $color-rhino;
    }

    @each $button-type in $button-types {
        &#{$button-type} {
            color: $color-white;
            border: none;
            background: none;

            &:hover {
                color: $color-white;
            }
        }
    }
    &.primary {
        background-color: $color-active-blue;

        #{$enabled-hover} {
            background-color: $color-active-blue-highlighted;
        }
    }
    &.success {
        background-color: $color-success;

        #{$enabled-hover} {
            background-color: $color-success-highlighted;
        }
    }
    &.cancel {
        background-color: $color-neutral-40;

        #{$enabled-hover} {
            background-color: tint($color-neutral-40, 10%);
        }
    }
    &.danger {
        background-color: $color-critical-red;

        #{$enabled-hover} {
            background-color: $color-critical-red-highlighted;
        }
    }
    &.secondary {
        background-color: $color-white;
        color: $color-slate;
        border: 1px solid $color-rhino;

        #{$enabled-hover} {
            color: $color-active-blue-highlighted;
            border-color: $color-active-blue-highlighted;
        }
    }

    &.inline {
        $height: 26px;

        height: $height;
        line-height: $height;
        font-size: $font-size-normal;

        border-radius: ceil($height/2);

        .badge {
            color: inherit;
            border: 1px solid $color-stratus;
            margin-left: 5px;
            padding: 0 5px;
            box-sizing: border-box;
            display: inline-block;
            min-width: 19px;
            height: 19px;
            line-height: 19px;
            text-align: center;

            border-radius: 10px;
        }
    }
    &.disabled,
    &[disabled] {
        cursor: default;
        opacity: $inactive-opacity;
    }
    &.add {
        @include button-with-icon();
    }
    &.download {
        @include button-with-icon($icon-download);
    }
    &.inline {
        &.edit,
        &.download-box,
        &.expand,
        &.plus,
        &.delete,
        &.remove,
        &.minus,
        &.prev,
        &.clear,
        &.next {
            $size: 29px;

            padding: 0;
            height: $size;
            width: $size;
            min-width: $size;
            text-align: center;
            line-height: $size;

            border-radius: ceil($size/2);

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                font-family: $icon-font;
                text-transform: none;
            }

            // if the icon button has text then adjust the spacing accordingly
            &:not(:empty) {
                width: auto;
                padding: 0 25px 0 43px;

                &:before {
                    width: auto;
                    left: 18px;
                }
            }
        }

        &.plus {
            &:before {
                content: '*';
            }
        }
        &.remove,
        &.minus {
            &:before {
                content: '-';
            }
        }
        &.delete {
            &:before {
                content: 'd';
            }
        }
        &.download-box {
            &:before {
                content: '\e935';
                font-size: 15px;
            }
        }
        &.edit {
            &:before {
                content: 'e';
            }
        }
        &.expand {
            &:before {
                content: '\e91f';
                font-size: 11px;
            }
        }
        &.prev {
            &:before {
                content: '<';
            }
        }
        &.next {
            &:before {
                content: '>';
            }
        }
        &.clear {
            &:before {
                content: 'X';
                font-size: 12px;
            }
        }

        &.button--circle {
            $size: 28px;

            padding: 0;
            height: $size;
            width: $size;
            min-width: $size;
            text-align: center;

            border-radius: ceil($size/2);

            .button__icon {
                left: 0;
                right: 0;
                text-align: center;
                width: auto;
            }
        }

        &.small {
            $size: 21px;
            height: $size;
            width: $size;
            line-height: $height;
            font-size: 13px;
            margin: 0;
            vertical-align: text-bottom;

            &.edit {
                &:before {
                    top: 5px;
                    font-size: 10px;
                }
            }
            &.plus {
                &:before {
                    top: 7px;
                    font-size: 10px;
                }
            }
            &.remove,
            &.minus {
                &:before {
                    top: 10px;
                    left: 1px;
                    right: 0;
                    font-size: 9px;
                    width: auto;
                    height: 2px;
                    line-height: 2px;
                }
            }
        }
    }
    &.large {
        $height: 52px;
        height: $height;
        line-height: $height;
        font-size: $font-size-primary;
    }
    &.row-button {
        vertical-align: bottom;

        &.inline {
            margin: 0 0 6px;
        }
    }
    &.link {
        background: transparent;
        border: none;
        color: $color-active-blue;
        height: auto;
        line-height: inherit;
        padding: 0;
        font-size: 14px;
        vertical-align: initial;

        &:hover {
            color: $color-active-blue-highlighted;

            .button__text {
                text-decoration: underline;
            }
        }

        .button__icon {
            position: static;
            transform: none;
            margin-right: $spacing-xs;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.button--icon-button {
        border: none;
        background: transparent;
        color: $color-accent-neutral-10;
        font-size: 15px;
        height: auto;
        padding: 0;

        &:hover {
            color: $color-active-blue;
        }

        .button__icon {
            position: static;
        }
    }
}

.button {
    &__icon {
        position: absolute;
        left: $spacing-md;
        width: $spacing-md;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        font-size: 13px;
        line-height: 1em;
    }

    &--icon {
        padding-left: $spacing-md + $spacing-sm + $spacing-sm;
    }

    &--active {
        background: $color-midnight;
        border-color: $color-midnight;
        color: $color-white;

        #{$enabled-hover} {
            color: $color-white;
            background: tint($color-midnight, 10%);
        }
    }

    &--tutorial {
        height: $spacing-lg;
        line-height: $spacing-lg;
        font-size: $font-size-normal;
        font-weight: 100;
        padding: 0 $spacing-sm;
        text-transform: uppercase;

        border-radius: 3px;
    }
}

.rocker-button {
    $height: 35px;
    $transition: left 0.4s ease-out;

    display: flex;
    align-content: center;
    justify-content: center;

    height: $height;
    line-height: 10px;
    margin-bottom: $spacing-lg;

    border-radius: 3px;
    @include base-button-styles;

    &__container {
        display: inline-block;
    }

    button,
    .help-tooltip > .helphint-button-target > button {
        @include text-style();
        padding: 0 $spacing-lg;

        width: auto;
        line-height: $height - 2px;

        text-transform: none;
        margin: 0;

        border-style: solid;
        border-color: $color-rhino;
        border-width: 1px 0 1px 1px;
        border-radius: 0;

        cursor: pointer;
    }

    button {
        &:first-of-type {
            border-radius: 3px 0 0 3px;
        }
        &:last-of-type {
            border-radius: 0 3px 3px 0;
            border-width: 1px;
        }
    }

    .help-tooltip > .helphint-button-target > button {
        &:first-of-type {
            border-radius: 3px 0 0 3px;
        }

        &:last-of-type {
            border-radius: 0 3px 3px 0;
            border-width: 1px;
        }
    }

    &.disabled {
        opacity: $inactive-opacity;
        cursor: default;
        button {
            pointer-events: none;
        }
    }

    .iconComponent {
        vertical-align: bottom;
    }

    .iconComponent__graphic {
        line-height: unset;
    }

    &.sel-0 button:nth-of-type(1),
    &.sel-1 button:nth-of-type(2),
    &.sel-2 button:nth-of-type(3),
    &.sel-3 button:nth-of-type(4),
    &.sel-4 button:nth-of-type(5),
    &.sel-5 button:nth-of-type(6),
    &.sel-6 button:nth-of-type(7),
    &.sel-7 button:nth-of-type(8),
    &.sel-8 button:nth-of-type(9),
    &.sel-9 button:nth-of-type(10),
    &.sel-10 button:nth-of-type(11),
    &.sel-11 button:nth-of-type(12),
    &.sel-12 button:nth-of-type(13),
    &.sel-13 button:nth-of-type(14),
    &.sel-14 button:nth-of-type(15),
    &.sel-15 button:nth-of-type(16),
    &.sel-16 button:nth-of-type(17),
    &.sel-17 button:nth-of-type(18),
    &.sel-18 button:nth-of-type(19),
    &.sel-19 button:nth-of-type(20) {
        transition: $transition;
        background: $color-timberwolf;
    }

    &__button {
        &:focus {
            z-index: 1;
        }
    }

    &--no-margin {
        margin-bottom: 0;
    }
}

button.button--overflow {
    padding-left: 10px;
    padding-right: 10px;
    &:after {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $color-slate;
        box-shadow: 0 -5px 0 $color-slate, 0 5px 0 $color-slate;
    }
    &:hover {
        &:after {
            background-color: $color-active-blue;
            box-shadow: 0 -5px 0 $color-active-blue, 0 5px 0 $color-active-blue;
        }
    }
}
// Consolidates styles for centered group of buttons with optional cancel link
.button-group {
    margin: 25px 0 0;
    text-align: center;
    white-space: nowrap;
    button,
    .button,
    input[type=button] {
        margin: 0 5px;
    }
    a {
        text-transform: none;
        &.secondary {
            color: $color-rhino;
        }
        &.cancel {
            display: inline-block;
            margin-top: 15px;
            font-weight: normal;
        }
    }
}

.tile-button,
button.tile-button {
    background: $color-white;
    border: 1px solid $color-accent-neutral-70;
    color: $color-slate;
    cursor: pointer;
    font-size: 15px;
    padding: $spacing-xl $spacing-lg $spacing-lg $spacing-lg;
    line-height: normal;
    height: auto;
    white-space: normal;

    display: flex;
    flex-direction: column;
    position: relative;

    align-items: center;
    justify-content: space-between;
    -ms-flex: 1 1 100%;

    $icon-size: 80px;

    &:hover,
    &:active,
    &.tile-button--selected {
        border-color: $color-active-blue;

        .tile-button__icon {
            color: $color-active-blue;
        }
        .tile-button__icon-container {
            svg {
                fill: $color-active-blue;
            }
        }
    }
    &.tile-button--selected {
        box-shadow: 0 2px 5px 0 rgba($color-black, 0.2);

        & .tile-button__title {
            @include font-pn-semibold;
        }
    }

    &__details {
        position: relative;
        text-align: left;
        color: $color-neutral-50;
        list-style: none;
        margin-top: $spacing-lg;
        line-height: 1em;

        li {
            margin-bottom: $spacing-xs;

            &:before {
                content: '•';
                position: absolute;
                left: -10px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__divider {
        border-top: 1px solid $color-neutral-50;
        margin-top: $spacing-lg;
        width: 100%;
    }

    &__icon {
        font-size: $icon-size;
        color: $color-midnight;

        &--action {
            font-size: 28px;
            margin-bottom: $spacing-lg;
        }
    }

    &__icon-container {
        height: $icon-size;
        margin-bottom: $spacing-lg;
        display: flex;
        align-items: center;
        justify-content: center;

        &--square {
            margin-bottom: $spacing-md;
        }

        img,
        svg {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__content {
        @include break-word;
        flex-grow: 1;
        max-width: 100%;
        overflow: hidden;

        line-height: 1.3em;
        text-align: left;

        &--action {
            text-align: center;
        }
    }

    &__top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: $zindex__nav--primary;

        &-left {
            position: absolute;
            top: $spacing-xs;
            left: $spacing-xs;
        }

        &-right {
            position: absolute;
            top: $spacing-sm;
            right: $spacing-xs;
        }
    }

    &__title {
        color: $color-active-blue;
        font-size: 17px;
        margin-top: $spacing-lg;
        text-transform: uppercase;
        word-break: break-word;

        &--square {
            flex-grow: 1;
            margin-top: 0;
        }
    }

    &__action-title.text-primary {
        font-weight: 600;
    }

    &--panel {
        @include arrow(20px);

        &:after {
            bottom: -2%;
            transform: rotate(180deg);
            transform-origin: 25%;
        }
    }

    &--square {
        flex-basis: 165px;
        flex-grow: 0;
        min-height: 165px;
        min-width: 165px;
        padding: 25px;
    }

    &--action {
        flex-grow: 0;
        justify-content: flex-start;
        margin-bottom: $spacing-xl;
        min-width: 180px;
        padding: $spacing-lg $spacing-sm;
        min-height: 180px;

        &:hover {
            box-shadow: 0 2px 5px 0 rgba($color-black, 0.2);

            // Overriding default hover style
            border: 1px solid $color-accent-neutral-70;
            .tile-button__icon {
                color: $color-slate;
            }
        }

        &:active {
            box-shadow: inset 0 2px 5px 0 rgba($color-black, 0.2);
        }
    }
}

.tile-selector {
    display: flex;

    > * {
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: $spacing-md;
        flex-basis: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    &--stacked {
        display: block;

        .flex-row {
            display: block;

            a {
                display: flex;
            }
        }

        > * {
            margin-right: 0;
            margin-bottom: $spacing-md;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--square {
        flex-wrap: wrap;
        margin-bottom: -30px;

        .tile-button--square {
            margin-bottom: 30px;
        }

        .tile-selector__group {
            flex-shrink: 0;
            max-width: 100%;

            .tile-button--square {
                margin-bottom: 30px;
            }
        }
    }

    // Putting this here instead of in .tile-button--action due to specificity issues from the rule above
    &--action {
        flex-wrap: wrap;
        > * {
            flex-grow: 0;
            margin-right: $spacing-xl;
        }
    }

    &__group {
        flex-flow: column wrap;
        height: auto;

        &:not(:last-of-type) {
            margin-right: $spacing-xl;
        }

        &--grow-1 {
            flex-grow: 1;
        }

        &--grow-2 {
            flex-grow: 2;
        }

        &--grow-3 {
            flex-grow: 3;
        }

        &--grow-4 {
            flex-grow: 4;
        }

        &--square {
            flex-grow: 0;
            flex-basis: auto;
        }

        &--stacked {
            margin-right: 0;
        }

    }

    &__group-tiles {
        flex: 1;
        margin-top: $spacing-sm;
        width: 100%;

        > * {
            flex-grow: 1;
            flex-shrink: 1;

            margin-right: $spacing-md;
            flex-basis: 0%;
            &:last-child {
                margin-right: 0;
            }
        }

        &--stacked {
            flex-flow: column nowrap;

            > * {
                margin-bottom: $spacing-md;
                margin-right: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__group-title {
        color: $color-accent-neutral-40;
        font-size: $font-size-reduced;
        text-transform: uppercase;
        width: 100%;
    }
}

.tile-button--side-icon,
button.tile-button--side-icon {
    flex-direction: row;

    box-sizing: border-box;

    padding: $spacing-md $spacing-lg;

    .tile-button {
        &__icon {
            line-height: 60px;

            &:before {
                font-size: 60px;
                line-height: 60px;
                height: 60px;
                vertical-align: middle;
            }
        }

        &__icon-container {
            height: 60px;
            width: 60px;
            margin: 0 $spacing-lg 0 0;
        }

        &__content-container {
            flex-grow: 1;

            text-align: left;
        }

        &__title {
            display: flex;

            margin-top: 0;
            text-align: left;
        }

        &__note {
            @include text-body;

            flex-grow: 1;
            flex-shrink: 0;

            text-transform: none;
            text-align: right;
            margin-left: $spacing-lg;
        }

        &__link {
            font-size: $font-size-normal;
        }
    }
}

.tile-button--stacked-small,
button.tile-button--stacked-small {
    padding: $spacing-sm;
    margin-bottom: $spacing-md;
    margin-right: 0;

    .tile-button {
        &__icon {
            line-height: 40px;

            &:before {
                font-size: 40px;
                line-height: 40px;
                height: 40px;
            }
        }

        &__icon-container {
            height: 40px;
            width: 40px;
            margin: 0 $spacing-md 0 0;
        }

        &__title {
            display: flex;
            color: $color-accent-neutral-10;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 0 !important;
            text-align: left;
        }
    }
}

.tile-panel {
    box-shadow: 0 3px 4px 0 rgba($color-black, 0.1);

    background-color: $color-active-blue;
    box-sizing: border-box;
    color: $color-white;
    margin-right: auto;
    margin-top: $spacing-xs;
    padding: $spacing-lg;
    width: 66%;

    &--right {
        margin-left: auto;
        margin-right: 0;
    }

    &__label {
        border-bottom: 0.25px solid $color-neutral-80;
        padding-bottom: $spacing-sm;
        text-transform: uppercase;
    }

    &__option {
        display: flex;
        justify-content: space-between;
        margin-left: $spacing-md;
        padding: $spacing-lg $spacing-sm $spacing-md;

        &:not(:last-child) {
            border-bottom: 0.25px solid $color-neutral-80;
        }

        &__label {
            @include font-pn-semibold;
            font-size: 17px;
            margin-bottom: $spacing-xs;
        }

        &__button {
            border-color: $color-white;
            border-radius: 3px;
            color: $color-active-blue;

            &:hover {
                text-shadow: 0 0 0.5px $color-active-blue;
                border-color: $color-white;
                box-shadow: 0 0 3px #0e4054;
            }
        }
    }
}

.tile-button__grid {
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex-basis: calc((100% / 3) - #{$spacing-sm} * 2);
        box-sizing: border-box;
        margin: 0 $spacing-sm $spacing-md $spacing-sm;

        .tile-button__content {
            min-height: 350px;
        }

        @media only screen and (max-width: 1250px) {
            flex-basis: calc((100% / 2) - #{$spacing-sm} * 2);
        }

        @media only screen and (max-width: 1000px) {
            flex-basis: 100%;
        }
    }

    &--2-cols {
        & > * {
            flex-basis: calc((100% / 2) - #{$spacing-sm} * 2);
        }
    }
}

.button-add-modal {
    float: right;
}

.tab-set-children {
    margin-left: $spacing-lg;
    > .page-section {
        margin-left: 0;
    }
}
