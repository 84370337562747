.field-set-component {
    @include font-pn-regular;
    @include break-word;
    border: 1px solid $color-dolphin;
    border-radius: 5px;
    margin: 0;
    height: auto;
    line-height: 1em;
    background-color: transparent;
    padding: $spacing-md;

    legend {
        @include text-parent-label;
        padding: 0 $spacing-sm;
    }

    & .input-file__selected-content {
        margin: $spacing-sm;
    }

    &--full-width {
        flex-basis: 100%;
    }

}
