@import 'charting/chip-panel';
@import 'charting/multiseries-chart';
@import 'charting/dropdown-selector';
@import 'charting/legend';
@import 'charting/chart-wrapper';
@import 'general/global-message';
@import 'general/image-component';
@import 'layout/dashboard-cards';
@import 'layout/card-grid';
@import 'layout/charts';
@import 'layout/_disabled.scss';
@import 'layout/hero-chart';
@import 'layout/edit-section';
@import 'layout/policy-node';
@import 'layout/scroll-box';
@import 'layout/stack';
@import 'layout/header-bar';
@import 'layout/view-toggle';
@import 'layout/label-value-pairs';
@import 'layout/callout-box';
@import 'layout/aside-container';
@import 'layout/stretch-content';
@import 'layout/_list-nav.scss';
@import 'layout/flex-row';
@import 'layout/flex-item';
@import 'layout/field-set';
@import 'layout/file-details';
@import 'layout/padding';
@import 'layout/mapped-attributes';
@import 'layout/chip';
@import 'layout/linking-arrow';
@import 'password-meter';
@import 'templates/expandable-row-tooltip.scss';
@import 'templates/section-columns';
@import 'layout/nav-card';
@import 'layout/nav-frame';
@import 'general/node-field';
@import 'layout/segmented-box';

$layout-header-height: 46px;
$layout-nav-width: 205px;
$layout-nav-link-height: 47px; // works better as odd number
$layout-nav-width-offset: 15px;

// LOGOS
.logo-pingone {
    background-image: url('#{$img-path}logo-pingone.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 21px;
    width: 76px;
}

.logo-pingaccess {
    background-image: url('#{$img-path}logo-pingaccess.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 21px;
    width: 95px;
}

.logo-pingfed {
    background-image: url('#{$img-path}logo-pingfed.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 21px;
    width: 115px;
}

.logo-uilib {
    background-image: url('#{$img-path}logo-uilibrary.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 29px;
    width: 101px;
}


// CORE PAGE LAYOUT STYLES -------------------------------------------------------------------------
#header {
    z-index: $zindex__nav--primary;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

// LEFT NAV MENU COLORS
$color-grey-main: $color-strong-neutral-10;
$color-grey-main-1: $color-strong-neutral-20;
$color-grey-main-2: $color-strong-neutral-30;
$color-grey-minor-1: $color-neutral-30;

@mixin inactive-link-styles {
    transition: transform 0.2s ease-out;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-active;
    transform: scaleX(0);
    visibility: hidden;
}

@mixin active-link-styles {
    transition: transform 0.1s ease-out;
    transform: scaleX(1);
    visibility: visible;
}

#nav {
    z-index: $zindex__nav--primary - 1; // we want it just under the header bar
    position: absolute;
    top: $layout-header-height;
    bottom: 0;
    left: 0;

    .context-selector {
        padding-bottom: 0;

        &.context-selector-open {
            .context-selector-menu {
                display: block;
            }
        }
        .menu {
            max-height: none;
            overflow: visible;
        }
        .context-selector-value {
            margin: 0;
            min-height: 46px;

            &:hover {
                background-color: inherit;
            }
            &:after {
                right: 15px;
            }
            &.has-icon {
                padding-left: 56px;
            }
        }
        .context-selector-menu {
            display: none;
            padding: 5px 0 10px;
            background-color: rgba($color-black, 0.05);
            z-index: $zindex__menu;

            animation: fade-in 0.3s;

            a {
                padding: 10px 10px 10px 25px;
                color: $color-walrus;
                font-size: 14px;


                &:hover {
                    background-color: inherit;
                }
                &.context-selector-add {
                    padding: 0 10px 0 25px;
                    margin: 15px 0;
                    color: $color-active;

                    &:before {
                        content: '+';
                        font-family: $icon-font;
                        font-size: 8px;
                        display: inline-block;
                        margin: 0 5px 0 0;
                    }
                }
            }
        }
    }
}

#nav.legacy-left-nav {
    width: $layout-nav-width + $layout-nav-width-offset;
    box-sizing: border-box;
    background-color: $color-timberwolf;
    border-right: $layout-nav-width-offset solid $color-white;
    display: flex;
    flex-direction: column;

    .left-nav__title-link {
        display: block;

        &:after {
            display: inline-block;
            top: auto;
            left: auto;
            right: auto;
            margin: 0 0 0 10px;
            color: inherit;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .nav-section {
        padding: 16px 0 14px;
        border-bottom: 1px solid $color-rabbit-fur;

        &:first-of-type {
            margin-top: -15px;
        }
        &.open {
            padding-bottom: 25px;

            .menu {
                max-height: 20000px;
            }
        }
    }
    .selected-item-arrow + .nav-section {
        padding-top: 0;
    }
    .title {
        padding: 10px 5px 10px 17px;
        position: relative;
        color: rgba($color-midnight, 0.55);
        display: block;
        font-size: 12px;
        line-height: 1em;
        text-transform: uppercase;
        @include font-pn-semibold;

        &.narrow {
            margin-top: 5px;
        }

        [class^='icon-'],
        [class*=' icon-'] {
            display: none; // eliminate all icons for section titles in the old nav
        }
    }
    .selected-item {
        transition: all 0.3s;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: $layout-nav-width;
        box-sizing: border-box;
        z-index: $zindex__hidden;
        background-color: $color-white;
        border-style: solid;
        border-color: $color-rabbit-fur;
        border-width: 1px 0;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 8px;
            background-color: $color-active;
        }
    }
    .selected-item-arrow {
        transition: all 0.3s;
        position: absolute;
        left: $layout-nav-width;
        top: 0;
        bottom: 0;

        .arrow {
            stroke: $color-rabbit-fur;
            fill: none;
            stroke-width: 2;
        }
    }
    .nav-menus {
        overflow-x: hidden;
        overflow-y: auto;
        width: $layout-nav-width + 10px;
        padding: 15px 10px 0 0;
        box-sizing: border-box;
        flex: 1;
        position: relative;
    }
    ul.menu {
        font-size: 16px;
        width: $layout-nav-width + 10px;
        max-height: 0;
        overflow: hidden;

        > li {
            transition: all 0.3s;
            position: relative;

            a {
                position: relative;
                display: block;
                box-sizing: border-box;
                padding: 16px 20px 16px 25px;
                line-height: 1em;
                font-size: 14px;
                @include font-pn-regular;
                color: $color-slate;
                background-color: transparent;

                &:hover {
                    background-color: rgba($color-white, 0.5);
                    text-decoration: none;
                }
                span {
                    display: inline-block;
                    pointer-events: inherit;
                }
            }
            &.highlighted {
                a:hover {
                    background-color: inherit;
                }
            }
            [class^='icon-'],
            [class*=' icon-'] {
                position: absolute;
                left: 25px;
                top: 13px;
                font-size: 18px;
                width: 18px;
                text-align: center;
            }
            &.has-icon {
                a {
                    padding-left: 56px;
                }
            }
        }
    }
    .copyright {
        width: $layout-nav-width;
        box-sizing: border-box;
        padding: 20px 10px 25px 20px;
        @include font-pn-regular;
        font-size: 10px;
        line-height: 1.3em;
        color: $color-elephant;
        background: $color-neutral-90;

        .pingone-logo {
            max-width: 75px;
            display: block;
            margin: 0 0 7px;
            fill: $color-elephant;
        }
        // application logos
        .logo-container {
            margin: 0 0 $spacing-sm 0;

            @include clearfix();

            .ping-application {
                display: block;
                max-width: 175px;
                max-height: 52px;
                height: auto;
            }
        }
    }
    &.scrollable {
        .copyright {
            border-top: 1px solid darken($color-timberwolf, 7%);
        }
    }
    &.collapsible {
        .title {
            cursor: pointer;
        }
    }
    .top-content {
        padding: 0 10px 10px;

        .return-btn {
            position: relative;
            background-color: transparent;
            width: 100%;
            text-align: left;
            padding-left: 30px;

            &:before {
                content: $icon-expand-arrow;
                font-family: $icon-font;
                font-size: 6px;
                position: absolute;
                margin: -1px 0 0 0;
                left: 15px;

                transform: rotate(90deg);
            }
        }
    }
}

#nav.left-nav {
    $layout-nav-width: 205px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: $color-grey-minor-1;
    color: $color-white;
    width: $layout-nav-width;

    .left-nav__title-link {
        display: block;

        &:after {
            display: inline-block;
            top: auto;
            left: auto;
            right: 0;
            margin: 0 0 0 10px;
            color: inherit;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .nav-section {
        padding: 0;

        &.selected {
            .title {
                transition: background-color 0.2s;
                @include font-pn-semibold;
                background-color: $color-grey-main-2;
                &:before {
                    @include active-link-styles;
                }
                &:hover {
                    background-color: $color-grey-main;
                }
            }
        }
        &.highlighted .title {
            transition: padding 0, background-color 0.2s;
            @include font-pn-semibold;
            padding-right: 15px;
            background-color: $color-grey-main;
            &:before {
                @include active-link-styles;
            }
        }
        &.open {
            background-color: $color-grey-minor-1;

            &.selected {
                .title {
                    transition: background-color 0.2s;
                    @include font-pn-light;
                    background-color: $color-grey-main-2;
                    &:before {
                        height: 0;
                        background-color: $color-grey-main-2;
                    }
                    &:hover {
                        transition: background-color 0.2s;
                        background: $color-grey-main;
                    }
                }
            }
            .menu {
                max-height: 20000px;
                background-color: $color-grey-main-1;
                border-top: 1px solid shade($color-strong-neutral-10, 20%);
                border-bottom: 1px solid $color-neutral-50;

                [class^='icon-'],
                [class*=' icon-'] {
                    display: none;
                }
            }
        }
    }
    .selected-item-arrow + .nav-section {
        padding-top: 0;
    }
    .title {
        padding: 16px $spacing-md+2px 16px 45px;
        position: relative;
        color: $color-white;
        display: block;
        font-size: 14px;
        line-height: 1em;
        @include font-pn-light;

        &.narrow {
            margin-top: 5px;
        }

        [class^='icon-'],
        [class*=' icon-'] {
            position: absolute;
            top: 13px;
            left: 15px;
            font-size: 18px;
            width: 30px;
            margin: 0 -5px;
            text-align: center;
        }
        &.open {
            transition: background-color 0.2s;
            background-color: $color-grey-main-2;
        }
        &.left-nav__title-link {
            margin-right: 0;
            &:after {
                right: 15px;
            }
        }
        &:hover {
            transition: background-color 0.2s;
            text-decoration: none;
            background-color: $color-grey-main;
        }
        &:before {
            @include inactive-link-styles;
        }
    }
    .nav-menus {
        overflow-x: hidden;
        overflow-y: auto;
        width: $layout-nav-width;
        padding: 0;
        box-sizing: border-box;
        flex: 1;
    }
    .menu {
        font-size: 16px;
        width: $layout-nav-width;
        max-height: 0;
        overflow: hidden;

        > li {
            transition: all 0.3s;
            position: relative;

            a {
                @include font-pn-light;

                position: relative;
                display: block;
                box-sizing: border-box;
                padding: 13px 20px 13px 45px;
                line-height: 1em;
                font-size: 14px;
                color: $color-white;

                &:hover {
                    text-decoration: none;
                    transition: background-color 0.2s;
                    background-color: $color-grey-main;
                }
                span {
                    display: inline-block;
                    pointer-events: inherit;
                }
                &:after {
                    @include inactive-link-styles;
                }
            }
            &.highlighted {
                transition: background-color 0.2s;
                width: $layout-nav-width;
                box-sizing: border-box;
                background-color: $color-grey-main;
                overflow: hidden;

                &:after {
                    @include active-link-styles;
                }
                a {
                    @include font-pn-semibold;
                    &:hover {
                        background-color: inherit;
                        &:after {
                            @include active-link-styles;
                        }
                    }
                }
            }
            [class^='icon-'],
            [class*=' icon-'] {
                position: absolute;
                top: 10px;
                left: 15px;
                font-size: 18px;
                width: 18px;
                text-align: center;
            }

            &:after {
                @include inactive-link-styles;
            }
        }
    }
    .copyright {
        @include font-pn-regular;

        width: $layout-nav-width;
        box-sizing: border-box;
        padding: 20px 10px 25px 15px;
        font-size: 10px;
        line-height: 1.3em;
        color: $color-white;

        .pingone-logo {
            max-width: 75px;
            display: block;
            margin: 0 0 7px;
            fill: $color-elephant;
        }
        // application logos
        .logo-container {
            margin: 0 0 $spacing-sm 0;

            @include clearfix();

            .ping-application {
                display: block;
                max-width: 175px;
                max-height: 52px;
                height: auto;
            }

            .logo {
                $logo-env-size: 52px;
                $logo-env-pad: 5px;

                height: $logo-env-size;
                width: $logo-env-size;

                display: inline-block;
                vertical-align: middle;
                background-image: url('#{$img-path}logo-pingidentity.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-color: transparent;

                img {
                    margin: 5px 0 0;
                    max-height: $logo-env-size - 2 * $logo-env-pad;
                    max-width: $logo-env-size - 2 * $logo-env-pad;

                    &.ping {
                        margin: 0;
                        max-height: none;
                        max-width: none;
                        height: 52px;
                        width: 52px;
                    }
                }
            }
        }


        border-top: none;
        &:before,
        &:after {
            background: none;
        }
    }
    &.collapsible {
        &.open {
            background-color: $color-grey-main-2;
        }
    }
    .top-content {
        padding: 15px 10px 13px 15px;
        color: $color-slate;
        text-transform: uppercase;
        font-size: 12px;
    }

    &.scrollable {
        .copyright {
            width: $layout-nav-width;
            &:before,
            &:after {
                background: none;
            }
        }
    }
    .selected-item,
    .selected-item-arrow {
        display: none;
    }

    &.nav--light {
        background-color: $color-timberwolf;
        box-shadow: 1px 0 0 $color-neutral-80;

        .title {
            color: $color-slate;
            font-weight: 400;

            [class^='icon-'],
            [class*=' icon-'] {
                color: $color-strong-neutral-30;
            }

            &:hover {
                background: $color-white;
            }
        }

        .top-content {
            color: $color-elephant;
            @include font-pn-semibold;
        }

        .nav-section.open .title,
        .nav-section.selected .title {
            background: $color-neutral-80;

            &:hover {
                background: $color-white;
            }
        }

        .nav-section.open.selected .title {
            background-color: $color-neutral-80;
            font-weight: 400;
        }

        .nav-section.open,
        .nav-section.open .menu {
            background: $color-neutral-95;
        }

        .nav-section.open .menu {
            background: $color-neutral-95;
            border-top: 1px solid $color-strong-neutral-70;
            border-bottom: 1px solid $color-white;
        }

        .nav-section,
        .nav-section .menu {
            a {
                color: $color-slate;
                font-weight: 400;
            }
        }

        .menu > li a:hover {
            background: $color-white;
            box-shadow: 0 1px 0 $color-neutral-90;
        }

        .menu > .highlighted {
            box-shadow: 0 -1px 0 $color-neutral-80;
        }

        .menu > .highlighted,
        .nav-section.highlighted .title {
            background: $color-white;
            font-weight: 600;

            a {
                font-weight: 600;
            }

            &:hover {
                background: $color-white;
            }
        }

        .copyright {
            color: $color-slate;
        }
    }

}

#content,
.content-area {
    @extend .stretch-content;

    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    top: $layout-header-height;
    right: 0;
    bottom: 0;
    left: $layout-nav-width;
    padding: 25px 40px 0;
    box-sizing: border-box;

    // workaround for bottom padding - bottom padding is ignored in scrolling elements in FF and IE
    &:after {
        flex-shrink: 0;

        content: '';
        display: block;
        height: 2 * $spacing-xl;
    }

    .content-area {
        top: 0;
        left: 0;

        &--no-left-padding {
            padding: 25px 40px 0 0;
        }

    }

}

@mixin message-button-css {
    button,
    .button {
        background-color: transparent;
        color: $color-white;
        border-color: $color-white;
        vertical-align: baseline;
    }
}
@mixin message-primary-button-css ($color) {
    .button,
    button {
        &.inline.primary {
            border: 1px solid $color-white;
            background-color: $color-white;
            color: $color;
        }
    }
}

@import 'layout/messages';

// REUSABLE PAGE STYLES ----------------------------------------------------------------------------
.count {
    color: $color-neutral-20;
    border: 1px solid $color-neutral-60;
    padding: 0 5px;
    display: inline-block;
    min-width: 19px;
    height: 19px;
    line-height: 19px;
    text-align: center;

    box-sizing: border-box;
    border-radius: 7px;
}

@import 'layout/collapsible-link';

.page-return-link {
    position: relative;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 $spacing-md;
    white-space: nowrap;
    padding-left: $spacing-md;

    &:before {
        content: 'Y';
        position: absolute;
        top: -1px;
        left: -1px;
        font-size: 10px;
        font-family: $icon-font;

        transform: rotate(90deg);
    }
}

.forward-link {
    position: relative;
    display: inline-block;
    font-size: 14px;

    &:after {
        content: 'Y';
        position: absolute;
        top: -1px;
        right: -14px;
        font-size: 6px;
        font-family: $icon-font;

        transform: rotate(-90deg);
    }
}

.collapsible-divider {
    overflow: hidden;

    & > *:before {
        content: '';
        position: absolute;
        height: 1px;
        border-top: 1px solid $color-slate;
        top: 50%;
        left: 100%;
        width: 5000px;
        margin-left: $spacing-lg;
    }

    &__toggle {
        text-transform: uppercase;
        color: $color-slate;
        font-size: 15px;
        margin: $spacing-md 0;
        padding: 0;

        &.right:after,
        &:hover {
            color: $color-slate;
        }
    }
}

.page-title {
    @include text-page-title;

    margin: 0 0 30px;
    word-wrap: break-word;

    &.underlined {
        border-bottom: 1px solid $color-rabbit-fur;
        padding: 0 0 15px;
    }
    .page-subtitle {
        margin: 5px 0 0;
        font-size: 14px;
        display: block;
        color: $color-elephant;
    }
}

.page-section-group-title,
.page-section-title {
    margin: 0 0 $spacing-lg;
    padding: 0 0 5px;
    border-bottom: 1px solid $color-neutral-70;
    line-height: 1em;
    @include font-pn-regular;
    color: $color-strong-neutral-10;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    &.page-section-title-nounderline {
        border-bottom: 0;
        padding: 0;
    }
}

.page-section__title-accessories {
    color: $color-elephant;
    text-transform: none;
}

.page-section-group-title {
    font-size: 18px;
}

.page-section-title {
    font-size: 15px;
}

.page-section {
    margin: 0 0 40px 25px;
}

.page-section__description,
.page-section-title-desc { // deprecated selector
    margin: -10px 0 25px 0;

    &.only {
        margin-bottom: 40px;
    }
}

.page-group-content {
    padding: 0 0 $spacing-sm $spacing-sm;
}

.page-section-content {
    margin: 0 0 40px 25px;

    .page-section & {
        margin-bottom: 0;
    }
}

.page-section-data-title {
    font-size: 14px;
    text-transform: uppercase;
    color: $color-elephant;
    margin: 0 0 5px;
}

.page-section-data {
    display: table;
    margin: 0 0 30px;

    .data-item {
        display: table-row;

        .data-item-label,
        .data-item-value {
            display: table-cell;
            padding-top: 5px;
            padding-bottom: 5px;
            @include font-pn-regular;
            line-height: 1em;
        }
        .data-item-label {
            font-size: 13px;
            text-transform: uppercase;
            padding-right: 20px;
            color: $color-elephant;
            // when icons, images are present as values
            // keeps labels better aligned
            vertical-align: top;

            &:after {
                content: ':';
            }
        }
        .data-item-value {
            font-size: 14px;
            color: $color-midnight;
        }
    }
}

.page-section-data-divider {
    background: none;
    border-style: dotted;
    border-width: 1px 0 0;
    border-color: $color-rabbit-fur;
    margin: -25px 0 5px 0;
}

.page-controls-secondary {
    float: right;
    margin: -4px 0 0 0;

    > * {
        display: inline-block;
        margin: 0 0 0 15px;
    }
    .input-toggle {
        a.toggle {
            margin: 0;
        }
    }
    .details-tooltip {
        z-index: $zindex__elevated;
    }
}

.page-controls-primary {
    box-sizing: border-box;
    position: fixed;
    z-index: $zindex__fixed-controls;
    left: $layout-nav-width + 40px;
    right: 40px;
    bottom: 0;
    padding: 20px 0;
    text-align: right;
    background: rgba($color-white, 0.9);
    border-top: 1px solid $color-rabbit-fur;

    transition: translate 0.2s;

    &.hidden {
        transform: translateY(100%);
    }

    @include button-spacing();

    &--unfixed {
        background: none;
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        margin-top: $spacing-md;
        z-index: $zindex__base;

        &.hidden {
            display: none;
        }
    }
}

.button-bar {
    &__buttons {
        display: inline-flex;
        flex-flow: row-reverse;
    }

    &--left-align &__buttons {
        flex-flow: row;
    }

    &--left-align {
        text-align: left;
    }

    &__cancel-link {
        &.button {
            line-height: 36px;

            @include font-pn-semibold;
        }
        margin: 0 $spacing-lg 0 $spacing-md;
    }
}


.content-columns {
    display: flex;

    .content-column {
        flex: 1;
        box-sizing: border-box;
        margin: 0 40px 0 0;
        max-width: 100%;

        flex-grow: 1;

        &:last-of-type {
            margin: 0;
        }

        &--ie11 {
            flex: 1 1 auto;
        }
    }
    &.columns-margin-none,
    &.columns-nopad {  // nopad DEPRECATED
        .content-column {
            margin: 0;
        }
    }
    &.columns-margin-20 {
        .content-column {
            margin: 0 20px 0 0;

            &:last-of-type {
                margin: 0;
            }
        }
    }
    &.columns-width-auto {
        .content-column {
            flex: auto;

            flex-grow: 0;
        }
    }
    &--divided {
        .content-column {
            margin-right: $spacing-lg;
            padding-right: $spacing-lg;
            border-right: 1px solid $color-rabbit-fur;

            &:last-child {
                margin-right: 0;
                border-right: none;
                padding-right: 0;
            }
        }
    }
}

.hr {
    border: 1px dashed $color-rabbit-fur;
    margin: 25px 0;
    height: 1px;
    border-width: 1px 0 0;

    &--zero {
        margin: 0;
    }

    &--xs {
        margin: $spacing-xs 0;
    }

    &--sm {
        margin: $spacing-sm 0;
    }

    &--md {
        margin: $spacing-md 0;
    }

    &--solid {
        border-style: solid;
    }
}

// flakey but one of only a few options : use with caution
.valign-container {
    display: table !important;
    height: 100%;

    .valign-content {
        display: table-cell !important;
        vertical-align: middle !important;
    }
}

.align-center {
    text-align: center;
}


// DEPRECATED OR SITE-SPECIFIC STYLES --------------------------------------------------------------
.inlineForm {
    display: inline-block;
}

.inline-alert {
    position: relative;
    background: #fedba3;
    margin: 30px 0;
    padding: 15px 15px 15px 55px;
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:before {
        content: '!';
        font-family: $icon-font;
        position: absolute;
        left: 20px;
        top: 15px;
        font-size: 20px;
    }
    .title {
        margin: 0 0 5px;
    }
    &.reversed {
        background: transparent;
        border: none;
        color: $color-warning-text;
    }
}

#header {
    .nav {
        position: absolute;
        right: 4px;
        top: 15px;
        font-size: 24px;

        li {
            float: left;

            a {
                display: inline-block;
                vertical-align: middle;
                padding: 6px 20px 3px;
                border-right: 1px solid rgba($color-white, 0.2);

                &:hover {
                    text-decoration: none;
                    color: $color-white;
                }
            }
            &:last-child {
                a {
                    border-right: none;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.content-col-of-2,
.content-col-of-3,
.content-col-of-4,
.content-col-of-5 {
    float: left;

    .input-row {
        margin-right: 15px;
    }
}

.content-col-of-2 {
    max-width: 50%;

    &.maximize {
        max-width: none;
        width: 50%;
    }
}

.content-col-of-3 {
    max-width: 33.3%;

    &.maximize {
        max-width: none;
        width: 33.3%;
    }
}

.content-col-of-4 {
    max-width: 25%;

    &.maximize {
        max-width: none;
        width: 25%;
    }
}

.content-col-of-5 {
    max-width: 20%;

    &.maximize {
        max-width: none;
        width: 20%;
    }
}

.submit {
    width: 100%;
    clear: both;
    margin-top: 20px;
    padding: 0 0 10px;
    text-align: right;

    input[type=button] {
        &:last-of-type {
            margin-right: 0;
        }
    }
    .saving {
        line-height: 36px;
        height: 36px;
        width: 50px;
        bottom: 30px;
        right: 27px;

        &.icon-ellipsis {
            background-image: none;
        }
    }
}

a {
    &.back-link {
        display: block;
        margin: 30px 0;
    }
    &.no-break {
        white-space: nowrap;
    }
}

.checkbox-description {
    padding-left: 36px;
}

.value-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 14px;
    color: $color-midnight;

    &__icon {
        line-height: 1em;
    }

    &__value {
        margin-left: $spacing-sm;
    }

    &__inline {
        display: inline-block;
    }
}


// DEPRECATED CLASSES --------------------------------------------------------------------------------------------------

.search-bar {
    $animation-timing: ease-out 0.2s;

    .search-box {
        display: inline-block;
        vertical-align: middle;
    }

    .filter-by {
        font-size: 14px;
        margin: 12px 7px 0 15px;
        padding: 0;
    }
    .input-text {
        margin: 0;
        width: 460px !important;
    }
    .filters {
        display: none;
        padding-top: 20px;
        max-height: 0;

        transition: max-height $animation-timing;
    }
    .add-modal {
        display: inline;
        float: right;
    }

    &.expanded {
        .filter-by {
            &:before {
                transform: rotate(-180deg);
            }
        }
        .filters {
            display: block;
            max-height: 133px;

            animation: fade-in $animation-timing;
        }
    }
}
