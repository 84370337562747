.dropdown-button {
    &__options {
        box-shadow: $popup-box-shadow;
        box-sizing: border-box;
        line-height: 20px;
        padding: $spacing-xs 0;
        width: 202px;

        &--no-title {
            margin-top: 10px;
        }
    }

    &__option,
    &__title {
        padding: 8px 15px 7px 22px;
    }

    &__option {
        display: block;
        position: relative;
        cursor: pointer;

        &-icon {
            font-size: 10px;
            position: absolute;
            left: 7px;
            color: $color-white;
            transition: all 0.3s;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover &-icon {
            color: inherit;
        }

        &:hover,
        &.selected {
            text-decoration: none;
            background-color: $color-elderly;
        }

        transition: all 0.1s;
    }
}
