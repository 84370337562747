.scroll-box {
    $border-color: $color-neutral-90;
    max-height: 100%;

    position: relative;

    &__content {
        min-height: 0;
        overflow: auto;
    }

    &__top-shadow,
    &__bottom-shadow {
        visibility: hidden;
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        background: rgba($color-accent-neutral-70, 0.2);

        &--show {
            visibility: visible;
        }
    }

    &__top-shadow {
        border-top: 1px solid $border-color;
        top: 0;
    }

    &__bottom-shadow {
        border-bottom: 1px solid $border-color;
        bottom: 0;
    }
}
