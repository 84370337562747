.keyword-search {
    > * {
        width: 100%;
    }

    &__box {
        margin: $spacing-md 0 0 0;

        & > * {
            width: 100%;
        }
    }

    &__results {
        max-height: 60vh;
        overflow-y: scroll;
        position: relative;

        > :not(:last-child) {
            padding-bottom: $spacing-xs;
        }
    }

    &__result {
        color: $color-slate;
        display: block;
        height: 20px;
        padding: $spacing-md $spacing-sm;
        border-radius: 3px;

        &--selected,
        &:hover {
            background-color: $color-strong-neutral-90;
            color: $color-slate;
            text-decoration: none;
        }

        &__nav {
            font-size: 12px;
            line-height: 1em;
            padding-top: $spacing-sm;
        }

        &-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 55%;
        }

        &-section.text-component,
        &-root.text-component {
            width: auto;
            max-width: 45%;
        }
    }

    &__result-section,
    &__result-root,
    &__result-carat {
        line-height: 14px;
    }

    // Have to do this because of text component specificity
    &__title.text-component {
        color: $color-strong-neutral-40;
        margin-left: 10px;
    }

    &__result-root.text-component {
        color: $color-strong-neutral-60;
    }
}
