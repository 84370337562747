
.password-meter {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1em;
    min-height: 1em;

    @include text-style($color-slate, $font-size-reduced);

    &__label {
        visibility: hidden; // render all labels at once so that the label area takes the space of the longest string
        line-height: 0; // allows all labels to be positioned in the same spot
    }

    &__bar {
        flex-grow: 1;
        margin: 0 $spacing-xs 0 0;
        height: $spacing-xs;
        background-color: $color-neutral-80;

        transition: 0.2s background-color;
    }

    &--s1 {
        .password-meter__bar {
            &--s1 {
                background-color: $color-critical-red;
            }
        }
        .password-meter__label {
            &--s1 {
                visibility: visible;
            }
        }
    }
    &--s2 {
        .password-meter__bar {
            &--s1,
            &--s2 {
                background-color: $color-warning-yellow;
            }
        }
        .password-meter__label {
            &--s2 {
                visibility: visible;
            }
        }
    }
    &--s3 {
        .password-meter__bar {
            &--s1,
            &--s2,
            &--s3 {
                background-color: mix($color-warning-yellow, $color-success);
            }
        }
        .password-meter__label {
            &--s3 {
                visibility: visible;
            }
        }
    }
    &--s4 {
        .password-meter__bar {
            &--s1,
            &--s2,
            &--s3,
            &--s4 {
                background-color: $color-success;
            }
        }
        .password-meter__label {
            &--s4 {
                visibility: visible;
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}
