.aside-container {
    display: flex;
    flex-direction: row;

    &--full-height {
        > :last-child {
            align-self: stretch;
        }
    }

    > :last-child {
        flex-grow: 1;

        margin-left: $spacing-lg;
        box-sizing: border-box;
    }

    &--sm > :last-child {
        flex-shrink: 0;
        width: 250px;
    }

    &--md > :last-child {
        flex-shrink: 0;
        width: 400px;
    }

    &--lg > :last-child {
        flex-shrink: 0;
        width: 600px;
    }

    &__aside {
        &--top-right {
            flex-grow: 1;

            text-align: right;
        }

        &--right {
            flex-grow: 0;

            margin-left: auto;
        }
    }
}
