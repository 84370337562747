@import 'general/page-header';
@import 'general/formatted-content';

.text-weight {

    &--normal {
        font-weight: normal;
    }

    &--bold {
        // Text shadow used in place of font-weight to eliminate text changing size when bold
        text-shadow: 0 0 0.65px currentColor, 0 0 0.65px currentColor;
    }

    &--hover {

        &:hover {
            text-shadow: 0 0 0.65px currentColor, 0 0 0.65px currentColor;
        }
    }
}

.content-link {
    line-height: inherit;
    a {
        &.detached {
            display: inline-block;
            padding: 10px 0 10px 5px;
        }
        &.text-first {
            position: relative;
            .count,
            [class^='icon-'],
            [class*=' icon-'] {
                position: absolute;
                right: -30px;
            }
        }
        .count {
            color: $color-neutral-20;
            margin-right: $spacing-xs;
            min-width: 14px;
            height: 14px;
            line-height: 13px;
            font-size: 10px;
            padding: 0 4px;
            vertical-align: text-top;

            border-radius: 5px;
            &:hover {
                text-decoration: none;
            }
        }
        [class^='icon-'],
        [class*=' icon-'] {
            color: $color-blue-grey;
            display: inline-block;
            line-height: inherit;
            padding: 0 $spacing-xs 0 0;
            &:hover {
                text-decoration: none;
            }
            &:before {
                display: inline-block;
            }
        }

        &.more-on-topic,
        &.content-link--icon-color-active {
            [class^='icon-'],
            [class*=' icon-'] {
                color: #2996cc;
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: $inactive-opacity;
        }
    }
}

.content-halign-center {
    text-align: center;
}

.text-component {
    margin: 0 0 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &--inline {
        display: inline-block;
        margin: 0;
    }

    &--overflow-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &--overflow-wrap {
        @include break-word;
    }

    &--overflow-no-wrap {
        white-space: nowrap;
    }

    &--center {
        text-align: center;
    }

    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

    &--disabled {
        opacity: $inactive-opacity;
    }
}

.textblock {
    color: $color-elephant;
    font-size: 14px;
    margin: 0 0 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.textblock--white {
    color: $color-white;
}

.textblock--primary {
    color: $color-slate;
    font-size: 15px;
}

.textblock--primary-light {
    color: $color-midnight;
    font-size: 15px;
}

.textblock--primary-white {
    color: $color-white;
    font-size: 15px;
}

.textblock--semibold {
    @include font-pn-semibold;
}

.textblock--value {
    color: $color-midnight;
    font-size: 15px;
}

.masked-value {
    color: $color-midnight;
    font-size: 14px;

    &--masked {
        letter-spacing: 2px;
    }
    &__container {
        display: inline-flex;
        max-width: 100%;
    }
    &--masked &__value {
        max-height: 1em;
        overflow: hidden;
    }

    &__value {
        flex: 1 1 auto;
        word-break: break-word;
    }

    &__icon {
        color: $color-midnight;
        font-size: 20px;
        margin-left: $spacing-sm;
        line-height: 18px;
        flex: 0 0 auto;
        user-select: none;

        &:hover {
            text-decoration: none;
        }
    }
}

.anchor {
    &__icon {
        margin-right: $spacing-xs;
    }

    &--disabled {
        pointer-events: none;
        opacity: $inactive-opacity;
    }

    &--sm {
        font-size: $font-size-reduced;
    }
}

.pipe-row {
    line-height: 1em;
}

.pipe {
    display: inline-block;
    width: 1px;
    height: 1em;
    background: $color-rabbit-fur;
    margin: 0 $spacing-sm;
    transform: translateY(-1px);
    vertical-align: middle;

    &--sm {
        margin: 0 $spacing-xs;
    }
}


.select-text {
    @include break-word;
}
