
.tabs {
    margin-bottom: 25px;
    position: relative;
    border-bottom: 1px solid $color-rabbit-fur;

    ul {
        list-style-type: none;
        margin-bottom: 28px;

        li {
            display: inline-block;
            margin-right: 46px;
            padding-bottom: 8px;
            position: relative;
            text-transform: uppercase;
            color: $color-rhino;
            cursor: pointer;
            font-size: 13px;
            margin-bottom: 6px;

            a {
                color: $color-rhino;
                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                color: $color-active;
            }
            &.active {
                color: $color-slate;
                border-bottom: 3px solid $color-active;
                cursor: default;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    margin: 0 0 0 -7px;
                    border-width: 5px 7px;
                    border-style: solid;
                    z-index: $zindex__elevated;
                    border-color: $color-active transparent transparent transparent;
                    bottom: -13px;
                }
            }
        }
    }
}

// settings tabs
.settings-tabs {
    margin-bottom: 45px;

    ul {
        list-style-type: none;
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: 2px solid $color-rhino;

        li {
            display: inline-block;
            margin-right: 25px;
            margin-left: 16%;
            padding-bottom: 10px;
            position: relative;
            text-transform: uppercase;
            color: $color-rhino;
            cursor: pointer;
            height: 105px;
            border-bottom: 5px solid $color-white;

            a {
                color: $color-rhino;
                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                color: $color-active;
            }
            &.active {
                border-bottom: 5px solid $color-active;
                cursor: default;
                margin-bottom: 1px;

                transition: all 0.4s ease-in;
            }
        }
    }
}
