.page-header {
    margin: 0 0 30px;
    justify-content: space-between;
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &__icon {
        font-size: 24px;
        flex-basis: 40px;
        line-height: inherit;
        .iconComponent__graphic {
            line-height: inherit;
        }
    }

    &--margin-xs {
        margin-bottom: $spacing-xs;
    }

    &--margin-sm {
        margin-bottom: $spacing-sm;
    }

    &--margin-md {
        margin-bottom: $spacing-md;
    }

    &--margin-lg {
        margin-bottom: $spacing-lg;
    }
}

.page-header--underlined {
    border-bottom: 1px solid $color-rabbit-fur;
    padding: 0 0 15px;
}

.page-header__text {
    flex: 1 1 auto;
}

.page-header__title {
    @include font-pn-regular;
    @include break-word;
    font-size: 24px;
    color: $color-slate;
    line-height: 1.2em;

    margin-right: $spacing-sm;
}


.page-header__subtitle {
    font-size: 14px;
    color: $color-elephant;
}

.page-header__accessories {
    float: right;
    display: inline-flex;
    align-items: center;

    > * {
        margin: 0 0 0 20px;
    }
}

.page-header__image,
.page-header__node {
    $size: 40px;

    box-sizing: border-box;
    height: $size;
    width: 40px;
    flex-basis: $size;
    margin: 0 $spacing-md 0 0;

    img {
        margin: 50% 0 0 50%;
        transform: translateY(-50%) translateX(-50%);
        max-height: $size;
        max-width: $size;
    }
}

