.row-builder {
    &__rows {
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
        max-width: 100%;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__row.input-row {
        align-items: flex-end;
        display: inline-flex;
        position: relative;
        margin: 0 0 $spacing-sm;
    }

    &__add {
        margin-top: $spacing-md;
    }

    &__remove {
        margin-bottom: 1px;
        margin-left: auto;

        &__label {
            @include text-label;
            line-height: $font-size-reduced;
            min-height: $font-size-reduced;
            margin-bottom: 13px;
            text-transform: uppercase;
        }

        &__button {
            display: block;
            margin-left: $spacing-sm;
            margin-bottom: 6px;

            &--hidden {
                pointer-events: none;
                visibility: hidden;
            }
        }
    }

    &__remove-icon {
        color: $color-accent-neutral-30;
    }
    &__row:hover &__remove-icon,
    &__row:focus-within &__remove-icon {
        color: $color-strong-neutral-10;

        &:hover {
            color: $color-active-blue;
        }
    }

    &--form {
        &__row {
            align-items: flex-end;
            display: inline-flex;
            justify-content: space-between;
        }

        // Here for backward compatibility with old SSO Attributes template designs
        &__icon {
            font-size: 20px;
            margin-bottom: 11px;

            &--required {
                color: $color-dolphin;
            }
        }

        &__separator {
            font-size: 20px;
            margin: 0 20px 11px 8px;
        }

        &__input {
            &__option {
                color: $color-dolphin !important;
            }
        }

        &__no-label-above {
            margin-top: 25px !important;
        }
    }

    &--underlined {
        .row-builder__rows {
            > * {
                margin: $spacing-lg 0;

                &:after {
                    border-bottom: 1px solid $color-rabbit-fur;
                    bottom: -$spacing-lg;
                    content: '';
                    left: 0;
                    position: absolute;
                    width: 100%;
                }

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;

                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}
