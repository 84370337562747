.input-multivalues {
    @include text-value;
    position: relative;
    display: inline-block;
    text-transform: none;
    cursor: text;
    vertical-align: bottom;

    .clear {
        font-family: $icon-font;
        font-size: 10px;
        position: absolute;
        top: 28px;
        right: 26px;
        color: $color-input-text;
    }
    .entries {
        @include input-background;

        position: relative;
        z-index: $zindex__elevated;
        display: block;
        min-height: 36px;
        outline: none;
        overflow-y: auto;
        padding: $spacing-xs + 5px $spacing-sm 1px $spacing-sm;
        width: 100%;
        height: 200px;
        border: 1px solid $color-input-fill;

        &--error {
            border: 1px solid $color-critical-red;
        }

        &--auto-height {
            height: auto;
            min-height: 40px;
        }

        &:hover {
            border: 1px solid $color-input-border;
        }

        box-sizing: border-box;

        .entry {
            position: relative;
            border: 1px solid $color-input-border;
            background: $color-white;
            max-width: 100%;
            display: inline-block;
            margin: 0 $spacing-xs $spacing-xs 0;
            vertical-align: top;
            padding: $spacing-xs 30px $spacing-xs $spacing-sm;
            text-overflow: ellipsis;
            box-sizing: border-box;
            border-radius: 2px;

            label {
                @include break-word;
                @include text-value;
                max-width: 100%;
                text-transform: none;
                color: $color-midnight;
                margin: 0;
            }

            &__edit {
                font-size: 11px;
                position: absolute;
                left: 0;
                top: 0;
                transform: translate(-45%, -55%);

                &-icon {
                    border: 1px solid $color-neutral-80;
                    background-color: $color-white;
                    padding: 3px;
                    border-radius: 2em;
                    color: $color-midnight;

                    &:hover {
                        color: $color-active-blue;
                    }
                }
            }

            .delete {
                font-size: 11px;
                position: absolute;
                top: 5px;
                right: 11px;

                &:before {
                    content: 'X';
                    font-family: $icon-font;
                    font-size: 8px;
                    color: $color-midnight;
                }
                &:hover {
                    text-decoration: none;
                    &:before {
                        color: $color-active-blue;
                    }
                }
            }
            span {
                max-width: 100%;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
            }

            &--active {
                box-shadow: 0 1px 2px rgba($color-black, 0.1);

                border-color: $color-active-blue;

                .delete:before {
                    color: $color-active-blue;
                }
            }
        }
        [draggable] {
            @include grab-cursor;

            &:active {
                @include grab-cursor;
            }
        }
        .placeholder {
            background: #2996cc;
            width: 5px;
            height: 36px;
            display: inline-block;
        }
        .value-input {
            display: inline-block;
            outline: none;
            vertical-align: middle;
            padding: 0;
            max-width: 100%;
            position: relative;
            margin-left: 5px;

            &__hidden {
                position: relative;
                width: auto;
                display: inline-block;
                visibility: hidden;
                z-index: -1;
                padding-right: 20px;
            }

            &__wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            &__input {
                width: 100%;
                color: $color-input-text;
                box-sizing: content-box;
                background: none transparent;
                border: 0 none;
                box-shadow: none;
                cursor: text;
                display: inline-block;
                font-family: inherit;
                font-size: inherit;
                height: 26px;
                margin: 0;
                outline: none;
                padding: 0;
                -webkit-appearance: none;
            }
        }
    }

    &.stacked {
        .entries {
            .entry,
            .value-input {
                float: left;
                clear: both;
            }
        }
    }

    .iconComponent__graphic {
        padding: 0;
        margin-left: 8px;
        font-size: 12px;
    }

    &__popper.popper-container {
        margin-top: 0;
        margin-bottom: 0;
    }

    &--focused .entries,
    &--focused .entries:hover {
        border-color: $color-active-blue;
    }
}

.modifier_light-inputs .input-multivalues .entries .entry {
    border-color: $color-accent-neutral-90;
    background-color: $color-accent-neutral-90;
}

.value-input__placeholder {
    @include text-placeholder;
    @include break-word;
    margin-top: 6px;
}

