.input-filedrop {
    box-sizing: border-box;
    position: relative;

    border: 1px dashed $color-rabbit-fur;
    padding: $spacing-lg;
    background-color: $color-white;
    @include text-body;
    height: 95px;
    text-align: center;
    border-radius: 2px;
    line-height: 1em;

    margin-bottom: $spacing-lg;

    label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        text-transform: none;
        cursor: pointer;
    }
    input[type=file] {
        opacity: 0;
        position: absolute;
        z-index: $zindex__hidden;
    }

    &--replace-content {
        background: transparent;
        border: none;
        height: auto;
        line-height: inherit;
        margin: 0;
        padding: 0;

        label {
            z-index: -1;
        }
    }

    &--disabled {
        opacity: 0.5;
        label {
            cursor: default;
        }
    }
}

.input-filedrop--hover,
.input-filedrop--hover.input-filedrop--selected {
    background: #e4ecf2;
}

.input-filedrop--selected {
    background: lighten($color-success-green, 40%);

    label {
        position: relative;
        display: none;
        vertical-align: middle;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
    }
}

.input-filedrop__upload-text,
.input-filedrop__selected-content {
    @include vertical-align;
}

.input-filedrop__upload-text {
    line-height: 1.3em;
}

.input-filedrop__selected-content {
    @include text-primary;
    line-height: 15px;
}

.input-filedrop__selected-text {
    display: inline-block;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.input-filedrop__link {
    @include text-link;
    vertical-align: middle;

    .input-filedrop:hover .input-filedrop__upload-text & {
        text-decoration: underline;
    }
    .input-filedrop--disabled:hover .input-filedrop__upload-text & {
        text-decoration: none;
    }
}

.input-filedrop__divider {
    margin: 0 0 0 15px;
    padding: 0 0 0 15px;
    border-left: 1px solid $color-neutral-70;
}

.input-filedrop__icon {
    background-color: $color-success;
    margin: 0 $spacing-md 0 0;
}
