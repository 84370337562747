@import 'globals';
@import 'mixins';

.reporting-filters {
    margin-bottom: $spacing-lg;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-top: $spacing-md;
        background-color: $color-timberwolf;
    }

    button {
        margin-top: 25px;
    }

    &--divider {
        display: inline-block;
        border-right: 1px solid $color-stratus;
        height: 75px;
        padding-left: $spacing-md;
    }

    &--inputs {
        padding-left: $spacing-md;
    }

    &--button-margin {
        margin-left: auto;
        margin-right: $spacing-md;
    }
}


.report-filters {

    $border-color: $color-dolphin;
    $background-color: $color-white;

    display: inline-block;
    position: relative;
    box-sizing: border-box;
    min-width: 960px;
    padding: 20px 20px 0;
    background-color: $color-timberwolf;

    @include clearfix;

    .input-select,
    .input-textselect,
    .input-text,
    .input-calendar,
    .input-timezone,
    .input-selection-list-tooltip {
        margin: 0 15px 20px 0;
    }
    .input-textselect {
        .input-select,
        .input-text {
            margin: 0;
        }
    }
    .input-text,
    .input-textselect {
        vertical-align: bottom;
    }

    .input-text {
        input[type=text] {
            background-color: $background-color;
        }
    }
    .input-select {
        vertical-align: bottom;

        .input-container {
            select {
                background: $background-color;
            }
        }
        .wrapper {
            border-color: $border-color;
        }
    }
    .input-custom-select {
        .input-text {
            margin: 0;
        }
    }
    .input-calendar {
        vertical-align: bottom;

        input[type=text].input-calendar-value {
            border-color: $border-color;
            width: 145px;
        }
        .input-calendar-wrapper {
            background: $color-white;
        }
    }

    .input-textselect {
        .input-text {
            input {
                width: 90px;
            }
            &.form-error {
                > .input-container {
                    margin-right: 21px;

                    > input {
                        width: 70px;
                    }
                }
            }
        }
    }

    .input-timezone {
        vertical-align: top;
        line-height: 15px;
    }

    .divider {
        display: inline-block;
        width: 1px;
        height: 81px;
        margin: -10px 15px 5px 0;
        background-color: $color-stratus;
        vertical-align: top;
    }
    .more-option-inputs, // support for bad name until fixed in WP
    .more-options-inputs {
        border-top: 1px solid $color-dolphin;
        padding: 20px 0;
    }
    button.primary {
        float: right;
        margin: 25px 0 20px;
    }
}

.reports-add-btn {
    text-align: right;

    button {
        margin: -25px 0 0;
    }
}

.reports-more-options-link {
    margin: 15px 0 30px;
    padding: 0;
}

.reports-export-btn {
    float: right;
    margin: 15px 0 0;

    .icon-ellipsis {
        margin: 5px 36px 0 0;
    }
    button {
        margin: 0;
    }
}

.report-table {
    @mixin vertical-scroll {
        max-height: 600px;
        overflow: auto;
        max-width: 100%;
    }
    position: relative;
    overflow: hidden;
    //set divs to act like table
    .table {
        display: table;
    }
    .thead {
        display: table-header-group;
        transform: translateZ(0);
    }
    .tbody {
        display: table-row-group;
    }
    .tr {
        display: table-row;
    }
    .th,
    .td {
        display: table-cell;
        -moz-background-clip: padding;     /* Firefox 3.6 */
        -webkit-background-clip: padding;  /* Safari 4? Chrome 6? */
        background-clip: padding-box;      /* Firefox 4, Safari 5, Opera 10, IE 9 */
    }

    .dd-table-container {
        @include vertical-scroll;
        text-align: left;
        display: inline-block;
        .table {
            border-collapse: collapse;
            overflow: hidden;
            .th {
                position: relative;
                background-color: $color-white;
                padding: 20px 30px 10px;
                border: 1px solid $color-stone;
                //fix issues with border-collapse in Firefox.
                background-clip: padding-box;
                text-align: left;
                &[draggable] {
                    @include grab-cursor;
                    &:before {
                        color: default;
                        font-family: $icon-font;
                        content: $icon-grip;
                        margin-left: -15px - 4px;
                        margin-right: 15px - 4px;
                    }
                }
                a {
                    position: relative;
                    &.ascending,
                    &.descending {
                        &:after {
                            transition: all 0.2s;
                            font-size: 11px;
                            font-family: $icon-font;
                            content: $icon-spin-up;
                            position: absolute;
                            margin-left: 5px;
                        }
                    }
                    &.descending {
                        &:after {
                            transform: rotate(-180deg);
                        }
                    }

                }
            }
            .td {
                padding: 20px 10px 10px 30px;
                border: 1px solid $color-stone;
                border-bottom: 1px dashed $color-stone;
                border-top: none;
                min-width: 140px;
                height: 100%;
                &:first-child {
                    border-left-color: transparent;
                }
                &:last-child {
                    border-right-color: transparent;
                }

            }
            .dragging {
                opacity: 0.2;
            }
            .dd-column {
                &.drag-left {
                    border-left: 3px solid $color-active-blue;
                }
                &.drag-right {
                    border-left: 3px solid $color-active-blue;
                }
            }
        }
    }
    &.fixed-head {
        overflow: hidden;
        .dd-table-container {
            margin-top: 52px;
            max-height: 600px;
            max-width: 100%;
            overflow-x: auto;
            .thead {
                position: absolute;
                top: 0;
                .tr {
                    white-space: nowrap;
                    .th {
                        box-sizing: border-box;
                    }
                }
            }
            .tbody {
                max-height: 660px;
            }
            .td {
                box-sizing: border-box;
                word-wrap: break-word;
                //this truncated text that overflowed. May be added back if detailstooltip chagnes to fixed position.
                // overflow: hidden;
                // text-overflow: ellipsis;
            }
        }
    }
    &.infinite-scroll-container {
        .dd-table-container {
            max-height: none;
            .table {
                display: block;
            }
            .infinite-scroll-wrapper {
                > div {
                    max-height: 600px;
                }
            }
            .batch {
                display: table;
                overflow: hidden;
                border-bottom: 1px dashed $color-stone;
                overflow-y: visible;
                margin-right: 1px;
                > div {
                    display: table-row-group;
                    > div {
                        display: table-row;
                        > div {
                            display: table-cell;
                        }
                    }
                }
            }
        }
    }
    &.has-next {
        .batch:last-of-type {
            > div:last-of-type .td {
                position: relative;
                overflow: visible;
                &:before {
                    content: ('');
                    position: absolute;
                    display: block;
                    top: 0;
                    right: -1px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-white;
                    opacity: 0.5;
                    border-right: 1px solid $color-stone;
                }
            }
        }
    }
}
