
// PATHS ---------------------------------------------------------------------------------------------------------------
$img-path: '../images/';
$pn-font-path: '../fonts/proxima-nova/' !default;

// Allow library users to specify whether or not to use typekit by
// setting the $use-typekit variable in their sass file.
$use-typekit: true !default;

@mixin importTypekit($should-import: true) {
    @if $should-import {
        @import url('https://use.typekit.net/nxh5mrb.css');
    }
}

@include importTypekit($use-typekit);

$img-path: '../images/';

@import 'colors';
@import 'spacing';

// FONTS
// $font-stack: 'ProximaNovaRegular', helvetica, arial, sans-serif;
// $font-pn-cond-regular: 'ProximaNovaCondRegular', helvetica, arial, sans-serif;
$icon-font: 'iconfont';
$input-font-stack: proxima-nova, sans-serif;

@mixin font-pn-regular {
    font-family: 'proxima-nova', 'helvetica', 'arial', sans-serif;
    font-weight: 400;
    font-style: normal;
}
@mixin font-pn-light {
    font-family: 'proxima-nova', 'helvetica', 'arial', sans-serif;
    font-weight: 300;
    font-style: normal;
}
@mixin font-pn-semibold {
    font-family: 'proxima-nova', 'helvetica', 'arial', sans-serif;
    font-weight: 600;
    font-style: normal;
}
@mixin font-pn-bold {
    font-family: 'proxima-nova', 'helvetica', 'arial', sans-serif;
    font-weight: 700;
    font-style: normal;
}

// MISC
$layout-header-height: 64px;    // used in miltiple includes
$layout-nav-width: 190px;       // used in miltiple include
$line-height-default: 1.3em;

$inactive-opacity: 0.5;

// Z-INDEXES -----------------------------------------------------------------------------------------------------------
$zindex__modal:          80;
$zindex__nav--primary:   70;
$zindex__page-overlay:   60;
$zindex__message:        50;
$zindex__popover:        40;
$zindex__menu:           30;
$zindex__fixed-controls: 20;
$zindex__elevated:       10;
$zindex__base:            0;
$zindex__hidden:        -10;

// IMPORTS -------------------------------------------------------------------------------------------------------------
@import 'colors';
@import 'spacing';
@import 'text-styles';
