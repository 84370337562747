
table {
    &.grid {
        display: inline-block;
        border-collapse: separate;

        .grid {
            // not sure why grids display inline-block, but grids
            // within grids should display block, so they don't
            // align to the bottom
            display: block;
        }

        &.width-full {
            display: table;
            width: 100%;
            table-layout: fixed;

            $widths:
                (
                    5: 5,
                    10: 10,
                    12: 12.5,
                    15: 15,
                    20: 20,
                    25: 25,
                    30: 30,
                    33: 100/3,
                    35: 35,
                    40: 40,
                    45: 45,
                    50: 50,
                    55: 55,
                    60: 60,
                    65: 65,
                    66: 200/3,
                    70: 70,
                    75: 75,
                    80: 80,
                    85: 85,
                    87: 87.5,
                    90: 90,
                    95: 95
                );

            @each $name, $value in $widths {
                .column-#{$name}p {
                    width: $value * 1%;
                }
            }
        }
        th {
            font-size: 13px;
            color: $color-elephant;
            text-transform: uppercase;

            &.column-pg {
                padding: 0 0 0 20px;
                text-transform: none;
                font-size: 12px;
                font-weight: bold;
                line-height: 1em;
                white-space: nowrap;

                .icon-previous,
                .icon-next {
                    display: inline-block;
                    font-size: 11px;
                    vertical-align: text-top;
                }
                .icon-previous {
                    margin-right: 10px;
                }
                .icon-next {
                    margin-left: 10px;
                }
            }
            a.sort {
                position: relative;

                &:after {
                    position: absolute;
                    top: -1px;
                    right: -11px;
                    font-family: $icon-font;
                    text-transform: none;
                    content: $icon-sort-none;
                    font-size: 10px;
                    margin: 0 0 0 10px;

                }
                &.asc {
                    &:after {
                        top: -4px;
                        font-size: 4px;
                        content: $icon-sort-asc;
                    }
                }
                &.desc {
                    &:after {
                        top: 1px;
                        font-size: 4px;
                        content: $icon-sort-desc;
                    }
                }
            }
        }
        td {
            font-size: 14px;
            color: $color-midnight;
            word-break: break-word;

            button.inline {
                &.delete {
                    margin: -5px 0;
                }
            }
            &.cell-btn {
                vertical-align: middle;
            }
            .input-width-xsmall,
            .input-width-small,
            .input-width-medium,
            .input-width-large,
            .input-width-xlarge,
            .input-width-full {
                margin-bottom: 0;
            }
            .input-text,
            .input-select,
            .input-checkbox,
            .input-radio {
                margin-bottom: 0;
            }
            .input-checkbox {
                padding: 0;
            }
        }
        th,
        td {
            padding: 9px 20px 9px 0;
            border-bottom: 1px dotted $color-rabbit-fur;
            text-align: left;
            box-sizing: border-box;
            @include font-pn-regular;
            line-height: 1.1em;
            vertical-align: baseline;
            height: inherit;

            &.center {
                text-align: center;
            }
            &.right {
                text-align: right;
            }
            &:first-child {
                padding-left: 2px;
            }
            &:last-child {
                padding-right: 5px;
            }

            &.column-xs {
                width: 90px;
            }
            &.column-sm {
                width: 190px;
            }
            &.column-md {
                width: 290px;
            }
            &.column-lg {
                width: 390px;
            }
            &.column-xl {
                width: 490px;
            }
        }
        &--no-lines tbody {
            td,
            th {
                border-bottom: none;
                padding-bottom: $spacing-sm / 2;
            }
            tr + tr {
                td,
                th {
                    padding-top: $spacing-sm / 2;
                }
            }
        }

        &--fixed {
            table-layout: fixed;
        }
        &--full-width {
            display: table;
            width: 100%;
        }
        tr {
            &.expanded {
                td,
                th {
                    border-bottom: none;
                }
            }
            &.expanded-content {
                td {
                    padding: 15px;

                    animation: fade-in 0.1s;
                }
            }
            &.tr--fixed-header {
                th {
                    position: sticky;
                    top: 0;
                    background-color: #fff;
                    z-index: $zindex__elevated;
                    word-break: break-word;
                }
            }
        }
        thead {
            th {
                border-bottom: 1px solid $color-rabbit-fur;
                padding: 0 20px $spacing-xs 0;

                &.grid {
                    &__column {
                        &--alignment {
                            &-center {
                                text-align: center;
                            }

                            &-left {
                                text-align: left;
                            }

                            &-right {
                                text-align: right;
                            }
                        }

                        &--overflow {
                            &-ellipsis {
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
        tbody:first-child tr:first-child { // if there's no heading row, remove top padding from top row
            td,
            th {
                padding-top: 0;

            }
            .table-divider {
                top: 0;
            }
        }
        tbody tr:last-child {
            td {
                padding-bottom: 9px;
                border-bottom: none;
            }

            th {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

table.grid td.grid__cell {
    position: relative;

    &--top {
        vertical-align: top;
    }
    &--middle {
        vertical-align: middle;
    }
    &--bottom {
        vertical-align: bottom;
    }

    &--alignment {
        &-center {
            text-align: center;
        }

        &-left {
            text-align: left;
        }

        &-right {
            text-align: right;
        }
    }
}

.grid {
    &__cell {
        &--alignment {
            &-center {
                text-align: center;
            }

            &-left {
                text-align: left;
            }

            &-right {
                text-align: right;
            }
        }
    }

    &__cell-content {
        display: inline-block;
    }

    &__cell-content-wrapper {
        &--overflow-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
        .collapsible-link .icon-edit {
            // this CSS block is for the licensing template
            // if you remove it, make sure the edit icons still look good on that template
            padding-right: 25px;
            color: $color-accent-neutral-10;
            &:hover {
                color: $color-active-blue;
            }
        }
    }

    &__cell-hint {
        color: $color-accent-neutral-10;
        width: 100%;
    }

    &__cell-icon {
        display: inline-block;
    }

    &__column-content {
        &--overflow {
            &-ellipsis {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

// DEPRECATED OR SITE-SPECIFIC STYLES ----------------------------------------------------------------------------------
table {
    &.basic {
        width: 100%;
        &.fixed {
            table-layout: fixed;
        }
        th {
            color: $color-elephant;
            text-transform: uppercase;
        }
        th,
        td {
            text-align: left;
            font-size: 14px;
            padding: 0 12px 15px 0;

            &:last-of-type {
                padding-left: 0;
            }
            &.center {
                text-align: center;
            }
        }
        label {
            vertical-align: middle;

            &.input-checkbox {
                &.no-label {
                    padding: 0;
                }
            }
            &.input-text {
                &.no-label {
                    margin-right: 10px;
                }
                &.row-item {
                    margin-right: 0;
                }
            }
        }
        thead {
            border-bottom: 1px solid $color-rabbit-fur;
        }
        tbody {
            tr {
                border-top: 1px dotted $color-rabbit-fur;
                &:first-child {
                    border-top: none;
                }
                td {
                    .input-width-xsmall,
                    .input-width-small,
                    .input-width-medium,
                    .input-width-large,
                    .input-width-xlarge,
                    .input-width-full {
                        margin-bottom: 0;
                    }
                    .icon-delete {
                        position: inherit;
                    }
                }
            }
        }
        .button {
            &.inline {
                vertical-align: middle;
            }
        }
    }
}

.table-title {
    font-size: 18px;
    margin: 30px 0 10px;
    color: $color-slate;
}

a.add-table-row {
    position: relative;
    display: inline-block;
    padding-left: 15px;
    font-size: 14px;

    &:before {
        content: $icon-plus;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
    }
}

.table-divider {
    top: 10px;
    bottom: 10px;
    position: absolute;
    border-left: solid 1px $color-rhino;
    right: 50%;
}
