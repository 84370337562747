.legend {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$spacing-md;

    &--left {
        justify-content: flex-start;
    }

    &--center {
        justify-content: center;
    }

    &--right {
        justify-content: flex-end;
    }

    &__item {
        display: inline-block;
        margin-bottom: $spacing-md;

        &:not(:last-of-type) {
            margin-right: $spacing-lg;
        }

        &-info {
            display: flex;
            justify-content: center;
            margin-bottom: $spacing-xs;
        }

        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }

        &-badge {
            width: 12px;
            height: 12px;
            display: inline-block;
            border-radius: 3px;
            margin-right: $spacing-xs;
        }

        &-label {
            font-size: 12px;
            line-height: 12px;
            color: $color-neutral-10;
            font-weight: 400;
            display: inline-block;
            text-transform: uppercase;
            text-overflow: ellipsis;
            max-width: calc(20vw - 17px);
            overflow: hidden;
        }

        &-value {
            font-size: 22px;
            color: $color-accent-neutral-10;
            font-weight: 600;
            text-overflow: ellipsis;
            max-width: 20vw;
            overflow: hidden;

            &--sm {
                font-size: 16px;
            }
        }
    }
}
