
.code-view {
    @import './hljs.scss';
    padding: $spacing-md;
    max-height: 400px;
    overflow: scroll;
    color: $color-midnight;
    background-color: rgba($color-input-fill, 0.25);
    border: 1px solid $color-neutral-70;
    pre {
        background-color: rgba($color-input-fill, 0.25);
    }
}

.code-view__font {
    font-family: 'Consolas', 'Monaco', 'Andale Mono', monospace;
    font-size: 14px;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
}


