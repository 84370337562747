@mixin chip($color, $bg) {
    background-color: $bg;
    border-color: $bg;
    color: $color;
}

@mixin chipOutline($color) {
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    border-color: $color;
    color: $color;
}

.chip-component {
    @include break-word;
    border-radius: 3px;
    color: $color-neutral-20;
    display: inline-block;
    padding: $spacing-xs $spacing-sm;
    border-width: 0;

    /* keep rule for condensed before any variant with custom color; see UIP-3495 for details */
    &--condensed {
        display: inline-block;
        margin: 0;
        padding: 4px 5px 1px;
        font-size: 13px;
        line-height: 1em;
        text-transform: uppercase;
        color: $color-white;
        background-color: $color-elephant;
        border-radius: 3px;
    }

    &--color-cyan {
        @include chip($color-neutral-20, $color-charting-cyan);

        &.chip-component--outline {
            @include chipOutline($color-charting-cyan);
        }
    }

    &--color-faint-grey {
        @include chip($color-neutral-20, $color-strong-neutral-90);

        &.chip-component--outline {
            @include chipOutline($color-strong-neutral-90);
        }
    }

    &--color-light-grey {
        @include chip($color-neutral-20, $color-neutral-90);

        &.chip-component--outline {
            @include chipOutline($color-neutral-90);
        }
    }

    &--color-dark-grey {
        @include chip($color-white, $color-elephant);

        &.chip-component--outline {
            @include chipOutline($color-elephant);
        }
    }

    &--color-white {
        @include chip($color-neutral-20, $color-white);

        &.chip-component--outline {
            @include chipOutline($color-white);
        }
    }

    &--color-red {
        @include chip($color-white, $color-critical-red);

        &.chip-component--outline {
            @include chipOutline($color-critical-red);
        }
    }

    &--color-dark-blue {
        @include chip ($color-white, $color-midnight);

        &.chip-component--outline {
            @include chipOutline($color-midnight);
        }
    }

    &--full-width {
        display: block;
    }
}
