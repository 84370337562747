
.settings-sections {
    .sections-heading {
        position: relative;
        font-size: 18px;
        text-transform: uppercase;
        @include font-pn-regular;
        border-bottom: 2px solid $color-dolphin;
        padding: 0 0 5px;
        margin: 0 0 5px;

        .heading-link {
            font-size: 14px;
            text-transform: none;
            position: absolute;
            right: 0;
        }
    }
    &.multiple {
        .section {
            padding-left: 15px;
        }
    }
    .section {
        position: relative;
        padding: 15px 0 20px;
        border-bottom: 1px solid $color-cinder-block;
        overflow: visible;

        transition: max-height ease-out 0.6s;

        &:last-of-type {
            border-bottom: none;
        }
        &.active {
            .input-integer {
                .icon-dropdown-arrow {
                    transition: none;
                    transform: none;
                }
            }
            .icon-dropdown-arrow {
                transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
                transform: rotate(180deg);
            }
        }
    }
    .section-title {
        text-transform: uppercase;
        font-size: 15px;
        color: $color-slate;
        @include font-pn-regular;
        position: relative;

        .section-title-text {
            cursor: pointer;
        }
        &.sub-title {
            margin-top: -11px;
        }
        &.listing {
            margin-left: -25px;
        }
        &.link {
            color: $color-active-blue;
            text-transform: none;
            margin-bottom: 25px;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    .section-sub-title {
        color: $color-rhino;
        font-size: 12px;
        @include font-pn-light;
        text-transform: none;
        padding: 0 0 0 25px;
    }
    .show-section,
    .show-condition {
        cursor: pointer;
        font-size: 12px;
        height: 12px;
        margin: 0 5px 0 0;
        display: inline-block;
    }
    .section-container,
    .condition {
        padding-left: 27px;
        margin: 25px 0 0;
        .menu {
            height: auto;
            .options {
                a {
                    &:hover {
                        &:before {
                            content: '';
                        }
                    }
                }
            }
        }
        &.sub-attribute {
            .delete-attribute {
                position: absolute;
                right: 20px;
                .button.inline.remove {
                    width: 22px;
                }
            }
        }
    }
    .expanded {
        max-height: 600px;
        .show-section {
            color: $color-elephant;
            display: inline-block;

            transition: top ease-out 0.4s;
            transform: rotate(180deg);
        }
    }
    &.device-settings {
        .input-radio {
            span {
                padding-right: 0;
            }
        }
    }
    &.policy-settings {
        .section {
            border-bottom: none;
        }
    }
}

.settings-table {
    border: 1px solid $color-rabbit-fur;
    display: inline-block;
    clear: both;

    > div {
        display: table-row;
        text-align: center;

        > div {
            display: table-cell;
            padding: 10px;
            border-bottom: 1px dotted $color-rabbit-fur;
            min-width: 100px;
        }
        &:last-of-type {
            > div {
                border-bottom: none;
            }
        }
        .icon-check {
            font-size: 12px;
            color: $color-midnight;
        }

        &.headings {
            @include font-pn-regular;

            > div {
                display: table-cell;
                border-bottom: 1px solid $color-rabbit-fur;
            }
        }
    }
    &.row-labels {
        > div {
            > div {
                &:first-of-type {
                    text-align: left;
                    @include font-pn-regular;
                    text-transform: uppercase;
                    color: $color-elephant;
                    &.value {
                        text-transform: none;
                        color: $color-midnight;
                    }
                }
            }
        }
    }
}

.settings-attribute-elements {
    .settings-attribute-item {
        position: relative;
        overflow: hidden;
        max-height: 38px;
        border-bottom: 1px solid #ddd;
        margin: 20px 0 20px 15px;
        padding: 0 15px;

        transition: all ease-out 0.5s;

        .settings-attribute-title {
            margin: 0 0 20px;
            padding: 3px 0 0 20px;
            display: block;
            text-transform: uppercase;
            color: $color-elephant;
            font-size: 14px;
            line-height: 1em;

            .edit-link {
                display: inline-block;
                margin: 0 0 0 20px;
                text-transform: capitalize;
            }
        }
        &.open {
            max-height: 420px;

            .settings-attribute-title {
                display: none;
            }
            .close-link {
                opacity: 1;
            }
        }
        &:last-of-type {
            border-bottom: none;
        }
        .grip {
            position: absolute;
            top: 2px;
            left: 15px;

            @include grab-cursor;

            &:before {
                content: $icon-grip;
                text-transform: none;
                font-size: 18px;
                font-family: 'iconfont';
                color: $color-stone;
            }
        }
        .close-link {
            position: absolute;
            right: 15px;
            bottom: 25px;

            opacity: 0;
            transition: all ease-out 0.5s;
        }
    }
}

// ATTRIBUTE PREVIEW
.attribute-preview {
    background-color: $color-timberwolf;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .section {
        padding: 0;
        border-bottom: none;

        .section-title {
            color: $color-midnight;
            padding: 10px;
            display: block;
            text-transform: uppercase;
            text-align: center;
            font-size: 14px;
            line-height: 1em;

            .icon-dropdown-arrow {
                background-image: none;
                padding-right: 10px;

                transition: none;
                transform: none;
            }
        }
        &.active {
            .section-title {
                border-bottom: 1px solid $color-stratus;
            }
            .icon-dropdown-arrow {
                &:before {
                    display: inline-block;

                    transition: (top ease-out 0.4s, opacity ease-out 0.1s 0.3s);
                    transform: rotate(180deg);
                }
            }
        }
        .section-container {
            text-align: left;
            margin: 0;
            padding: 20px 0 0 40px;

            label {
                &.input-text,
                &.input-textarea {
                    input[type=text],
                    input[type=password],
                    textarea {
                        background-color: $color-white;
                    }
                }
                &.input-checkbox {
                    .icon {
                        background-color: $color-white;
                        &:before {
                            border-color: $color-rabbit-fur;
                        }
                    }
                }
                &.input-radio {
                    .circle {
                        border-color: $color-rabbit-fur;
                    }
                }
            }
            .row-title {
                text-transform: uppercase;
                color: $color-midnight;
                margin: 5px 0 10px;
            }
            .inactive {
                opacity: 0.6;
            }
        }
    }
}

// CO-BRANDING, CID SETTINGS

.branding,
.directory-settings {
    label {
        cursor: default;
        line-height: 22px;

        &.input-checkbox,
        &.input-radio {
            line-height: 20px;
            cursor: pointer;

            span {
                vertical-align: top;
                padding-right: 0;
            }
        }
        .reset {
            text-transform: none;
            font-weight: 300;
        }
    }
    .title {
        color: $color-text;
        text-transform: uppercase;
        margin: 15px 0 10px;
        font-size: 14px;
        line-height: 1em;

        .inline {
            margin-left: 10px;
        }
        span {
            @include font-pn-regular;
            font-size: 13px;
        }
        &.section {
            color: $color-rhino;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 10px;
        }
    }
    &.data-group {
        margin: 0 0 30px;
        display: none;

        &.show {
            display: block;
        }
        .image-upload {
            margin-bottom: 20px;
            .image-types {
                top: 55px;
            }
        }
        .reset {
            padding-left: 10px;
            font-size: 14px;
            text-transform: none;
        }
        .data-cols {
            .col-1-3 {
                width: 33%;
                float: left;
            }
            .col-2-3 {
                width: 33%;
                float: left;
            }
            .col-3-3 {
                width: 33%;
                float: left;
            }
            .heading {
                width: 75%;
            }
        }

        // SUB GROUPS
        .sub-group {
            margin-top: 10px;
            .heading {
                margin: 10px 0;
                padding: 10px 0;
                padding-bottom: 7px;
                border-bottom: 1px solid $color-input-border;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                color: $color-text;
            }
        }
        .section {
            margin: 20px 0 0 20px;
        }

        // TAB GROUPS
        .tab-group {
            display: none;
            min-height: 500px;
            border: 1px solid $color-input-border;
            background-color: $color-stone;
            position: relative;
            padding: 0;

            &.show {
                display: block;
                overflow: auto;
            }
            .col-1 {
                float: left;
                width: 44%;
                min-height: 500px;
                padding: 20px;
                background-color: $color-white;

                .title {
                    span {
                        color: $color-text;
                        font-size: 14px;
                    }
                }
                &.logos {
                    .input-row {
                        margin-top: 25px;
                    }
                }
                .corporate {
                    height: 90px;
                }
            }
            .col-2 {
                float: right;
                width: 46%;
                padding: 20px;

                @mixin text-area-as-div {
                    background: transparent;
                    border: none;
                    outline: none;
                    resize: none !important;
                    overflow: hidden;
                    padding-top: 0;
                    color: $color-text;
                }
                .preview {
                    font-size: 14px;
                    position: absolute;
                    left: 500px;
                }
                .details-target.inline.button {
                    background-color: transparent;
                    margin-right: 0;
                }

                // MODAL OVERRIDE MIXINS
                $modal-width: 320px;
                $modal-height: 574px;
                $modal-wide-width: 600px;
                $modal-wide-height: 545px;

                @mixin width-height-margin {
                    width: $modal-width;
                    height: $modal-height;
                    margin: 0;
                }

                @mixin modal-background-size {
                    background-size: $modal-width $modal-height;
                }

                @mixin full-modal-body {
                    margin: 0;
                    padding: 0;
                    top: 0;
                    background-color: $color-white;
                    overflow: hidden;
                }

                @mixin close {
                    color: transparent;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: $modal-width;
                    height: $modal-height;
                    z-index: $zindex__elevated;
                    text-decoration: none;
                }

                // override modal settings for mobile previews
                #full-preview-modal {
                    width: $modal-width;
                    height: $modal-height;
                    &.show {
                        top: 15%;
                    }
                    .modal-body {
                        @include full-modal-body;
                        .close-modal {
                            @include close;
                        }
                        .tab-group {
                            border: none;
                            &.show {
                                overflow: hidden;
                            }
                        }
                    }
                }
                #full-preview-wide-modal {
                    width: $modal-wide-width;
                    height: $modal-wide-height;
                    &.show {
                        top: 15%;
                    }
                    .modal-body {
                        @include full-modal-body;
                        .close-modal {
                            @include close;
                            width: $modal-wide-width;
                            height: $modal-wide-height;
                        }
                        .tab-group {
                            border: none;
                            &.show {
                                overflow: hidden;
                            }
                        }
                        .full {
                            width: $modal-wide-width;
                            height: $modal-wide-height;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .info {
        margin: 0 0 10px;
        display: inline-block;

        label {
            display: inline-block;
            width: 150px;
            margin: 0 0 10px;
        }
        span {
            display: inline-block;
            width: 290px;
            max-width: 290px;
            float: right;
            text-align: left;
        }
        .success {
            color: $color-success;
        }
        span {
            display: inline-block;
        }
        &:last-of-type {
            margin: 0;
        }
        a {
            text-transform: uppercase;
        }
        &.last-of-type label {
            margin: 0;
        }
    }
    .option {
        display: none;
        width: 100%;
        &.active {
            display: inline-block;
        }
        .input-toggle {
            position: relative;
            top: 5px;
            left: 0;
            clear: both;
        }
    }
}
