
@-webkit-keyframes spinner-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin spinnerOuterSize($size, $border-width) {
    width: $size;
    height: $size;
    border-width: $border-width;
    border-radius: $size;
}

@mixin spinnerRotatorSize($size, $border-width, $color) {
    top: -#{$border-width - 1};
    left: -#{$border-width - 1};
    width: $size - 2;
    height: $size - 2;
    border-width: $border-width - 2;
    border-radius: $size;
    border-top-color: $color;
}

@mixin spinnerPageSize($border-width, $color) {
    &:before,
    &:after {
        position: absolute;
        height: $border-width - 2;
        width: $border-width - 2;
        border-radius: $border-width - 2;
        background-color: $color;
    }
}

.loader {
    $size: 25px;
    $border-width: 4px;
    $color: $color-neutral-50;

    position: relative;
    display: inline-block;
    box-sizing: border-box;
    color: transparent;
    font-size: 1px;
    vertical-align: bottom;
    border-style: solid;
    border-color: $color-neutral-90;

    @include spinnerOuterSize($size, $border-width);

    &__rotator {
        position: absolute;
        box-sizing: border-box;
        border-style: solid;

        animation: spinner-rotation 0.8s infinite linear;
        @include spinnerRotatorSize($size, $border-width, $color);
    }

    &.large {
        $size: 40px;
        $border-width: 6px;
        $color: $color-neutral-50;

        @include spinnerOuterSize($size, $border-width);

        .loader__rotator {
            @include spinnerRotatorSize($size, $border-width, $color);
        }
    }
}

.page-loader {
    $size: 75px;
    $border-width: 9px;
    $color: $color-active-blue;

    @include spinnerOuterSize($size, $border-width);

    .loader__rotator {
        @include spinnerRotatorSize($size, $border-width, $color);
        @include spinnerPageSize($border-width, $color);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 2px;
        }
        &:before {
            left: 3px;
        }
        &:after {
            left: 49px;
        }
    }

    &__box {
        text-align: center;
    }

    &__text {
        display: inline-block;
        margin-top: 15px;
        padding: $spacing-sm $spacing-lg;
        border-radius: 40px;
        line-height: 1.2em;
        font-size: 20px;
        text-align: center;
        color: $color-strong-neutral-10;
        background-color: rgba($color-white, 0.6);
        max-width: 310px;
    }

    &--small {
        $size: 50px;
        $border-width: 7px;

        @include spinnerOuterSize($size, $border-width);

        .loader__rotator {
            @include spinnerRotatorSize($size, $border-width, $color);
            @include spinnerPageSize($border-width, $color);

            &:before,
            &:after {
                top: 1px;
            }
            &:before {
                left: 2px;
            }
            &:after {
                left: 31px;
            }
        }
    }
}


.icon-ellipsis {
    $color: $color-accent-neutral-10;
    $size: 8px;
    $spacing: 6px;
    $timing: 1s ease-out infinite;

    position: relative;
    display: inline-block;
    width: 3 * $size + 2 * $spacing;
    height: $size;

    > span,
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        height: $size;
        width: $size;
        background-color: $color;
        opacity: 0;

        border-radius: ceil($size/2);
    }

    @include keyframes(pulse-1) {
        0% {
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
        60% {
            opacity: 0;
        }
    }

    @include keyframes(pulse-2) {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        80% {
            opacity: 0;
        }
    }

    @include keyframes(pulse-3) {
        0% {
            opacity: 0;
        }
        40% {
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    span {
        left: 0;

        animation: pulse-1 $timing;
    }
    &:before {
        left: $size + $spacing;

        animation: pulse-2 $timing;
    }
    &:after {
        left: 2*($size + $spacing);

        animation: pulse-3 $timing;
    }

    &.ie9 {
        height: 7px;
        width: 36px;
        content: ('');
        background-image: url('#{$img-path}ellipsis-dark.gif');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 36px 7px;
    }
}

// for hardcoded instances of the previous spinner markup
.spinner {
    $size: 25px;

    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: $size;
    height: $size;

    border-color: $color-neutral-50 $color-neutral-90 $color-neutral-90;
    border-width: 4px;
    border-style: solid;
    border-radius: $size;

    color: transparent;
    font-size: 1px;
    vertical-align: bottom;

    animation: spinner-rotation 0.8s infinite linear;

    &.large {
        $size: 40px;

        width: $size;
        height: $size;
        border-radius: $size;
        border-width: 6px;
    }
}
