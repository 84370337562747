.input-file {
    @include font-pn-regular;
    @include break-word;
    border: none;
    padding: 0;
    margin: 0;
    height: auto;
    text-align: left;
    font-size: 14px;
    line-height: 1em;
    background-color: transparent;
    display: inline-block;

    &.input-file--selected {
        background-color: transparent;
        &-full-width {
            width: 100%;
            .input-file__field-set.unfocused {
                margin-bottom: 0;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
    }


    &__field-set {
        display: inline-flex;
        width: 100%;
        &--focused {
            padding: $spacing-lg;
            border-radius: 3px;
            margin: 0;
        }
        &.unfocused {
            padding: 0;
        }
    }

    &__selected-content {
        display: inline-flex;
        align-items: center;
        width: 100%;
    }
    &__info {
        display: flex;
    }
    &__remove-btn,
    &__node-btn {
        margin-left: auto;
    }
    &__file {
        display: flex;
        flex-direction: column;
        margin: 0 $spacing-lg 0 0;
    }
    &__file-icon {
        margin-top: -4px;
        color: $color-strong-neutral-10;
    }
    &__file-name {
        padding: 0 $spacing-xs 0 0;
        @include text-value;
    }

    &__file-data {
        margin: 7px 0 0;
        padding: 7px $spacing-xs 0 0;
        border-top: 1px solid $color-strong-neutral-70;
        color: $color-strong-neutral-50;
    }
    &__select-btn--required {
        -webkit-box-shadow: 0 0 0 3px rgba(242, 187, 26, 1);
        -moz-box-shadow: 0 0 0 3px rgba(242, 187, 26, 1);
        box-shadow: 0 0 0 3px rgba(242, 187, 26, 1);
    }

}
