.translation-picker { // added specificity to all of these to override .collapsible-link styles
    & &__link,
    & &__link:hover {
        @include text-value;
        text-decoration: none;
    }
    & &__link:after {
        color: $color-value-text !important;
    }
    & &__link.right:after {
        right: -18px;
    }
}

.translation-picker__label {
    $width: 21px;
    font-size: 13px;
    text-align: center;
    width: 15px;
    padding-left: 3px;
    height: 1em;
    overflow: hidden;
    &:before {
        font-family: $icon-font;
        content: $icon-chat-square;
        font-size: $width;
        position: absolute;
        left: 0;
        margin-top: 1px; //center the text better in the bubble
    }
}
