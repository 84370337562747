// NEUTRAL COLORS
$color-black: #000;
$color-white: #fff;

$color-neutral-10: #575f67;
$color-neutral-20: #686f77;
$color-neutral-30: #798087;
$color-neutral-40: #8b9197;
$color-neutral-50: #9da2a8;
$color-neutral-60: #afb4b8;
$color-neutral-70: #c2c6ca;
$color-neutral-80: #d5d8db;
$color-neutral-90: #e8ebed;
$color-neutral-95: #f8f8f8;

$color-strong-neutral-10: #3d454d;
$color-strong-neutral-20: #515960;
$color-strong-neutral-30: #676e75;
$color-strong-neutral-40: #7d8389;
$color-strong-neutral-50: #93999f;
$color-strong-neutral-60: #abb0b4;
$color-strong-neutral-70: #c3c7cb;
$color-strong-neutral-80: #dbdfe2;
$color-strong-neutral-90: #f4f7f9;

$color-accent-neutral-10: #4b637c;
$color-accent-neutral-20: #5f748b;
$color-accent-neutral-30: #738599;
$color-accent-neutral-40: #8797a8;
$color-accent-neutral-50: #9ba9b8;
$color-accent-neutral-60: #b0bcc7;
$color-accent-neutral-70: #c5cfd7;
$color-accent-neutral-80: #dae2e7;
$color-accent-neutral-90: #f0f6f7;

// SEMANTIC COLORS
$color-active-blue: #2996cc;
$color-active-blue-highlighted: tint($color-active-blue, 10%);
$color-critical-red: #a31300;
$color-critical-red-highlighted: tint($color-critical-red, 10%);
$color-success-green: #4aba78;
$color-success-green-highlighted: tint($color-success-green, 10%);
$color-warning-yellow: #eeb91c;

// CHARTING COLORS
$color-charting-indigo: #193967;
$color-charting-blue: #4c8dca;
$color-charting-magenta: #e12f51;
$color-charting-orange: #ffa500;
$color-charting-green: #447100;
$color-charting-cyan: #77e6eb;
$color-charting-grape: #6a3f89;

// LEGACY COLOR NAMES
$color-active: $color-active-blue;
$color-blue-grey: $color-accent-neutral-50;
$color-chilly: $color-accent-neutral-80;
$color-elephant: $color-strong-neutral-50;
$color-elderly: $color-strong-neutral-90;
$color-midnight: $color-accent-neutral-10;
$color-rabbit-fur: $color-neutral-70;
$color-required-yellow: $color-warning-yellow;
$color-rhino: $color-neutral-50;
$color-slate: $color-strong-neutral-10;
$color-sonic: $color-accent-neutral-90;
$color-stratus: $color-neutral-60;
$color-timberwolf: $color-neutral-90;
$color-walrus: $color-neutral-10;
$color-warning-icon-yellow: $color-warning-yellow;

// DEPRECATED COLORS
$color-active-blue-option: #0069d9;
$color-active-blue-inactive: #a9d5eb;
$color-cinder-block: #e4e5e5;
$color-dolphin: #d8dbdc;
$color-grass: #3cb66e;
$color-manatee: #b1b5b8;
$color-oitnb: #f89201;
$color-stone: #c2c3c4;
$color-success-green-inactive: #b1e2c5;
$color-verde: #76cc99;
$color-warning-text: #96702c;
$color-success: $color-success-green;
$color-success-highlighted: $color-success-green-highlighted;

// REUSABLE SEMANTIC COLOR VARS (use color vars for all other colors or when semantic meaning does not apply)
$color-text: $color-elephant;
$color-label-text: $color-elephant;
$color-value-text: $color-midnight;
$color-active: $color-active-blue;
$color-input-fill: $color-sonic;
$color-input-border: $color-rabbit-fur;
$color-input-text: $color-midnight;
$color-input-placeholder: $color-accent-neutral-40;
$color-input-required: $color-required-yellow;
$color-input-error: $color-critical-red;
$color-link-text: $color-active-blue;
