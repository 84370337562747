
.collapsible-section {
    $line-indent: 17px;

    position: relative;
    border-top: 1px dotted $color-rabbit-fur;
    border-bottom: 1px dotted $color-rabbit-fur;

    //When collapsible-section follows another element in its container apply this style.
    * + & {
        margin-top: -1px;
    }

    &--no-underline {
        border: none;
    }

    > .collapsible-section-title {
        position: relative;
        display: inline-block;
        padding: 25px 0 22px;
        font-size: 15px;
        text-transform: uppercase;
        color: $color-slate;
        line-height: 1em;
        cursor: pointer;
        @include font-pn-regular;

        &--circled {
            &.collapsible-section-title {
                margin-left: 28px;
            }

            &:before {
                left: -23px !important;
            }

            &:after {
                position: absolute;
                top: 11px;
                content: '';
                border: 1px solid $color-accent-neutral-50;
                height: 20px;
                width: 20px;
                left: -30px;
                border-radius: 50%;
            }
        }
        &--full-width {
            width: 100%;
        }
        &--node {
            font-size: 14px;
            text-transform: none;
            margin: 0 0 0 5px;
            color: $color-midnight;
            cursor: pointer;
            .content-columns {
                margin-top: $spacing-md;
                margin-bottom: $spacing-lg;
            }
            label {
                cursor: pointer;
            }
        }

        &--accessories {
            text-transform: none;
        }
    }
    > .collapsible-section-title-value {
        font-size: 14px;
        text-transform: none;
        margin: 0 0 0 5px;
        color: $color-midnight;
    }

    > .collapsible-section-content {
        text-transform: none;
        display: none;
        visibility: hidden;
        position: relative;
        margin: 0 0 $spacing-lg $spacing-lg;

        transition: all 0.3s;

        &--no-margin {
            margin-left: 0;
        }
    }
    .row-accessories {
        > * {
            margin-left: 10px;
        }
    }
    &__row-accessories {
        float: unset;
    }
    &.disable-expand {
        .left:before,
        .right:after {
            content: '';
        }
        .collapsible-section-title {
            pointer-events: none;
            cursor: default;
        }
    }
    &.open {
        > .collapsible-section-content {
            display: block;
            visibility: visible;

            animation: 0.2s fade-in;
        }
    }
    &.has-title-value {
        > .collapsible-section-title {
            &:after {
                content: ':';
            }
        }
    }

    &.draggable {
        &:before {
            content: 'G';
            font-family: $icon-font;
            position: absolute;
            left: 5px;
            top: 33px;
            margin-top: -9px;
            color: $color-stone;
        }
        .collapsible-section-title {
            padding-left: 21px;
        }
    }

    &.condensed {
        border-bottom: none;

        &:first-child {
            border-top: none;
            margin-top: -15px;
        }
        .collapsible-section-title {
            padding: 15px 0 13px;
        }

        .collapsible-section__right-content {
            height: 43px;
        }
    }

    .collapsible-section-title {
        margin: 0 0 0 24px;

        &:hover {
            color: $color-slate;
            text-decoration: none;

            &.left:before {
                color: $color-slate;
            }
        }
        &.left:before {
            top: auto;
            left: -19px;
            color: $color-midnight;
        }
        &.collapsible-section-title--node {
            padding: 0;

            &.left:before {
                top: 34px;
            }
        }
        &.none {
            &:after {
                content: none;
            }
        }
    }
}

.collapsible-form {
    .collapsible-section {
        .collapsible-section-title {
            color: $color-elephant;
            font-size: 13px;
        }
    }
}

.collapsible-section__right-content {
    float: right;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    width: 50%;
}

.collapsible-section__details-text {
    padding-left: 12px;
    color: $color-strong-neutral-50;

    transition: all 0.3s;
}
