
//Unsure if the dock is pulling the css file. They have similarities but,
// best for now to keep the css file.
.introOverlay {
    background: rgba($color-black, 0.6);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: absolute;
    z-index: $zindex__elevated;
    text-align: center;
    left: 0;
    top: 0;

    > svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
    }
    div.modal {
        display: inline-block;
        padding: 20px 20px 77px;
        margin-top: -100px;
        margin-left: -200px;
        top: 50%;
        left: 50%;
        background-color: $color-black;
        width: 400px;
        opacity: 1;
        color: $color-white;

        border-radius: 5px;

        .title {
            font-size: 36px;
            line-height: 1em;
            margin: 0 0 25px;
            @include font-pn-regular;
        }
        .description {
            text-align: left;
            line-height: 1.6em;
            font-size: 18px;
            @include font-pn-light;
        }
        .page {
            font-weight: 100;
            font-size: 20px;
            padding: 30px 0;
            color: transparentize($color-white, 0.5);
        }
        .controls {
            position: absolute;
            bottom: 0;
            left: 5px;
            right: 5px;
            border-top: 1px solid transparentize($color-white, 0.75);
            padding: 20px 0;
            text-align: right;

        }
        button[type=button] {
            border: none;
            font-size: 16px;
            line-height: 1em;
            padding: 10px 20px;
            color: $color-white;
            background-color: $color-active-blue;
            font-weight: 100;

            border-radius: 2px;

            &.prev,
            &.dismiss {
                color: $color-active-blue;
                background-color: transparent;
                font-weight: 500;
            }
            &.dismiss {
                position: absolute;
                bottom: 10px;
                left: 10px;
                padding: 0;
            }
            &[disabled] {
                &:hover {
                    color: $color-white;
                }
            }
        }
    }
    path {
        stroke: $color-active-blue;
        fill: none;

        &.line {
            stroke-width: 2px;
        }
        &.marker {
            stroke-width: 0.04em;
        }
    }
    > .spotlight {
        background: $color-white;
        border-radius: 15px;
        position: absolute;
        text-align: left;
        overflow: hidden;
    }
}

html body .introOverlay > .spotlight > * {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.icons {
    .inline-icon {
        display: inline-block;
        width: 35px;
        text-align: center;
        color: $color-walrus;
    }
}
