.stretch-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    position: relative;

    > * {
        flex-shrink: 0;
    }

    &--scrollable {
        min-height: 400px;

        > *,
        > div {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}
