// INCLUDE PARTIALS ----------------------------------------------------------------------------------------------------

@import 'code-view';
@import 'forms/dropdown-button';
@import 'forms/_keyword-search';
@import 'forms/_translation-picker';
@import 'forms/_translation-picker';
@import 'list/column-selector.scss';
@import 'forms/_file-drop';
@import 'forms/_file-input';
@import 'forms/_toggle';
@import 'rows/_row-builder';

// INPUT GLOBAL VARS ---------------------------------------------------------------------------------------------------

$default-input-transition: all 0.3s;
$default-input-height: 40px;
$default-input-font-size: 14px;
$borderSize: 1px !default;
$default-input-border-radius: 2px;

@mixin input-icon {
    font-family: $icon-font;
    color: $color-accent-neutral-10;
    font-size: 16px;
    position: absolute;
    left: $spacing-sm;
    top: 50%;
    transform: translateY(-50%);
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/

.button,
.input-checkbox > input,
.input-radio > input,
.anchor,
.rocker-button__button,
.value-input__input,
.option-list,
.iconComponent,
.iconComponent--clickable,
.integer-down,
.integer-up,
.view-toggle,
.delete-btn,
.expand-btn,
.edit-btn,
.view-btn,
.button-menu__button,
.input-image-thumb,
.image-icon,
.focusable-element,
.tile-button {
    &:focus {
        outline-width: 0;
        outline-style: solid;
    }
}

.ui-library-focus-visible {
    .button,
    .input-checkbox > input,
    .input-radio > input,
    .anchor,
    .rocker-button__button,
    .value-input__input,
    .option-list,
    .iconComponent,
    .iconComponent--clickable,
    .integer-down,
    .integer-up,
    .view-toggle,
    .delete-btn,
    .expand-btn,
    .edit-btn,
    .view-btn,
    .button-menu__button,
    .input-image-thumb,
    .image-icon,
    .focusable-element,
    .tile-button {
        &:focus {
            outline-width: 5px;
            outline-color: rgba($color-active, 0.5);
        }
    }
}

// CORE INPUT STYLES ---------------------------------------------------------------------------------------------------

// General browser-specific overrides (not needed specifically for each input type below - reduces bloat having it here)
input,
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-clear {
        display: none;
    }
    &::-ms-expand {
        display: none;
    }
    &::-ms-value {
        // Not sure what this rule was for. It makes problems for overriding input styles in IE. -jfal
        // color: $color-midnight;
        background: none;
    }
}


// Tag styling
input[type=text],
input[type=password],
input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=range],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week],
textarea {
    @include input-style;

    display: block;
    height: $default-input-height;
    box-sizing: border-box;
    outline-width: 0;
    @include font-pn-regular;

    border-radius: $default-input-border-radius;
    transition: border-color 0.3s;

    &:visited {
        border: 1px solid $color-input-fill;
    }
    &:hover {
        border: 1px solid $color-input-border;
    }
    &:focus {
        border-color: $color-active-blue;
    }
}

textarea {
    overflow: auto;

    &[readonly],
    &[disabled] {
        resize: none !important;
    }
}

.label-text {
    @include text-label;
}

.form-label {
    max-width: 100%;
}

.form-label__explanation {
    @include text-body;

    margin-top: $spacing-xs;
    text-transform: none;
}

.form-label--no-spacing {
    &.input-checkbox.inline, // hacking specificity
    &.input-checkbox.stacked, // hacking specificity
    &.input-width-medium,
    &.input-width-small,
    & {
        margin: 0;
    }
}

label {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 0 20px 0 0;
    text-transform: uppercase;
    vertical-align: top;
    line-height: 1em;
    cursor: default;

    @include text-label;

    .modifier_strip-input-margins & {
        // this is for layouts that don't play well with the built-in margins inputs have
        // for instance, when an input goes in a column, which has its own padding and margin
        margin-right: 0;
    }

    .label-text,
    .input-container {
        display: block;
        position: relative;

        transition: $default-input-transition;

        &:before {
            transition: $default-input-transition;
        }
    }


    .label-text {
        margin: 0 0 8px;
        text-align: left;

        .label-container {
            align-items: baseline;
            display: flex;
        }

        .help-tooltip {
            margin-left: 10px;
            height: 12px;
            vertical-align: baseline;
        }
        .help-tooltip.inline {
            margin-left: 5px;
            margin-top: 0;
        }
        &__description {
            text-transform: none;
            padding-top: $spacing-xs;
        }
    }

    &.input-text {
        input {
            width: 100%;
            padding: 4px 15px 3px;

            // Hide autofill button in Safari
            &::-webkit-contacts-auto-fill-button,
            &::-webkit-credentials-auto-fill-button {
                visibility: hidden;
                pointer-events: none;
                position: absolute;
                right: 0;
            }
        }
        &.search {
            .input-container {
                input {
                    padding-left: 35px;
                    padding-right: 30px;
                }
            }
            a.clear-search {
                @include input-icon();

                right: $spacing-sm;
                left: auto;

                &:before {
                    content: 'X';
                }

                &:hover {
                    text-decoration: none;
                    color: $color-active-blue;
                }
            }
            a.undo {
                top: 15px;
                line-height: 1em;

                transition: $default-input-transition;

                &:before {
                    content: 'X';
                    font-size: 13px;
                    line-height: 1em;
                }
            }
            &--query .input-container:before {
                content: 'd';
            }
        }
        &.no-label {
            input {
                margin-top: 0;
            }
        }
        &.extended {
            input {
                width: 350px;
            }
        }
        &.masking-controls {
            input[type=text],
            input[type=password] {
                padding-right: 40px;
            }
        }

        // temporary sizing classes
        &.input-width-1 {
            input {
                width: 70px;
            }
        }
        &.input-width-2 {
            input {
                width: 210px;
            }
        }
        &.input-width-3 {
            input {
                width: 492px;
            }
        }
        &--mono input {
            font-family: 'Consolas', 'Monaco', 'Andale Mono', monospace;
        }

        .password-meter {
            margin: 6px 0 0;
        }
        &--flex-icon-left {
            input {
                padding: 4px 30px 3px;
            }
        }
    }

    &.input-textarea {
        width: 100%;

        textarea {
            padding: 12px 15px;
            line-height: 1.2em;
            height: auto;
            width: 100%;

            &.input-textarea {
                &__input {
                    &--monospaced {
                        font-family: 'Consolas', 'Monaco', 'Andale Mono', monospace;
                    }
                    &--no-resize {
                        resize: none;
                    }
                }
            }
        }
        &.extended {
            textarea {
                width: 350px;
            }
        }
        &.no-label {
            textarea {
                margin-top: 0;
            }
        }
        &.textarea-height--short {
            textarea {
                height: 100px;
            }
        }

        &.textarea-height--medium {
            textarea {
                height: 250px;
            }
        }

        &.textarea-height--large {
            textarea {
                height: 400px;
            }
        }

        &.readonly {
            &.textarea-height--short,
            &.textarea-height--medium,
            &.textarea-height--large {
                textarea[readonly],
                textarea {
                    resize: both !important;
                }
            }
        }
    }

    &.input-text,
    &.input-textarea,
    &.input-multivalues {
        &.short {
            input[type=text],
            input[type=password] {
                width: 75px;
            }
        }
        &.actions {
            input[type=text],
            input[type=password],
            textarea {
                padding-right: 40px;
            }
        }
    }

    &.input-checkbox {
        $checkbox-size: 24px;
        $border-width: 3px;

        position: relative;
        display: inline-block;
        box-sizing: border-box;
        min-width: $checkbox-size;
        min-height: $checkbox-size;
        padding: 0 0 22px;
        cursor: pointer;

        .label-text {
            display: block;
            text-transform: uppercase;
        }
        input[type=checkbox] {
            position: absolute;
            margin: 0;
            bottom: 0;
            left: 0;
            width: $checkbox-size;
            height: $checkbox-size;

            &:checked + .icon:after {
                color: $color-input-text;
            }
        }
        .icon {
            position: absolute;
            left: 0;
            bottom: 0;
            display: inline-block;

            &:before {
                @include input-background;

                content: '';
                display: block;
                box-sizing: border-box;
                border: 3px solid $color-timberwolf;
                height: $checkbox-size;
                width: $checkbox-size;
                line-height: $checkbox-size;

                border-radius: $default-input-border-radius;
            }
            &:after {
                content: $icon-success;
                position: absolute;
                text-transform: none;
                color: transparent;
                font-family: $icon-font;
                font-size: 13px;
                left: 0;
                bottom: 0;
                height: $checkbox-size;
                width: $checkbox-size;
                line-height: $checkbox-size;
                text-align: center;

                transition: $default-input-transition;
            }
        }
        &.top-label {
            padding: 0;
            height: 47px;

            .icon {
                top: 23px;
            }
        }
        &.no-label {
            margin-right: 0;
        }
        &.side-label {
            .icon {
                position: relative;
                top: auto;
                left: auto;
                vertical-align: middle;
                margin: 0 5px 0 0;
            }
            .label-text {
                display: inline-block;
                vertical-align: middle;
            }
        }
        &.stacked,
        &.inline {
            margin-bottom: 5px;
            margin-right: 20px;
            padding: 0 0 0 36px;
            text-transform: none;

            &.disabled {
                .label-text {
                    color: rgba(75, 99, 124, 0.5);
                    .help-target {
                        opacity: 1;
                    }
                }
            }

            .label-text {
                @include break-word;
                display: inline-block;
                text-transform: none;
                font-size: 14px;
                color: $color-midnight;
                padding: 6px 0 0;
                margin: 0;
                line-height: 1em;
            }
        }
        &.stacked {
            margin-bottom: 10px;

            .icon {
                bottom: auto;
            }
        }
        &.disabled {
            cursor: default;
        }
        // indeterminate checkboxes are so rare that this class is only added to the checkbox
        // by other components that use it internally
        &--indeterminate {
            .icon:after {
                color: $color-input-text;
                content: $icon-minus;
                font-weight: bold;
            }
        }
    }

    &.input-radio {

        $radio-height: 24px;
        $border-width: 3px;

        position: relative;
        display: inline-block;
        box-sizing: border-box;
        line-height: $radio-height;
        min-height: $radio-height;
        min-width: $radio-height;
        font-size: 14px;
        text-transform: none;
        color: $color-midnight;
        padding: 0 0 0 35px;
        margin-right: 20px;
        cursor: pointer;

        .circle {
            @include input-background;

            position: absolute;
            left: 0;
            top: -1px;
            box-sizing: border-box;
            width: $radio-height;
            height: $radio-height;
            cursor: pointer;
            border: $border-width solid $color-timberwolf;

            border-radius: ceil($radio-height/2) + $border-width;

            &:after {
                content: '';
                position: absolute;
                left: 4px;
                top: 4px;
                width: 10px;
                height: 10px;
                background-color: transparent;

                border-radius: ceil($radio-height/2) + 1;
                transition: $default-input-transition;
            }
        }
        &.no-label {
            margin-right: 0;
            padding-left: 0;
        }
        &.stacked {
            display: block;
            margin-bottom: $spacing-sm;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .label-text {
            text-transform: none;

            @include text-value;
        }
        input[type=radio] {
            position: absolute;
            margin: 0;
            top: -1px;
            left: 0;
            height: 24px;
            width: 24px;

            &:checked + .circle:after {
                background-color: $color-midnight;
            }

            :focus {
                outline-width: 1px;
            }
        }
        &.input-text {
            .circle {
                bottom: 9px;
            }
            input[type=text] {
                display: inline-block;
                margin: 0;
            }
        }
        &.hidden {
            display: none;
        }
        .help-tooltip {
            height: 19px;
            vertical-align: bottom;
            margin: 0 0 0 7px;
        }
    }

    &.input-group {
        font-size: 14px;
        color: $color-elephant;
        text-transform: uppercase;
        margin: 0 0 20px;
    }

    &.detached {
        margin-right: 0;
        margin-bottom: 8px;
        display: block;
    }

    &.parent {
        color: $color-elephant;
        @include font-pn-semibold;
        display: block;
        font-size: 15px;
        margin: 0 0 $spacing-sm;
    }

    &.inline-save {
        .input-container {
            padding-right: 50px;
        }
        .undo {
            right: 61px;
        }
        .save {
            position: absolute;
            top: 15px;
            right: 0;
        }
    }

    &.radio-group {
        display: block;
        margin: 0 0 10px;

        .help-tooltip {
            line-height: 1em;
            height: 18px;
            width: 18px;
            vertical-align: middle;
            margin: 0 0 0 10px;
        }
    }

    a {
        &.password-show-button {
            position: absolute;
            right: 14px;

            &:hover {
                text-decoration: none;
            }
            &.icon-view,
            &.icon-view-hidden {
                top: 9px;
                font-size: 20px;
            }
        }
        &.undo {
            position: absolute;
            top: 13px;
            right: 11px;
            font-size: 0;
            color: transparent;
            width: 14px;

            &:before {
                content: $icon-undo;
                font-family: $icon-font;
                font-size: 16px;
                color: $color-active;
                text-transform: none;

                transition: $default-input-transition;
            }
        }
    }

    .value-container {
        display: block;
        margin: 6px 0 0;
        color: $color-midnight;
        text-transform: none;
        font-size: 16px;
    }
}

.input-checkbox {
    &__conditional-content {
        margin-top: $spacing-xs;
        padding-left: 36px;

        &--stacked {
            margin-top: 0;
            margin-bottom: $spacing-sm;
        }
    }
}

.content-measurer {
    position: absolute;
    display: inline-block;
    z-index: $zindex__hidden;
    visibility: hidden;
}

.flex-width {
    max-width: 100%;

    .input-container {
        input[type=text],
        input[type=password] {
            max-width: 100%;

            transition: (width 0s);
        }
    }
}

fieldset {
    margin: 0 0 25px;
    clear: both;
    min-width: 0;

    legend {
        text-transform: uppercase;

        .help-tooltip {
            margin: 0 5px 0 10px;
            height: 18px;
            width: 18px;
            vertical-align: text-bottom;
        }
        label {
            margin-right: 5px;
            margin-bottom: 0;
        }
    }
    &.focused,
    &.unfocused {
        legend {
            padding: 0 10px;
            margin: 0 0 0 -10px;
            font-size: 14px;
            color: $color-elephant;

            .input-radio,
            .input-select {
                &:last-of-type {
                    margin-right: 0;
                }
                .label-text {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.focused {
        border: 1px solid $color-dolphin;
        padding: 20px;

        border-radius: 5px;

        &.narrow {
            width: 500px;
        }
    }
    &.unfocused {
        padding: 21px;
        padding-bottom: 0;
    }
    &.conditional-fieldset {
        margin-left: -21px;
    }
    .subset {
        margin: 0 0 0 20px;
        label {
            text-transform: none;
        }
    }
}

input[type=file] {
    line-height: 1em;
}

@include placeholder-css($color-input-placeholder, normal, 0);

@mixin dropdown-caret {
    position: absolute;
    content: 'v';
    font-family: $icon-font;
    font-size: 9px;
    text-transform: none;
    right: $spacing-sm - 1px;
    top: 11px;
    line-height: 1em;
    color: $color-midnight;
    padding: 5px;
    pointer-events: none;
}

// Input types
.input-text,
.input-textarea,
.input-select,
.input-file-upload,
.input-integer,
.input-radio,
.input-checkbox,
.input-color-picker,
.input-calendar {
    margin: 0 10px 20px 0;

    &:last-child {
        margin-right: 0;
    }
}

.radio-text-input {
    margin: -12px 0 0 32px;
}

.radio-input-link {
    margin: 2px 0 0 32px;
}

.input-select {
    > span {
        line-height: 1em;
        display: block;

        .help-tooltip {
            margin-left: 10px;
        }
        &.inline {
            display: inline-block;
            vertical-align: top;
            line-height: 50px;
            padding-right: 10px;
        }
    }
    .input-container {
        height: $default-input-height;

        .wrapper {
            position: relative;
            clear: both;
            display: block;
            height: $default-input-height;
            overflow: hidden;
            cursor: pointer;
            border: 1px solid $color-input-fill;
            box-sizing: border-box;

            border-radius: $default-input-border-radius;

            &:after {
                @include dropdown-caret();
            }

            &__spacer {
                @include text-value;

                padding: 0 30px;
                height: 0;
                display: block;
                visibility: hidden;
            }
        }
    }
    &.no-label {
        .input-container {
            margin: 0;
        }
    }
    &.large {
        .input-container {
            div {
                height: 50px;
                line-height: 50px;
            }
            &:after {
                top: 0;
                line-height: 50px;
            }
        }
    }


    select {
        appearance: none;
        border: 0;
        border-radius: 0;
        color: $color-input-text;
        background-color: $color-input-fill;
        font-size: $default-input-font-size;
        line-height: 20px;
        height: $default-input-height;
        outline: none;
        cursor: pointer;
        padding: 0 40px 0 10px;
        width: 110%;
        @include font-pn-regular;
        text-transform: none;

        transition: $default-input-transition;

        // IE FIXES
        option {
            &:checked {
                background-color: $color-active-blue-option;
            }
            &:hover {
                background-color: $color-active-blue-option;
                color: $color-white;
            }
        }

        // FF FIXES
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $color-black;
        }
    }
    &.ie9 {
        .input-container {
            .wrapper {
                &:after {
                    content: '';
                }

            }
        }
        select {
            // allow input widths to override
            width: 100%;
            padding-right: 0;
            option {
                &:checked,
                &:hover {
                    background-color: $color-input-fill;
                    color: $color-input-text;
                }
            }
        }
    }
    &.select-filter {
        .input-container {
            .wrapper {
                border: 1px solid $color-rhino;
                select {
                    background: $color-white;
                }
            }
        }
    }
}

@import 'inputs/dropdown';

.input-custom-select {
    padding: $borderSize;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: auto;           // set to auto, allow different input widths to be set

    &.open {
        .select-list {
            visibility: visible;
            z-index: $zindex__menu;
            top: -1px;

            animation: fade-in 0.2s;

        }
        label:not(.readonly) {
            .selected-option-label {
                &:hover,
                &:focus {
                    cursor: text;
                }
            }
        }

        .input-container {
            &:last-of-type {
                .wrapper {
                    &:after {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        .selected-option {
            .arrow {
                transform: rotate(-180deg);
            }
        }
    }
    &.disabled {
        .input-text {
            .input-container {
                opacity: 1;
                input {
                    cursor: default !important;
                }
            }
        }
    }
    // override the input-select behavior
    .input-container {
        height: auto;

        transition: none;
        &:before,
        &:focus {
            transition: none;
        }
        &:last-of-type {
            .wrapper {
                overflow: visible;
                border: none;
            }
        }
    }

    // override default input readonly styles
    .input-text {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;

        input[type=text] {
            width: 100%;
        }
    }
    .readonly {
        &.input-text {
            input[type=text] {
                @include input-style;
                cursor: pointer;

                &:focus {
                    border-color: $color-active-blue;
                }
            }
        }
    }
    .selected-option {
        transition: none;
        &:hover {
            transition: none;
        }
    }
    input[type=text] {
        transition: none;
        &.selected-option-label {
            padding-right: 25px;
            margin-right: -10px;
            text-overflow: ellipsis;
            border-radius: $default-input-border-radius $default-input-border-radius 0 0;
            &:hover,
            &:focus {
                cursor: pointer;
                border-color: $color-input-fill;
            }
            &--icon-padding {
                padding-left: 30px;
            }
        }
    }

    @include placeholder-css($color-midnight, normal, 0, normal); // override placeholder styles on selected option preview
    @include drop-down-styles;

    .select-list {
        $option-padding: 7px 40px 7px 15px;

        visibility: hidden;
        top: -100000px;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid $color-rabbit-fur;
        list-style: none;
        position: relative;
        background: $color-white;
        padding: 8px 0;
        white-space: nowrap;

        box-shadow: 0 3px 4px rgba($color-black, 0.1);

        // place menu above the input element
        &.dropup {
            bottom: 100%;
            margin-bottom: (-$borderSize);
        }

        @media (max-width: 500px) { // except on small screens, where we force the dropdown width to match the input
            white-space: normal;
        }

        // horizontal rule separator between groups
        .group-separator {
            border-top: 1px dotted $color-rabbit-fur;
            margin: 5px 10px;

            &:first-child {
                border: none;
            }
        }
        .select-prompt {
            padding: $option-padding;
            font-size: 14px;
        }

        .select-search-prompt {
            @include font-pn-light;
            font-style: italic;
            color: $color-stratus;
            cursor: pointer;
        }
        .select-add {
            .label {
                color: $color-elephant;
                font-size: 13px;
                margin-right: 4px;

                &:after {
                    content: ':';
                }
            }
            &.highlighted {
                background-color: $color-active-blue;
                color: $color-white;

                .label {
                    color: $color-white;
                }
            }
        }

        // each group header in dropdown
        .select-group {
            padding: 7px 40px 7px 10px;
            color: $color-elephant;
            text-transform: uppercase;
            font-size: 13px;

            &.disabled {
                color: $color-rabbit-fur;
            }
        }
    }
    .selected-option,
    .select-option,
    .none-option {
        text-transform: none;
    }
}

.input-file-upload {
    position: relative;
    display: inline-block;
    width: 100%;

    &.no-label {
        margin-top: 0;
    }
    input[type=file] {
        display: none;
    }
    button.inline.choose {
        display: block;
        text-transform: none;
        margin: 0 10px 0 0;
    }
    .file-info {
        display: block;
        position: relative;
        line-height: 1em;
        @include font-pn-regular;
        margin: 10px 0 0 1px;

        .icon-file {
            font-size: 10px;
            padding-right: 5px;
            display: inline-block;
        }
        &:before {
            display: none;
        }

        a.file-remove {
            font-size: 14px;
            line-height: 1em;
            vertical-align: middle;
        }
        .file-name {
            display: inline-block;
            font-size: 14px;
            line-height: 1em;
            color: $color-midnight;
            max-width: 250px;
            vertical-align: middle;
            margin: 0 10px 0 0;

            @include text-truncate;
        }
    }
    &.file-selected {
        .file-size {
            display: none;
        }
    }
    &__select-btn--required {
        -webkit-box-shadow: 0 0 0 3px rgba(242, 187, 26, 1);
        -moz-box-shadow: 0 0 0 3px rgba(242, 187, 26, 1);
        box-shadow: 0 0 0 3px rgba(242, 187, 26, 1);
    }
    &.image-upload {
        $img-width: 90px;

        &.selected,
        &.not-selected {
            .image-icon {
                &:after {
                    content: '';
                    border: none;
                }
            }
        }
        .image-icon {
            display: block;
            width: $img-width;
            height: $img-width;
            border: 1px solid $color-stratus;
            padding: 0;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            border-radius: 2px;

            // This should probably be refactored to not rely on absolute positioning,
            // but leaving it in place because of impending redesign.
            &:before {
                display: block;
                position: absolute;
                color: $color-stratus;
                font-family: $icon-font;
                text-transform: none;
                content: $icon-image;
                font-size: 90px;
                height: $img-width;
                line-height: 1.3em;
            }
            &:after {
                line-height: 1em;
                position: absolute;
                width: 22px;
                font-family: $icon-font;
                text-transform: none;
                right: 5px;
                bottom: 5px;
                content: '*';
                font-size: 22px;
                padding: 5px;
                color: $color-slate;
                background-color: $color-white;
                border: 1px solid $color-rhino;

                border-radius: 18px;
            }
            &.no-logo {
                display: inline-block;
                color: $color-stratus;
                border: 1px solid $color-stratus;
                height: 72px;
                width: 72px;
                padding: 5px 0 0;
            }

            &--disabled {
                pointer-events: none;

                &:before {
                    opacity: $inactive-opacity;
                }

                &:after {
                    color: $color-rhino;
                }
            }
        }
        .with-logo {
            width: 90px;
        }
        .input-image-thumb {
            display: none;
            // Width is repeated here and for img tag in case a user passes
            // in a React element for the thumbnail.
            width: $img-width;
            cursor: pointer;

            border-radius: 4px;

            &__img {
                display: block;
                width: $img-width;
                border: 1px solid $color-timberwolf;

                &--disabled {
                    opacity: $inactive-opacity;
                    cursor: default;
                }
            }

            &--default {
                display: block;
                opacity: 0.5;
            }
        }
        button.inline.choose {
            display: none;
        }
        .file-size {
            position: absolute;
            bottom: 4px;
            left: $img-width + $spacing-sm;
            min-width: 300px;
            color: $color-stratus;
            line-height: 1em;
            font-size: 13px;
            span:first-child {
                display: block;
            }
            .max-size {
                text-transform: none;
            }
        }
        .image-types {
            font-size: 14px;
            position: absolute;
            top: 65px;
            left: $img-width + 15px;
        }
        &.file-selected {
            .input-image-thumb {
                display: block;
            }
            .image-icon {
                display: none;
            }
        }
        &.inactive {
            display: none;
        }
    }
}

@mixin calendar-wrapper {
    @include font-pn-regular;

    padding: 5px 12px;
    border: 1px solid $color-stone;
    background-color: $color-input-fill;
    width: 200px;
    text-align: center;

    border-radius: 0 0 $default-input-border-radius $default-input-border-radius;
    user-select: none;

    .today-btn {
        display: none;
    }
    .days-view,
    .months-view,
    .years-view {
        .navigation-wrapper {
            .navigation-title {
                width: 100px;
                display: inline-block;
                cursor: pointer;
                color: $color-input-text;
                font-weight: 600;
            }
            .icon {
                display: inline-block;

                .fa {
                    display: inline-block;
                    height: 25px;
                    width: 25px;
                    color: $color-midnight;
                    font-size: 13px;
                    cursor: pointer;

                    &.fa-angle-left {
                        margin-right: 20px;
                    }
                    &.fa-angle-right {
                        margin-left: 20px;
                    }
                }
                &.disabled {
                    .fa {
                        opacity: $inactive-opacity;
                    }
                }
            }
        }
        .days-title {
            .cell {
                height: 25px;
                line-height: 25px;
                cursor: default;
                font-weight: 600;
                margin: 0;
                color: $color-input-text;
            }
        }
        .days,
        .months,
        .years {
            padding-bottom: 2px;
            .day {
                width: 26px;
                height: 25px;
                font-weight: 600;
            }
            .month {
                width: 58px;
                height: 38px;
                line-height: 38px;
                color: $color-input-text;
            }
        }
        .year {
            width: 58px;
            height: 38px;
            line-height: 38px;
            color: $color-input-text;
        }
        .day {
            width: 26px;
            height: 25px;
            line-height: 25px;
            color: $color-input-text;
            &.prev,
            &.next {
                opacity: $inactive-opacity;
                transition: opacity 0.5s ease;
                visibility: hidden;
            }
        }
        .cell {
            display: inline-block;
            text-align: center;
            cursor: pointer;
            border: none;
            -webkit-touch-callout: none;
            font-size: 13px;

            user-select: none;
            transition: $default-input-transition;

            &.hover {
                color: $color-input-text;
            }
            &.current,
            &:hover {
                opacity: 0.8;
                border-radius: 3px;
            }
            &.current {
                color: $color-white;
                background: $color-input-text;
            }
            &:hover {
                background: transparentize($color-input-text, 0.85);
            }
            &.title {
                &:hover {
                    opacity: 1;
                    background: transparent;
                }
            }
            &.disabled {
                opacity: $inactive-opacity;
            }
        }
    }
}

@mixin calendar-wrapper-no-portal {
    display: none;

    &.active {
        position: absolute;
        display: block;
        left: 0;
        top: calc(100% - 1px);
        -webkit-touch-callout: none;
        z-index: $zindex__menu;
    }
}

.input-calendar {
    display: inline-block;
    position: relative;
    @include font-pn-regular;
    margin-bottom: 7px;

    &:before,
    &:after {
        font-family: $icon-font;
        font-size: 14px;
        line-height: 1em;
        text-transform: none;
        position: absolute;
        color: $color-midnight;
        cursor: pointer;
    }
    &:before {
        content: $icon-calendar;
        left: 10px;
        bottom: 14px;
        z-index: $zindex__elevated;

        transition: $default-input-transition;
    }
    .input-container:after {
        @include dropdown-caret();
        cursor: pointer;
        pointer-events: unset;

        transition: $default-input-transition;
    }
    &.active {
        input[type=text] {
            border: 1px solid $color-stone;
        }
        &:after {
            transform: rotate(180deg);
        }
    }
    &:hover {
        .form-error-message {
            display: block;
            @include break-word;
        }
    }

    input[type=text].input-calendar-value {
        padding: 0 30px;
        font-size: $default-input-font-size;
        height: $default-input-height;
        line-height: 32px;
        &:focus {
            border: 1px solid $color-stone;
        }
    }
    .input-container {
        position: relative;
    }

    // display
    .input-calendar-wrapper {
        @include calendar-wrapper-no-portal;
        @include calendar-wrapper;
    }

    &--width-tight {
        input[type=text].input-calendar-value {
            width: 150px;
        }
    }

    &__label {
        margin-right: 0;
    }

    &.input-width-auto {
        .input-calendar-value {
            width: 226px;
        }
    }
}

// make styles work for popper container version
.calendar-popup {
    &.popper-container[x-placement^='bottom'],
    &.popper-container[x-placement^='top'] {
        margin-top: 0;
        margin-bottom: 0;
    }

    .input-calendar-wrapper {
        @include calendar-wrapper;
    }
}

.input-integer {
    position: relative;
    display: inline-block;

    &:after,
    a.undo {
        right: 40px;
    }
    .input-text {
        margin: 0;
    }

    .integer-controls {
        position: absolute;
        right: 13px;
        font-family: $icon-font;
        cursor: pointer;
        max-width: 35px;
        display: inline-block;
        bottom: 50%;
        transform: translateY(50%);

        button {
            $height: 10px;

            height: $height;
            width: 11px;
            padding: 0;
            margin: 0;
            display: block;
            text-align: center;
            text-decoration: none;
            color: $color-active-blue;
            border: none;
            background-color: transparent;
            line-height: $height;

            &:before {
                content: 'Y';
                font-family: $icon-font;
                font-size: 10px;
                line-height: $height;
                height: $height;
            }
            &.integer-up {
                margin-bottom: $spacing-xs;

                transform: scaleY(-1);
            }
        }

        transition: $default-input-transition;
    }
    [disabled],
    [readonly] {
        .integer-controls {
            pointer-events: none;
        }
    }
}

//pulled this out of the above and made it more generic for multiple list components to use
.list-input__bottom-links {
    border-top: 1px solid $color-input-fill;
    padding: $spacing-xs 0;

    .option,
    a {
        font-size: 13px;
        @include font-pn-regular;
        color: $color-active-blue;
        display: inline-block;
        padding: 0;
        cursor: pointer;

        &:after {
            content: '|';
            padding: 0 5px;

            &:hover {
                text-decoration: none !important;
            }
        }
        &:last-of-type {
            &:after {
                content: '';
                padding: 0;
            }
        }
    }
}

.link-dropdown-list {
    @include drop-down-styles;
    & &__link { // specificity to override default .collapsible-link styles
        padding: 0;
    }
    //temporary. remove when lists are aligned
    .list-input__bottom-links {
        margin: 0 $spacing-sm $spacing-xs;
    }

    .list-input__top-panel {
        border-bottom: 1px solid $color-input-fill;
    }
}


// INPUT VARIANTS ------------------------------------------------------------------------------------------------------

.required {
    @mixin requiredCss {
        content: '';
        position: absolute;
        background-color: $color-input-required;
        color: $color-white;
        font-size: 16px;
        text-align: center;
        width: 5px;
        z-index: $zindex__elevated;

        border-radius: 1px;
        transition: $default-input-transition;
    }

    @mixin requiredPadding {
        @include font-pn-regular;
        padding-left: $spacing-md;
    }

    @mixin requiredPaddingEntered {
        padding-left: $spacing-md;
    }

    &.input-text,
    &.input-calendar {
        input[type=text] {
            @include requiredPadding;
        }
        > .input-container {
            &:before {
                @include requiredCss;
                top: 5px;
                bottom: 5px;
                left: 5px;
            }
        }
    }
    &.input-calendar {
        &:before {
            left: 16px;
        }
        input[type=text].input-calendar-value {
            padding-left: 36px;
        }
    }
    &.input-textarea {
        textarea {
            @include requiredPadding;
        }
        > .input-container {
            &:before {
                @include requiredCss;
                top: 5px;
                bottom: 5px;
                left: 5px;
            }
        }
    }
    &.input-multivalues {
        .entries {
            @include requiredPadding;

            &:before {
                @include requiredCss;
                top: 5px;
                bottom: 5px;
                left: 5px;
            }
        }
    }
    &.input-select {
        select {
            padding-left: 25px;
        }
        .input-container {
            &:before {
                @include requiredCss;
                top: 5px;
                bottom: 7px;
                left: 5px;
            }
        }
    }
    &.legend {
        float: right;
        margin: 0 0 0 15px;

        &:before {
            top: 0;
            bottom: 2px;
            left: -14px;
        }
    }
    &.value-entered {
        &.input-text,
        &.input-textarea,
        &.input-select,
        &.input-calendar {
            .input-container {
                &:before {
                    width: 0;
                }
            }
        }
        &.input-calendar {
            &:before {
                left: 10px;
            }
            input[type=text] {
                padding-left: 30px;
            }
        }
        &.input-text {
            input {
                @include requiredPaddingEntered;
            }
        }
        &.input-textarea {
            textarea {
                @include requiredPaddingEntered;
            }
        }
        &.input-select {
            select {
                @include requiredPaddingEntered;
            }
        }
    }
    &.selection-filter-label {
        padding-left: 20px;

        &:before {
            top: 5px;
            bottom: 5px;
            left: 5px;

            @include requiredCss;
        }
    }
}


.form-error,
.input-message {
    $message-icon-size: 16px;
    $message-icon-margin: 5px;
    $message-input-offset: $message-icon-size + $message-icon-margin;
    $icon-spacing: $message-icon-size + $message-icon-margin;

    @mixin inputMessageDisplay {
        ~ .input-message .input-message__text,
        ~ .form-error-message {
            @include break-word;
            display: block;
        }
    }

    @include keyframes('fade-down-in') {
        0% {
            opacity: 0;
            margin-top: -4px;
        }
        100% {
            opacity: 1;
            margin-top: 0;
        }
    }

    &.input-text {
        input[type=text],
        input[type=password] {
            border-color: $color-input-error;

            &:hover,
            &:focus {
                @include inputMessageDisplay;
            }
        }
        .input-container {
            padding-right: $icon-spacing;
            border-color: $color-input-error;

            .input-icon--right {
                right: 30px;
            }
        }
        .integer-controls {
            right: 33px;
        }
        a.password-show-button {
            right: 35px;
        }
        &.search {
            a.clear-search {
                transition: $default-input-transition;
                right: 32px;
            }
        }
    }
    &.input-calendar {
        .input-container {
            border: 1px solid $color-input-error;
            border-radius: 2px;
        }
        .form-error-icon {
            top: 34px;
            right: -20px;
        }
        .form-error-message {
            width: 100%;
        }
        &.active {
            .form-error-message {
                display: none;
            }
        }
    }

    &.input-textarea {
        textarea {
            border-color: $color-input-error;

            &:hover,
            &:focus {
                @include inputMessageDisplay;
            }
        }
        .input-container {
            padding-right: $icon-spacing;
        }
        .input-message__icon,
        .form-error-icon {
            top: $message-icon-margin;
        }
        .undo {
            right: 30px;
        }
    }
    &.input-select {
        .wrapper {
            border-color: $color-input-error;

            &:hover {
                @include inputMessageDisplay;
            }
        }
        select {
            &:focus {
                @include inputMessageDisplay;
            }
        }
    }
    &.input-select:not(.input-custom-select) {
        .input-message__icon,
        .form-error-icon {
            right: -($message-input-offset);
        }
        .input-container {
            width: calc(100% - #{$message-input-offset});
        }
    }
    &.input-custom-select
    &.input-text
    input[type=text] {
        border-color: $color-input-error;
    }
    &.input-checkbox {
        .icon {
            &:before {
                border-color: $color-input-error;
            }
        }
        .input-message__icon,
        .form-error-icon {
            top: auto;
            left: 31px;
            right: auto;
            margin: 3px 0 0;
        }
        &.inline {
            .label-text {
                padding-right: 7px;
            }
            .input-message__icon,
            .form-error-icon {
                top: 5px;
                left: 100%;
                margin: 0;
            }
            &.form-error {
                margin-right: 40px;
            }
        }
    }
    &.input-radio {
        .circle {
            border-color: $color-input-error;
        }
    }
    &.intl-tel-input {
        input[type=text] {
            border-color: $color-input-error;

            &:focus {
                @include inputMessageDisplay;
            }
        }
    }

    &.input-file-upload {
        .input-message__icon,
        .form-error-icon {
            top: auto;
            bottom: 5px;
            right: -16px;
        }
        .input-message__text,
        .form-error-message {
            top: calc(100% + 5px);

            border-radius: $default-input-border-radius;
        }

        &.image-upload {
            .input-message__icon,
            .form-error-icon {
                right: -35px;
            }
            .input-message__text,
            .form-error-message {
                top: 100%;

                border-radius: 0 0 $default-input-border-radius $default-input-border-radius;
            }
        }
        input {
            &:focus {
                @include inputMessageDisplay;
            }
        }
    }
    &.input-integer {
        .integer-controls {
            right: 34px;
        }
        a.undo {
            right: 61px;
        }
        &:focus {
            @include inputMessageDisplay;
        }
    }
    &.input-timezone {
        padding-right: 26px;

        .input-message__icon,
        .form-error-icon {
            top: 0;
            right: 0;
        }
        .input-message__text,
        .form-error-message {
            top: calc(100% - 20px);

            border-radius: $default-input-border-radius;

        }
    }
    &.unit-input {
        position: relative;

        .input-message__text {
            box-sizing: border-box;
            width: calc(100% - 21px);
        }

        .unit-input__drop-down-list--error {
            border-left-width: 0;
            margin-left: -1px;
            padding-right: 21px;
            margin-bottom: 0;
        }

        .unit-input__text-field--error input[type=text],
        .unit-input__drop-down-list--error input[type=text],
        .unit-input__drop-down-list--error .readonly input[type=text] {
            border: 1px solid $color-critical-red;

            &:hover {
                border: 1px solid $color-critical-red;
            }
        }

        .input-message {
            position: relative;

            .input-message__icon,
            .form-error-icon {
                right: 2px;
                top: -28px;
            }
        }
    }

    &.input-textselect {

        > .input-text {
            padding-right: $icon-spacing;
            margin-right: -$icon-spacing !important;
        }
        &:hover,
        &:focus {
            .input-message .input-message__text {
                display: block;
            }
        }
    }
    &.input-custom-select {
        &.input-text {
            input[type=text] {
                &:hover {
                    border: 1px solid $color-critical-red;
                }
            }
        }
        .wrapper:after {
            right: 28px;
        }
    }

    .input-message__icon,
    .form-error-icon {
        color: $color-critical-red;
        position: absolute;
        top: round(($default-input-height - $message-icon-size) / 2);
        right: 0;
        height: $message-icon-size;
        width: $message-icon-size;

        &:before {
            position: absolute;
            right: 0;
            content: $icon-alert;
            font-family: $icon-font;
            height: $message-icon-size;
            width: $message-icon-size;
            font-size: $message-icon-size;
            line-height: 1em;
            text-transform: none;
        }

        &:hover {
            @include inputMessageDisplay;
        }
    }

    .input-message__text,
    .form-error-message {
        color: $color-white;
        background-color: $color-critical-red;
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        right: $message-input-offset;
        display: none;
        z-index: $zindex__popover;
        padding: 15px;
        box-sizing: border-box;
        min-width: 169px;
        text-transform: none;
        -webkit-backface-visibility: hidden; // keeps animation from flickering in Chrome

        border-radius: 0 0 $default-input-border-radius $default-input-border-radius;
        animation: fade-down-in 0.2s;

        &--error {
            background-color: $color-critical-red;
        }
    }

    @mixin messageTypeCss ($color, $icon) {
        .input-message__icon {
            color: $color;

            &:before {
                content: $icon;
            }
        }
        .input-message__text {
            background-color: $color;
            text-shadow: 0 0 3px rgba($color-black, 0.6);
        }
        &.input-text {
            input[type=text],
            input[type=password] {
                border-color: $color;
            }
        }
    }

    &.input-message--success {
        @include messageTypeCss ($color-success-green, $icon-success);
    }
    &.input-message--warning {
        @include messageTypeCss ($color-warning-yellow, $icon-alert);
    }
    &.input-message--info {
        @include messageTypeCss ($color-active-blue, $icon-info);
    }

    .input-multivalues {
        &__error {
            display: block;
            font-size: 0.9em;
            width: 100%;
        }
    }
}

.disabled {
    $disabled-opacity: $inactive-opacity;
    $normal-opacity: 1;

    font-style: normal;
    cursor: default;

    [disabled] {
        cursor: default;
    }

    &.input-text,
    &.input-textarea,
    &.input-multivalues,
    &.input-select {
        .input-container {
            opacity: $disabled-opacity;
        }
        .label-container {
            span {
                opacity: $disabled-opacity;
                &.icon-help,
                &.icon-lock {
                    opacity: $normal-opacity;
                }
            }

            .label-container {
                opacity: 1;
            }
        }
        .password-show-button {
            cursor: default;
            pointer-events: none;
        }
    }

    &.input-checkbox {
        .icon {
            opacity: $disabled-opacity;
        }
        .label-container {
            span {
                opacity: $disabled-opacity;
                &.icon-help,
                &.icon-lock {
                    opacity: $normal-opacity;
                }
            }
        }
    }

    &.input-text {
        input {
            border: 1px solid $color-input-fill;
        }
    }
    &.input-radio {
        opacity: $disabled-opacity;

        input[type=radio] + .circle {
            cursor: default;
        }
    }

    &.input-multivalues {
        input[type=text],
        input[type=password],
        textarea {
            &:hover {
                border: 1px solid $color-input-fill;
            }

            opacity: 1;
        }
        .input-container {
            &:before {
                content: none;
            }
        }
    }

    &.input-select {
        .input-container {
            .wrapper {
                &:after {
                    content: '';
                }
            }
        }
    }
}

.readonly {
    &.input-text,
    &.input-textarea {
        input[type=text],
        input[type=password],
        textarea {
            cursor: text;
            background-color: transparent;
            border-color: $color-rabbit-fur;

            &:hover,
            &:focus {
                border-color: $color-rabbit-fur;
            }
        }
        textarea {
            resize: none !important;
        }
    }
    &.required {
        &.input-text {
            input[type=text],
            input[type=password] {
                padding-left: 15px;
            }
        }
        &.input-textarea {
            textarea {
                padding-left: 15px;
            }
        }
        &.input-text,
        &.input-textarea,
        &.input-select > div,
        &.legend {
            &:before {
                content: none;
            }
        }
        &.input-select {
            select {
                padding: 0 30px 0 10px;
            }
        }
    }
}

// password requirements indicators
.validation-messages {
    padding: 10px 0;
    display: none;
    &.show {
        display: block;
        &.inline {
            display: inline-block;
            margin-left: 5px;
        }
    }

    &--show {
        display: block;
    }

    .message {
        padding: 3px 0;
    }
}


// MISC SUPPORTING STYLES ----------------------------------------------------------------------------------------------

.input-row {

    &--strict {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    &__accessories {
        display: inline-block;
        line-height: $default-input-height;

        & > * {
            vertical-align: middle;
        }
    }

    &--top > *,
    &--top > label {
        vertical-align: top;
    }

    &--top &__accessories {
        margin-top: 22px;
    }

    position: relative;
    margin: 0 0 $spacing-lg;

    .input-text,
    .input-textarea,
    .input-select,
    .input-file-upload,
    .input-integer,
    .input-radio,
    .input-checkbox,
    .input-checkbox__container--stacked:last-of-type .input-checkbox,
    .input-color-picker,
    .input-calendar {
        margin-bottom: 0;
    }

    .row-selector {
        margin-bottom: 50px;
    }

    .input-filedrop {
        margin-bottom: initial;
    }

    .input-checkbox {
        &.inline {
            vertical-align: bottom;
            line-height: 27px;
            .icon,
            input {
                top: 0;
                bottom: unset;
            }
            .form-error-message {
                line-height: normal;
                top: calc(100% - 5px);
            }
        }
    }

    .input-row--line-height {
        line-height: $default-input-height;
    }
}

.checkbox-list {
    padding: 10px 5px 10px 0;
    margin-left: 0;
    border: 1px solid $color-elderly;

    .filters {
        margin: 0 0 0 10px;
    }
    .options {
        width: 99%;
        max-height: 133px;
        overflow-y: auto;
        padding-left: 10px;

        label.input-checkbox {
            width: 190px;
            margin-bottom: 10px;
            text-transform: none;
            display: inline-block;
            padding: 0 0 0 30px;

            .label-text {
                display: inline-block;
                padding-top: 6px;
                margin: 0;
            }
        }
        &.item-head {
            display: block;
            color: $color-walrus;
        }
    }
    .actions {
        display: inline-block;
        margin: 12px 0 0;
        font-size: 14px;
    }
}

.input-datetime {
    .input-time {
        margin-right: 0;
        margin-left: 3px;
    }
}

.input-textselect {
    display: inline-block;

    // UnitInput Component
    &.unit-input {
        margin: 0;
        .input-custom-select {
            padding-top: 0;
            padding-bottom: 0;
        }
        .input-text {
            input[type=text] {
                &.selected-option-label {
                    text-align: left;
                }
            }
        }
        .input-message__text {
            white-space: initial;
            word-break: break-word;
        }
        &.reversed {
            .input-text {
                input[type=text] {
                    border-color: $color-dolphin;
                    background-color: $color-white;
                    &.selected-option-label {
                        border-color: $color-dolphin;
                    }
                }
            }
            > .input-text {
                input[type=text] {
                    border-width: 1px 0 1px 1px;
                }
            }
            > .input-custom-select {
                border-color: $color-dolphin;

                input[type=text] {
                    border-width: 1px 1px 1px 0;
                }
            }
        }
        .unit-input__input-container {
            display: inline-block;
            .input-text {
                margin-right: 0;
                &.unit-input__text-field--error {
                    margin-right: -1px;
                }
            }
        }
    }

    .input-container .input-select {
        margin: 0 0 0 -2px;
        vertical-align: middle;
        display: inline-block;

        .wrapper {
            border-radius: 0 $default-input-border-radius $default-input-border-radius 0;
        }
    }

    > .input-custom-select {
        padding: 0;
        border-left: 1px solid transparent;

        .input-text {
            input[type=text] {
                border-radius: 0 $default-input-border-radius $default-input-border-radius 0;
                width: 100%;
                text-align: left;
            }
        }
    }

    > .input-text {
        margin-right: 0 !important;

        input[type=text] {
            text-align: right;
            vertical-align: middle;
            display: inline-block;

            border-radius: $default-input-border-radius 0 0 $default-input-border-radius;
        }

        &.form-error {
            input[type=text] {
                border-radius: $default-input-border-radius 0 0 $default-input-border-radius;
            }
            .input-container {
                padding-right: 0;
            }
        }
    }
}

.input-selectionfilter {
    display: inline-block;
    margin: 0 15px 20px 0;
}

a {
    &.input-link {
        display: block;
        margin: 2px 0 0 1px;
    }
}

.drop-link {
    position: relative;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }
    &:after {
        content: 'v';
        font-family: $icon-font;
        font-size: 12px;
        text-transform: none;
        right: 10px;
        top: 1px;
        pointer-events: none;
    }
    &.open {
        &:after {
            content: '^';
        }
    }
}

.input-divider {
    display: block;
    margin: 0 0 15px;
    border-top: 1px solid $color-rabbit-fur;
    &.dotted {
        border-top-style: dotted;
    }
}

.input-child {
    margin: 0 0 0 25px;
}

.radio-input-description {
    color: $color-elephant;
    margin: -12px 0 13px 35px;
}

.input-radio {
    &:last-of-type {
        ~ .radio-input-description {
            margin-top: -7px;
        }
    }
}

@function calc-input-width($container-width, $divisions, $hor-margin) {
    @return (
        (($container-width - (($divisions - 1) * $hor-margin)) / $divisions)
    );
}

@function calc-input-part-width($fraction-num, $fraction-width, $hor-margin) {
    @return (
        round($fraction-width*$fraction-num + (($fraction-num - 1)*$hor-margin))
    );
}

@function input-width-map($width-smallest, $hor-margin) {
    @return ((
        'xsmall': $width-smallest,
        'small': calc-input-part-width(2, $width-smallest, $hor-margin),
        'medium': calc-input-part-width(3, $width-smallest, $hor-margin),
        'large': calc-input-part-width(4, $width-smallest, $hor-margin),
        'xlarge': calc-input-part-width(5, $width-smallest, $hor-margin),
        'full': calc-input-part-width(6, $width-smallest, $hor-margin)
    ));
}

@mixin input-width-css($size) {
    .label-text {
        @include break-word;
    }
    &.input-text,
    &.input-textselect,
    &.input-textselect .input-text.form-error,
    &.input-textselect .input-text.input-message {
        width: map-get($input-width-settings-map-text, $size);
    }
    &.input-multivalues {
        max-width: map-get($input-width-settings-map-text, $size);
        width: map-get($input-width-settings-map-text, $size);

        &.input-multivalues--auto-width {
            width: auto;
        }
    }
    &.input-textarea {
        width: map-get($input-width-settings-map-textarea, $size);
    }
    &.input-select,
    &.input-custom-select {
        width: map-get($input-width-settings-map-select, $size);
    }
    &.input-checkbox {
        width: map-get($input-width-settings-map-default, $size);
    }
    &.input-calendar {
        input[type=text] {
            width: map-get($input-width-settings-map-default, $size);
        }
    }
    &.input-datetime {
        $dt-width: map-get($input-width-settings-map-default, $size);
        $cal-percent: 0.66;
        $cal-width: ceil($dt-width * $cal-percent);
        $time-width: floor(($dt-width - 4) * (1 - $cal-percent));

        display: inline-block;

        .input-calendar {
            input[type=text] {
                width: $cal-width;
            }
        }
        .input-time {
            select {
                width: $time-width;
            }
        }
    }
}

@mixin flex-width-css($size) {
    .label-text {
        @include break-word;
    }
    &.input-text {
        min-width: map-get($input-width-settings-map-text, $size);
        width: auto;
    }
}

// this is only used by the unit input
.text-field--right-align input {
    text-align: right;
}

// Input width settings
$input-width-settings: (
    'width-max': 590px,
    'num-increments': 6,
    'hor-margin': 10px,
    'vert-margin': 25px
);
$input-width-settings-smallest-default: calc-input-width(
    map-get($input-width-settings, 'width-max'),
    map-get($input-width-settings, 'num-increments'),
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-smallest-text: calc-input-width(
    map-get($input-width-settings, 'width-max'),
    map-get($input-width-settings, 'num-increments'),
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-smallest-textarea: calc-input-width(
    map-get($input-width-settings, 'width-max'),
    map-get($input-width-settings, 'num-increments'),
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-smallest-select: calc-input-width(
    map-get($input-width-settings, 'width-max'),
    map-get($input-width-settings, 'num-increments'),
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-map-default: input-width-map(
    $input-width-settings-smallest-default,
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-map-text: input-width-map(
    $input-width-settings-smallest-text,
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-map-textarea: input-width-map(
    $input-width-settings-smallest-textarea,
    map-get($input-width-settings, 'hor-margin')
);
$input-width-settings-map-select: input-width-map(
    $input-width-settings-smallest-select,
    map-get($input-width-settings, 'hor-margin')
);

label {
    &.unit-input__text-field--error,
    .unit-input__drop-down-list--error {
        margin-bottom: 0 !important;
    }
    &.input-width-xsmall,
    &.input-width-small,
    &.input-width-medium,
    &.input-width-large,
    &.input-width-xlarge,
    &.input-width-full,
    &.input-width-auto {
        margin-bottom: map-get($input-width-settings, 'vert-margin');
        vertical-align: bottom;

        input[type=text],
        textarea,
        input[type=password],
        textarea {
            width: 100%;
        }
    }

    &.input-width-max {
        width: 100%;
        margin-right: 0;
        max-width: none;
    }
}

.input-width-xsmall {
    @include input-width-css('xsmall');
}

.input-width-small {
    @include input-width-css('small');
}

.input-width-medium {
    @include input-width-css('medium');
}

.input-width-large {
    @include input-width-css('large');
}

.input-width-xlarge {
    @include input-width-css('xlarge');
}

.input-width-full {
    @include input-width-css('full');
}

label.input-text {
    &--nospacing {
        margin-bottom: 0;
    }
}

// CUSTOM STYLES

.icon-selector-button {
    background: transparent;
    border: 1px solid transparent;
    box-sizing: border-box;
    height: auto;
    line-height: 15px;
    padding: $spacing-xs;
    margin-right: $spacing-xs;
    font-size: 18px;
    color: $color-accent-neutral-10;

    &:hover {
        color: $color-accent-neutral-10;
        background: $color-accent-neutral-90;
        border-color: $color-accent-neutral-90;
    }

    &--selected,
    &--selected:hover,
    &:active {
        border-radius: 2px;

        background: $color-white;
        border-color: $color-active-blue;
    }
}


// DEPRECATED OR SITE-SPECIFIC STYLES --------------------------------------------------------------
// TODO : move WP styles to WP

label {
    &.input-text {
        &.large {
            .input-container {
                input {
                    height: 48px;
                    font-size: 16px;
                }
            }
            &.search {
                .input-container {
                    &:before {
                        bottom: 18px;
                        left: 14px;
                    }
                    .undo {
                        bottom: 13px;
                        right: 12px;
                    }
                }
            }
        }
    }
    &.main {
        display: block;
        margin: 0 0 5px;
    }
}

.inline-input-labels {
    > label {
        display: table-row;

        &:before {
            content: none !important;
        }
        .label-text,
        .input-container {
            display: table-cell;
            vertical-align: middle;
            border-bottom: 15px solid transparent;
        }
        .label-text {
            padding: 0 15px 0 0;
        }
        .input-container {
            display: table-cell;
            padding: 0;
        }
        input,
        textarea {
            margin: 0;
        }
        &.input-select {
            .input-container {
                margin: 0;

                select {
                    margin: 0;
                }
            }
        }
        .selected-option-label {
            margin: 0;
        }
        &.input-checkbox {
            input {
                display: none;
            }
            .label-text {
                height: 25px;
            }
            .icon {
                display: table-cell;
                position: relative;
                &:after {
                    bottom: auto;
                    top: 5px;
                }
            }
        }
    }
}

.password-errors {
    margin: 10px 0 0 2px;

    > div {
        display: inline-block;
        margin: 0 15px 5px 0;
        width: 164px;

        &:before {
            content: '\00D7'; // no icon for this exists in current library
            background: $color-critical-red;
            color: $color-white;
            font-size: 14px;
            height: 14px;
            width: 14px;
            line-height: 16px;
            margin: 0 5px 0 0;
            text-align: center;
            display: inline-block;

            border-radius: 10px;
        }
    }
}

.radio-inputs {
    position: relative;
    margin: 15px 0 0 23px;
}

.input-row {
    &.lead-item {
        margin-bottom: 10px;
    }
    .inactive,
    &.inactive {
        display: none;
    }
    .text-input,
    .input-checkbox {
        text-align: left;
        .comment {
            display: block;
            padding: 5px;
            margin-top: -3px;
            &.error {
                background-color: $color-critical-red;
                color: $color-white;
                padding: 15px;

                border-radius: 0 0 4px 4px;
            }
        }
    }
}

.input-timezone {
    position: relative;
    display: inline-block;
    vertical-align: bottom;

    & &__link {
        text-transform: none;
        padding: 0;
    }
    .popup-frame {
        width: 330px;
    }

    &__clear-country {
        font-size: 10px !important;
        vertical-align: baseline;
        margin: 0 0 0 5px;
    }
    &__selected-country {
        margin: 0 0 0 10px;
        color: $color-midnight;
        text-transform: none;
        font-size: 14px;
    }
    &__offset {
        float: right;
    }
    &.input-timezone--country-selected {
        .popover-search {
            height: 0;
            overflow: hidden;
            opacity: 0;
        }
    }
    &__search .input-text {
        margin-bottom: 0;
    }
}

// filter bar
.selection-filter-label {
    margin: 0;
    padding: 1px 38px 0 15px;
    font-size: 13px;
    height: $default-input-height;
    line-height: $default-input-height;
    border: $borderSize solid $color-rabbit-fur;
    position: relative;
    display: inline-block;
    @include font-pn-regular;
    background-color: $color-white;
    min-width: 170px;
    max-width: 100%;
    text-align: left;
    text-transform: none;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
    color: $color-midnight;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    border-radius: $default-input-border-radius;
    box-sizing: border-box;

    &:after {
        position: absolute;
        top: 0;
        right: 15px;
        content: $icon-expand-arrow;
        font-family: $icon-font;
        font-size: 9px;
        color: $color-midnight;

        transition: ($default-input-transition);
    }
    .count {
        margin-left: 5px;
    }

    &__placeholder {
        opacity: $inactive-opacity;
        font-style: italic;
    }

    &--open:after {
        transform: rotate(-180deg);
        transition: (all 0.2s linear);
    }
}

.input-message-inline {
    border-radius: 2px;
    display: inline-block;
    color: $color-white;
    font-size: 15px;
    line-height: 1em;
    padding: 10px;
    margin: 7px 0 10px;

    &.required {
        background: $color-warning-icon-yellow;
    }
}

.input-icon {
    @include input-icon();

    &.icon-search {
        font-weight: bold;
    }

    &--right {
        right: $spacing-sm;
        left: auto;
    }

}

label.input-text--right-icon {
    input {
        padding-right: 35px;
    }
}

.input-text {
    &--right-arrow {
        margin-right: calc(#{$spacing-xl} + #{$spacing-md});
    }

    &__container {
        &--right-arrow {
            //margin-right: $spacing-xl;
            width: 100%;
            &:after {
                transform: rotate(-135deg);
                content: '';
                border: solid $color-neutral-70;
                border-width: 0 0 1px 1px;
                width: 10px;
                height: 10px;
                line-height: 0;
                font-size: 0;
                position: absolute;
                right: -$spacing-xl;
                bottom: 37%;
            }
            &:before {
                content: '';
                border: 0.5px solid $color-neutral-70;
                position: absolute;
                bottom: 50%;
                width: $spacing-xl;
                right: -$spacing-xl;
            }
        }

        &--inline {
            .input-message__icon {
                top: auto;
                bottom: 5px;
            }

            input[type=text],
            input[type=password],
            input[type=email],
            input[type=number],
            input[type=search],
            input[type=tel],
            input[type=url] {
                width: auto;
                padding: 2px;
                height: 25px;
                font-size: 13px;
                align-items: top;
                margin-top: -5px;
            }
        }
    }
}

.sub-text {
    position: absolute;
    right: 0;
    font-size: 10px;
    text-transform: lowercase;
}

.flex-width-XS {
    @include flex-width-css('xsmall');
}

.flex-width-SM {
    @include flex-width-css('small');
}

.flex-width-MD {
    @include flex-width-css('medium');
}

.flex-width-LG {
    @include flex-width-css('large');
}

.flex-width-XL {
    @include flex-width-css('xlarge');
}

.flex-width-XX {
    @include flex-width-css('full');
}

.flex-width-spacer {
    font-size: 1.09em;
    height: 0;
    padding: 0 15px;
    text-transform: none;
    visibility: hidden;

    &__password {
        font-size: 0.4em;
        // Icon font used for monospacing to match password masking character
        font-family: $icon-font;
    }

    &__reveal {
        margin-left: 25px;
    }

    &__icon-left {
        margin-left: 30px;
    }
}

// IE-specific CSS
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .flex-width-spacer {
        &__password {
            font-size: 0.66em;
            font-family: $icon-font;
            margin-left: 30px;
        }

        &__reveal {
            margin-left: 55px;
        }

        &__icon-left {
            margin-left: 30px;
        }
    }
}
