.nav-card {
    display: flex;

    .dashboard-card__front {
        border-radius: 4px;

        //setting temp background
        background-color: rgba(2, 30, 53, 0.521);
    }

    &__title.text-page-title {
        font-size: 20px;
        color: $color-white;
    }
}
