.slider {
    border: 1px solid #cfd3d6;
    border-radius: 5px;
    height: 10px;
    width: 100%;

    &--disabled {
        opacity: $inactive-opacity;
    }

    &__container {
        height: 25px;
        position: relative;
        z-index: 0;
    }

    &__indicator {
        background-color: #fff;
        border: 1px solid #c1c5c8;
        border-radius: 50%;
        box-shadow: 0 2px 2px 0 #cacaca;
        cursor: pointer;
        height: 25px;
        position: absolute;
        top: -8px;
        width: 25px;

        &--disabled {
            background-color: $color-neutral-80;
            cursor: default;
        }
    }
}
