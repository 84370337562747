$wizard-progress-icon-size: 23px;
$wizard-progress-menu-padding: 20px;
$wizard-v-spacing: 25px;
$wizard-h-spacing: 40px;
$wizard-padding: $wizard-v-spacing $wizard-h-spacing 0 $wizard-h-spacing;
$wizard-progress-menu-width: 280px;
$wizard-progress-menu-bgcolor: $color-neutral-30;
$wizard-progress-menu-selected-bgcolor: $color-strong-neutral-10;
$wizard-progress-title-size: 18px;
$wizard-progress-icon-border-size: 1px;
$wizard-text-spacing: 15px;
$wizard-header-border-color: $color-neutral-70;
$wizard-title-font-size: 28px;
$wizard-selected-arrow-width: 15px;
$wizard-content-bg-color: $color-strong-neutral-90;

$wizard-header-height: 77px;
$wizard-close-button-color: $color-strong-neutral-40;

.wizard2 {
    * {
        box-sizing: border-box;
    }
    position: fixed;
    top: 46px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $wizard-content-bg-color;
    color: $color-strong-neutral-10;
    z-index: $zindex__modal;

    // I know we are trying to only do one level of specificity for BEM and such, but I need to override the button bar defaults
    .wizard2__button-bar {
        position: fixed;
        left: $wizard-h-spacing;
        right: $wizard-progress-menu-width + $wizard-h-spacing;
        bottom: 0;
        background-color: $wizard-content-bg-color;
        padding: $wizard-v-spacing 0;
        z-index: $zindex__fixed-controls;
    }

    // psuedo element to cover the header items when the wizard is open
    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 46px;
        background-color: rgba($color-neutral-10, 0.7);
    }

    //TODO: input-modifier light
    .input-multivalues .entries {
        background-color: #fff;
        border: 1px solid #c3c7cb;
    }

    // contextual CSS
    &--header-shown {
        .wizard2-progress-menu-scroller {
            top: $wizard-header-height;
        }
        .wizard2__content {
            top: $wizard-header-height;

            > .page-messages {
                top: $wizard-header-height + $topnav-height;
            }
        }
    }
    &--menu-hidden {
        .wizard2__content {
            width: 100%;
        }
        .wizard2__button-bar {
            right: 40px;
        }
        .wizard2__page-loader {
            left: 50%;
        }
        .wizard2-close-btn,
        .wizard2-close-btn:hover {
            color: $color-neutral-30;
        }
    }
    &--buttonbar-hidden {
        .wizard2__content {
            margin-bottom: 0;
        }
    }

    &-close-btn {
        z-index: $zindex__elevated;
        float: right;
        margin: 20px 20px 0 0;
        padding: 0;
        border: none;
        background: none;
        font-size: 24px;
        line-height: 1em;
        opacity: 0.5;
        color: $color-white;

        &:before {
            content: 'x';
            font-family: $icon-font;
            display: block;
        }
        &:hover {
            color: $color-white;
            opacity: 1;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        background-color: $color-white;
        font-size: $wizard-title-font-size;
        @include font-pn-light;
        border-bottom: 1px solid $wizard-header-border-color;
    }

    &__header-details {
        padding: $spacing-md $spacing-lg;
        flex: 1;
        border-right: 1px solid $wizard-header-border-color;
        font-size: 23px;
        color: $color-accent-neutral-10;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
            border-right: none;
        }
    }

    &__header-title {
        @include font-pn-regular;
        font-size: 12px;
        color: $color-blue-grey;
        margin-bottom: $spacing-sm;
        text-transform: uppercase;
    }

    &__content {
        $buttonbar-height: 87px;

        position: absolute;
        width: calc(100% - #{$wizard-progress-menu-width});
        padding: $wizard-padding;
        margin: 0 0 $buttonbar-height 0;
        bottom: 0;
        top: 0;
        overflow-y: auto;

        .page-messages {
            position: fixed;
            left: 0;
            width: calc(100% - #{$wizard-progress-menu-width});
            height: auto;
        }

        // add spacing at the end of the scrolling content container
        &:after {
            content: '';
            display: block;
            height: $wizard-v-spacing;
        }

        .page-section {
            margin-left: 0;
        }
    }

    &-step {
        &-top {
            display: flex;
            align-items: center;
            &__title {
                font-size: $wizard-title-font-size;
                @include font-pn-light;
                line-height: 1em;
                margin-bottom: $wizard-text-spacing;
                flex: 3;
            }
            &__info {
                flex: 1;
                text-align: right;
            }
        }
        &__description {
            margin-bottom: $wizard-v-spacing;
        }
        &__content {
            margin-left: $wizard-h-spacing;

            &:before {
                content: '';
                border-top: 1px solid $color-rabbit-fur;
                display: block;
                margin-bottom: $spacing-xl;
                margin-left: -$wizard-h-spacing;
            }
        }
    }

    &-progress-menu {
        min-height: 100%;
        margin-left: $wizard-selected-arrow-width;
        background-color: $wizard-progress-menu-bgcolor;
        color: $color-white;

        &-scroller {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            overflow-y: auto;
            width: $wizard-progress-menu-width;
        }

        &__title {
            position: relative;
            text-transform: uppercase;
            font-size: 18px;
            padding: 30px 25px 25px 20px;
        }

        &__step {
            position: relative;
            padding: $wizard-progress-menu-padding;
            cursor: pointer;

            &:after {
                @include inactive-link-styles;
            }

            .wizard-progress-menu__item-description {
                opacity: 0.8;
            }

            &-icon {
                position: absolute;
                height: $wizard-progress-icon-size;
                width: $wizard-progress-icon-size;
                line-height: #{$wizard-progress-icon-size - 1};
                border-radius: 50%;
                border: $wizard-progress-icon-border-size solid $color-white;
                color: $color-white;
                text-align: center;

                @mixin required-active-css {
                    background-color: $color-white;
                    color: $wizard-progress-menu-selected-bgcolor;
                }

                &--required-active {
                    @include required-active-css;
                }

                &--optional-active {
                    @include required-active-css;
                    @include font-pn-bold;
                    font-size: 24px;

                    &:before {
                        display: block;
                        content: '...';
                        line-height: 0.5;
                    }
                }

                &--completed {
                    @extend .icon-check;
                    font-family: 'iconfont';
                    background-color: $color-success;
                    border-color: $color-success;
                    font-size: 10px;
                    &:before {
                        display: block;
                        color: $color-white;
                    }
                }

                &--editable {
                    @extend .icon-edit;
                    font-family: 'iconfont';
                    background-color: $color-white;
                    border-color: $color-white;
                    font-size: 12px;
                    &:before {
                        display: block;
                        color: $wizard-progress-menu-bgcolor;
                    }
                }
            }

            &--completed {
                .wizard-progress-menu__item-title .wizard-progress-menu__item-title-text,
                .wizard-progress-menu__item-description {
                    opacity: 0.6;
                }
            }

            &--click-disabled {
                cursor: default;
            }

            &--active {
                background-color: $wizard-progress-menu-selected-bgcolor;

                .wizard-progress-menu__item-description {
                    opacity: 1;
                }

                &:before {
                    content: '';
                    border-style: solid;
                    border-width: 15px;
                    border-color: transparent $wizard-progress-menu-selected-bgcolor transparent transparent;
                    height: 0;
                    width: 0;
                    position: absolute;
                    top: 17px;
                    left: -#{$wizard-selected-arrow-width * 2};
                }
                &:after {
                    @include active-link-styles;
                }
            }
        }

        &__divider {
            position: relative;
            @include font-pn-light;
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: calc(50% - 2px); //subtract 2px because of offset given for hanging characters
                height: 0;
                width: 400px;
                border-bottom: 1px solid $color-white;
            }
            &:before {
                right: 100%;
                margin-right: 10px;
            }
            &:after {
                left: 100%;
                margin-left: 10px;
            }

            &-container {
                overflow: hidden;
                padding: $wizard-progress-menu-padding 10px;
                text-align: center;
                border-style: solid;
                border-color: transparent $wizard-progress-menu-bgcolor;
                border-width: 0 $wizard-progress-menu-padding;
            }
        }

        &__item {
            &-title {
                white-space: nowrap;

                &-text {
                    display: inline-block;
                    white-space: normal;
                    margin-left: $wizard-progress-menu-padding + $wizard-progress-icon-size;
                    margin-bottom: $spacing-sm;
                    font-size: $wizard-progress-title-size;
                    line-height: 1em;
                    vertical-align: top;
                    max-width: 175px;
                }

                &-icon {
                    display: inline-block;
                    white-space: normal;
                    font-size: $wizard-progress-title-size - 2;
                    line-height: 1em;
                    margin-left: $spacing-md;
                }
            }

            &-description {
                margin-left: $wizard-progress-menu-padding + $wizard-progress-icon-size;
                @include font-pn-light;
                font-size: 14px;
            }
        }

    }

    &__page-loader {
        position: fixed;
        left: calc(50% - 140px); // 140px = half of wizard right-nav width (center on wizard content)
        top: 50%;
        margin: 0;
        z-index: zindex__page-overlay;
        transform: translateX(-50%) translateY(-50%);
    }

}
