.label-value-pairs {
    display: inline-grid;
    grid-row-gap: $spacing-sm;
    grid-column-gap: $spacing-md;
    list-style: none;
}

.label-value-pairs__label {
    @include break-word;
    @include text-label;

    grid-column: 1;

    &:after {
        content: ':';

        padding-left: 2px;
    }
}

// IE Version
td.label-value-pairs__label {
    padding-right: $spacing-md;
}

.label-value-pairs {
    td {
        vertical-align: top;
        padding-bottom: $spacing-md;
    }
}

.label-value-pairs__value {
    @include text-value;

    grid-column: 2;

}

.label-value-pairs__help-hint {
    padding-left: $spacing-sm;
    vertical-align: middle;
}

.label-value-pairs__divider {
    @extend .hr;
    @extend .hr--xs;
    @extend .hr--solid;

    grid-column-start: 1;
    grid-column-end: span 2;
}

.label-value-pairs__title {
    @include parent-label;

    grid-column-start: 1;
    grid-column-end: span 2;
}

* + .label-value-pairs__title {
    padding-top: $spacing-xs;
}
