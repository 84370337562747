
body {
    @include text-body;

    line-height: $line-height-default;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: $color-link-text;

    &:hover {
        text-decoration: underline;
        color: $color-active-blue-highlighted;
    }
    &.link {
        border-bottom: 1px solid  $color-active;
        padding-bottom: 0;
        text-transform: uppercase;
    }

    &.disabled {
        opacity: $inactive-opacity;

        &:hover {
            text-decoration: none;
            color: $color-link-text;
            cursor: default;
        }
    }
}

h1,
h2,
h3,
h4,
h5 {
    color: $color-slate;
}

h1 {
    font-size: 24px;
    font-weight: 400;
    margin: 25px 0;
}

h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 10px;
}

h3 {
    font-size: 16px;
    margin: 0 0 10px;
}

p {
    margin: 0 0 1em;
    @include font-pn-regular;
    font-size: 14px;
}

strong {
    font-weight: bold;
    @include font-pn-bold;
}

i {
    font-style: italic;
}

ul.ul,
ol.ol {
    margin: 5px 0 15px 20px;

    li {
        margin: 0 0 15px;
    }
}

ul.ul {
    li {
        list-style: disc;
    }
}

ol.ol {
    li {
        list-style: decimal;
    }
}
