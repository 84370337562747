
@mixin clearfix {
    *zoom: 1; // For IE 6/7 only

    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
}

.clearfix {
    @include clearfix();
}
