// these mixins should be replaced by centralized text styles
@mixin text-parent-label {
    color: $color-elephant;
    @include font-pn-semibold;
    font-size: 14px;
    text-transform: uppercase;
}

.text-value {
    @include text-value;
}

.policy-node {
    margin-bottom: $spacing-sm;
    position: relative;

    &--parent {
        margin-bottom: $spacing-md;
    }

    &:last-child,
    &--parent:last-child {
        margin-bottom: 0;
    }

    &__head {
        margin-bottom: $spacing-sm;
        display: flex;
        align-items: center;
    }

    &--parent > * > &__label {
        @include text-parent-label;
    }

    &__label {
        @include text-label;
    }

    &__edit {
        margin-left: $spacing-sm;
    }

    &__content {
        padding-left: $spacing-lg;
        position: relative;
    }

    $height: 19px;
    $offset: 2px;

    &__icon,
    &__number,
    &__character {
        border-radius: 20px;

        background: $color-blue-grey;
        color: $color-white;
        @include font-pn-regular;
        font-size: 15px;
        display: inline-block;
        height: $height;
        line-height: $height;
        margin: 0 1px + $spacing-xs 0 0;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: $height;

        &:before {
            font-family: $icon-font;
            font-size: 12px;
            line-height: 12px;
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            left: 0;
            right: 0;
            text-align: center;
        }

        &.icon-check {
            background: $color-success;

            &:before {
                font-size: 8px;
            }
        }
    }

    &__character,
    &__number {
        height: $height - $offset;
        line-height: $height - $offset;
        padding-top: $offset;
    }

    &__character {
        background: $color-warning-icon-yellow;
    }

    &__number {
        background: $color-midnight;
        font-size: 13px;
    }

    &__number--unindented {
        margin-left: -25px;
    }

    &__button {
        float: right;
        margin: -5px 0 0;
    }

    &__gutter-arrow {
        position: absolute;
        top: 0;
        left: 9px;
        bottom: 0;
        width: 19px;
        border-left: 1px solid $color-rabbit-fur;

        &:after {
            content: '';
            position: absolute;
            border: 5px solid transparent;
            border-top-color: $color-rabbit-fur;
            bottom: -5px;
            left: -5px;
        }
    }
}
