.chart-wrapper {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__chart {
        max-height: 100%;
    }

    &__loader,
    &__message {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 12; //have to be above rocker labels
    }
    &__message {
        pointer-events: none;
        text-transform: uppercase;
    }
    &__loader {
        background: rgba($color-white, 0.6);
    }

    &__info {
        margin-bottom: auto;
    }
    &__controls {
        margin-top: auto;
    }

}
