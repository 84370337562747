// STYLING ----------------------------------------------------------------------------------------

@mixin inactive-link-styles {
    transition: transform 0.2s ease-out;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-active;
    transform: scaleX(0);
    visibility: hidden;
}

@mixin active-link-styles {
    transition: transform 0.1s ease-out;
    transform: scaleX(1);
    visibility: visible;
}

@mixin input-style-light {
    background-color: $color-white;
    border: 1px solid $color-strong-neutral-70;
}

@mixin input-background-light {
    background-color: $color-white;
}

@mixin input-background-dark {
    background-color: $color-input-fill;
}

@mixin input-style-base {
    @include input-background-dark;

    color: $color-input-text;
    border: 1px solid $color-input-fill;
    font-size: 14px;

    &::placeholder {
        @include text-placeholder;
    }
}

@mixin input-style {
    @include input-style-base;

    .modifier_light-inputs & {
        @include input-style-light;
    }

    .modifier_light-inputs .modifier_dark-inputs & {
        @include input-style-base;
    }
}

@mixin input-background {
    background-color: $color-input-fill;

    .modifier_light-inputs & {
        @include input-background-light;
    }

    .modifier_light-inputs .modifier_dark-inputs & {
        @include input-background-dark;
    }
}

@mixin style-inputs-light {
    .input-textfield input,
    .input-textarea textarea,
    .input-multivalues .entries {
        @include input-background-light;
        @include input-style-light;
    }
}


// TEXT -------------------------------------------------------------------------------------------

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin placeholder-css(
    $color,
    $font-weight,
    $padding-top,
    $font-style: italic
) {
    ::placeholder {
        color: $color;
        font-weight: $font-weight;
        padding: $padding-top 3px 0 0;
        font-style: $font-style;
    }
}

@mixin break-word {
    overflow-wrap: break-word;
    // Added this line for IE, which won't wrap inline elements without a width.
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;
}

@mixin bold-text {
    text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
}

@mixin semi-bold-text {
    text-shadow: 0 0 0.4px #333, 0 0 0.4px #333;
}

// ANIMATION --------------------------------------------------------------------------------------

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin fadein-keyframes {
    @include keyframes('fade-in') {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

@include fadein-keyframes; // animation keyframes only need to be declared once and then can be used globally

@mixin slidedown-keyframes {
    @include keyframes('slide-down') {
        0% {
            transform: translateY(-100%);
        }
        100% {
            opacity: translateY(-100%);
        }
    }
}

@include slidedown-keyframes;


// NUMERIC -----------------------------------------------------------------------------------------

// Power function : @param {Number} $x, @param {Number} $n, @return {Number}
@function pow($x, $n) {
    /* stylelint-disable */
    $ret: 1;
    @if $n >= 0 {
        @for $i from 1 through $n {
            $ret: $ret * $x;
        }
    } @else {
        @for $i from $n to 0 {
            $ret: $ret / $x;
        }
    }
    @return $ret;
    /* stylelint-enable */
}

@function to-fixed($float, $digits: 2) {
    $sass-precision: 5;

    @if $digits > $sass-precision {
        @warn 'Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now.'
            + 'The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`.'
            + 'See https://github.com/sass/sass/issues/1122 for further informations.';
    }

    $pow: pow(10, $digits);

    @return round($float * $pow) / $pow;
}


// COLOR ------------------------------------------------------------------------------------------

@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}


// MISC --------------------------------------------------------------------------------------------

@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin grab-cursor {
	/* stylelint-disable */
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
	/* stylelint-enable */
}

@mixin button-spacing($spacing: 10px) {
    > .details-tooltip .details-target button {
        margin: 0;
    }

    > input[type=button],
    > button,
    > .button,
    > .details-tooltip {
        margin: 0;

        & ~ input[type=button],
        & ~ button,
        & ~ .button,
        & ~ .details-tooltip {
            margin-left: $spacing;
        }
    }
}

@mixin arrow($arrow-size) {
    &:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-width: $arrow-size;
        border-style: solid;
        border-color: inherit;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        content: '';
    }
}

// POPOVER/TOOLTIP/HELPHINT POINTERS
@mixin makePointer($height, $fill, $direction: down) {
    width: $height * 2;
    height: $height * 2;
    box-sizing: border-box;
    border: $height solid transparent;
    position: absolute;

    @if $direction == down {
        border-top-color: $fill;
    }
    @else if $direction == right {
        border-left-color: $fill;
    }
    @else if $direction == left {
        border-right-color: $fill;
    }
    @else {
        border-bottom-color: $fill;
    }
}

@mixin makeBorderedPointer($height, $fill, $border, $direction: down) {
    @include makePointer($height, $border, $direction);

    &:after {
        content: '';

        @include makePointer($height, $fill, $direction);

        @if $direction == down {
            left: -$height;
            bottom: 1px - $height;
        }
        @else if $direction == right {
            bottom: -$height;
            right: 1px - $height;
        }
        @else if $direction == left {
            bottom: -$height;
            left: 1px - $height;
        }
        @else {
            left: -$height;
            top: 1px - $height;
        }
    }
}

// DEPRECATED OR SITE-SPECIFIC --------------------------------------------------------------------

@mixin keyframe ($animation-name) {
    @include keyframes($animation-name) {
        @content;
    }
}
