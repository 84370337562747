
button.message-button,
button.message-button.inline {
    display: inline-flex;
    align-items: center;
    line-height: 1em;
    transition: all 0.1s ease-out;



    // !important used to override disabled button styling

    &--loading,
    &--success,
    &--error {
        padding-left: $spacing-md;
        padding-right: $spacing-md;
        opacity: 1 !important;
    }


    //This is weird, but helps center the icons
    &--success,
    &--error {
        .button__text {
            display: inline-flex;
        }
    }

    &--loading {
        background: transparent;
        border-color: $color-neutral-50 !important;
        color: $color-accent-neutral-10;

        .icon-ellipsis {
            margin: 0 $spacing-md;

            &:before,
            &:after,
            span {
                background-color: $color-accent-neutral-10;
            }
        }

        &.primary {
            .icon-ellipsis {
                &:before,
                &:after,
                span {
                    background-color: $color-white;
                }
            }
        }
    }
    &--success,
    &--success:hover {
        border-color: $color-success-green !important;
        color: $color-success-green !important;
    }
    &--error,
    &--error:hover {
        border-color: $color-critical-red !important;
        color: $color-critical-red !important;
    }
    .iconComponent {
        font-size: 16px !important;

        &__content {
            line-height: 1em;
        }
    }
}
