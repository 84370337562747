$font-size-normal: 14px;
$font-size-primary: 15px;
$font-size-title: 24px;

$topnav-height: 46px;
$topnav-bg: $color-neutral-10;

@mixin displayFlexRow {
    /* stylelint-disable declaration-block-no-duplicate-properties */
    display: flex;
    display: -ms-flexbox;
    /* stylelint-enable declaration-block-no-duplicate-properties */
    flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    -ms-flex-align: center;
    -ms-flex-pack: justify;
}

.header-bar {
    @include displayFlexRow;

    position: relative;
    background: $topnav-bg;
    height: $topnav-height;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
}

.header-bar__left,
.header-bar__right {
    @include displayFlexRow;

    flex-basis: 50%;
    white-space: nowrap;
}

.header-bar__left {
    justify-content: flex-start;
}

.header-bar__left > * {
    margin-right: $spacing-md;

    &.last-child {
        margin-right: 0;
    }
}

.header-bar__right {
    justify-content: flex-end;
}

.header-bar__center {
    flex-grow: 1;
}

.header-bar__ping-logo {
    $logo-width: 52px;
    $logo-height: 52px;
    $logo-env-pad: 5px;

    height: $logo-height;
    width: $logo-width;

    background-image: url('#{$img-path}logo-pingidentity.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    transform: translateY(($logo-height - $topnav-height) / 2);
}

.header-bar__site-logo,
.header-bar__pingone-logo {
    max-width: 150px;
}

.header-bar__site-logo {
    height: $topnav-height - 25px;
}

.header-bar__pingone-logo {
    fill: $color-white;
}

.header-bar__site-title,
.header-bar__additional-content {
    color: $color-white;
}

.header-bar__site-title {
    @include font-pn-regular;
    font-size: 20px;
    height: 20px;
}

.header-bar__additional-content {
    @include font-pn-light;
    margin-bottom: 1px;
}

.header-bar__mode {
    $mode-color: #38c0d1; //teal
    &-bar {
        background-color: $mode-color;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 4px;
    }
    &-title {
        border: 1px solid $mode-color;
        border-radius: 3px;
        font-size: 11px;
        color: $color-white;
        padding: 0 $spacing-sm;
        height: 20px;
        line-height: 20px;
    }
}

.market-selector.input-custom-select {
    $market-selector-height: 25px;

    margin: 0;
    vertical-align: middle;

    .input-container {
        height: $market-selector-height;

        .input-text.readonly input.selected-option-label {
            color: $color-white;
            @include font-pn-regular;
            font-size: $font-size-normal - 1px;

            border-radius: 2px;

            background: $color-strong-neutral-10;
            border: 0;
            height: $market-selector-height;
            line-height: $market-selector-height;
            padding-bottom: 0;
            padding-left: $spacing-sm;
            padding-top: 0;
            white-space: nowrap;

            &:after {
                color: $color-white;
                font-size: 10px;
                right: $spacing-sm;
                top: 3px;
            }

            &:hover {
                background: shade($color-strong-neutral-10, 20%);
            }
        }

        .wrapper {
            height: $market-selector-height;

            &:after {
                color: $color-white;
                font-size: 10px;
                right: $spacing-sm;
                top: 3px;
            }
        }
    }
}

.header-nav {
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: center;
    -ms-flex-align: center;
    line-height: $topnav-height;
    text-align: center;
}

.header-nav__item {
    background: none;
    border: none;
    color: $color-white;
    cursor: pointer;
    display: block;
    @include font-pn-light;
    font-size: $font-size-normal - 1px;
    height: auto;
    line-height: inherit;
    margin: 0;
    padding: 0 $spacing-lg;
    position: relative;
    text-transform: uppercase;

    &:hover {
        color: $color-white;
        text-decoration: none;
    }
}

@media (max-width: 1000px) {
    .header-nav__item {
        padding: 0 $spacing-md;
    }
}

.header-nav__item-label {
    padding-bottom: 2px;
    position: relative;

    &:after {
        background: $color-white;
        bottom: -2px;
        content: '';
        height: 1px;
        left: 50%;
        position: absolute;
        right: 50%;
        transition: 0.1s;

        // On some displays, the after gets a non-zero width and shows a single pixel.
        visibility: hidden;
    }
}

.header-nav__item:hover .header-nav__item-label:after {
    right: 0;
    left: 0;
    transition: 0.05s;
    visibility: visible;
}

.header-nav__item--selected .header-nav__item-label {
    @include font-pn-semibold;

    &:after {
        height: 2px;
        left: 0;
        right: 0;
        transition: 0.05s;
        visibility: visible;
    }
}

.environment-selector {
    & &__link {
        color: $color-neutral-90;
        @include font-pn-regular;
        font-size: $font-size-normal;

        &.right:after {
            color: $color-neutral-90;
            top: auto;
            bottom: 0;
        }

        &:hover,
        &.right:hover:after {
            color: $color-white;
            text-decoration: none;
        }
    }

    border: 1px solid $color-neutral-30;
    border-style: none solid;
    line-height: 30px;
    margin-left: $spacing-sm;
    padding: 0 $spacing-sm;
    vertical-align: middle;

    // compensate for this class being passed on
    // to DetailsTooltip when using portals
    &.popper-container {
        border: none;
        padding: 0;
        line-height: auto;
    }
}

.environment-selector__icon {
    display: inline-block;
    margin-right: 10px;
}

$item-size: 20px;

.product-nav {
    @include displayFlexRow;
}

.product-nav__item {
    font-size: $item-size;
    height: 30px;
    padding: 0 $spacing-md;
    text-align: center;
    border-left: 1px solid $color-neutral-30;
    line-height: 30px;

    &:first-child {
        border-left: none;
    }
    &:last-child {
        padding-right: 0;
    }
}

.product-nav__link {
    display: inline-block;
    color: $color-elephant;

    &:hover {
        text-decoration: none;
    }

    &:hover,
    &.active {
        color: $color-white;
    }
}

.product-nav__icon {
    display: inline-block;
    height: $item-size;
    vertical-align: middle;
    width: $item-size;
}

.product-nav__img {
    height: auto;
    width: auto;
    max-height: $item-size;
    max-width: $item-size;
}

.product-nav__menu-trigger,
button.product-nav__menu-trigger {
    background: none;
    border: none;
    color: $color-neutral-50;
    cursor: pointer;
    display: block;
    font-size: 20px;
    padding: 0;

    &:hover,
    &.active {
        color: $color-white;
    }
}
