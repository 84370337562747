$condensedBreakpoint: 1200px;
$fullwidthBreakpoint: 950px;

@mixin standoutText {
    color: $color-white;
    background: $color-charting-indigo;

    .stat-card--magenta & {
        background: $color-charting-magenta;
    }

    .stat-card--blue & {
        background: $color-charting-blue;
    }

    .stat-card--cyan & {
        background: $color-charting-cyan;
    }
}

@mixin flippedTo {
    transform: rotateY(0deg);
}

@mixin flippedAway {
    transform: rotateY(180deg);
}

@mixin flipper {
    transition: all 0.2s ease-in-out;
    backface-visibility: hidden;
}

@mixin cardFrame {
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    background: $color-white;
    border: 1px solid $color-neutral-70;
}

.dashboard-card {
    display: flex;

    position: relative;
    max-width: 100%;
    min-width: 220px;

    > * {
        flex-grow: 1;
        max-width: 100%;
    }

    &__control {
        position: absolute;
        right: $spacing-sm;
        top: $spacing-sm;
    }

    &__front {
        @include cardFrame;
        @include flipper;
        @include flippedTo;
        display: flex;
        flex-direction: column;

        box-sizing: border-box;
        padding: $spacing-xl $spacing-lg;
        min-height: 227px;
        overflow-x: auto;

        @media (max-width: $condensedBreakpoint) {
            padding: $spacing-xl $spacing-md;
        }
    }

    &--flipped &__front {
        @include flippedAway;
    }

    &__back {
        @include cardFrame;
        @include flipper;
        @include flippedAway;

        padding: $spacing-md;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        @media (max-width: $condensedBreakpoint) and (min-width: $fullwidthBreakpoint) {
            padding-top: $spacing-xl;
        }
    }

    &--padding-sm {
        .dashboard-card__front,
        .dashboard-card__back {
            padding: $spacing-sm;
        }
    }

    &--padding-md {
        .dashboard-card__front,
        .dashboard-card__back {
            padding: $spacing-md;
        }
    }

    &--padding-lg {
        .dashboard-card__front,
        .dashboard-card__back {
            padding: $spacing-lg;
        }
    }

    label.dashboard-card__make-default {
        position: absolute;
        bottom: 21px;
        left: 21px;
        margin: 0;

        .label-text {
            color: $color-elephant;
        }
    }

    &--flipped &__back {
        @include flippedTo;
    }


    &__title {
        @include break-word;
        font-size: 18px;
        text-transform: uppercase;
        color: $color-neutral-10;
        display: flex;
        justify-content: center;
        word-break: break-word;

        &--donut,
        &--multiseries,
        &--horizontal-bar-card {
            @include break-word;
            margin: -24px 50px 6px;
        }

        &--frequency-card {
            margin-top: -20px;
            margin-bottom: 6px;
        }

        &--heatmap {
            margin-bottom: 10px;
        }
    }

    &__title-accessories {
        display: flex;
        padding-left: 5px;
    }


    &__title-icon {
        margin-right: $spacing-sm;
        line-height: 25px;
        display: inline-block;
        vertical-align: middle;

        font-size: 30px;

        color: $color-charting-indigo;

        @media (max-width: $condensedBreakpoint) and (min-width: $fullwidthBreakpoint) {
            display: none;
        }
    }

    &__description,
    &__subtitle {
        font-size: 12px;
        text-align: center;
        color: $color-neutral-10;
    }

    &__chart {
        margin-left: 50%;
        transform: translateX(-50%);
    }

    &__range-selector {
        transform: translateX(-50%);
        margin: 0 0 0 50%;
        white-space: nowrap;

        label {
            float: none;
        }
    }

    &__stat-list {
        padding: 0 $spacing-md;
    }

    &__stat-row {
        display: flex;
        flex-direction: row;

        > * {
            flex-grow: 1;
        }
    }

    &--back-title {
        @include standoutText;

        display: inline-block;
        @include font-pn-light;
        font-size: 16px;
        margin-bottom: $spacing-md;
        padding: $spacing-xs $spacing-sm;
        text-transform: uppercase;
        margin-right: 55px;
    }

    &__stat-row-label {
        font-size: 14px;
        color: $color-strong-neutral-30;
        margin-bottom: $spacing-sm;
        text-align: left;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__stat-row-number {
        text-align: right;
        font-size: 16px;
        color: $color-charting-indigo;
    }

    &__error {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        color: $color-neutral-10;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) !important;
        margin: 0 !important;
    }

    &__error-text {
        font-size: 14px;
        line-height: 1.3em;
        max-width: 200px;
    }

    &__error-icon {
        font-size: 60px;
        margin: 0 0 $spacing-md;
    }

    &--magenta {
        .dashboard-card__title-icon,
        .dashboard-card__stat-row-number,
        .stat-area-card__value {
            color: $color-charting-magenta;
        }
        .dashboard-card--back-title,
        .stat-card__primary-number {
            background-color: $color-charting-magenta;
        }
    }

    &--blue {
        .dashboard-card__title-icon,
        .dashboard-card__stat-row-number,
        .stat-area-card__value {
            color: $color-charting-blue;
        }
        .dashboard-card--back-title,
        .stat-card__primary-number {
            background-color: $color-charting-blue;
        }
    }

    &--cyan {
        .dashboard-card__title-icon,
        .dashboard-card__stat-row-number,
        .stat-area-card__value {
            color: $color-charting-cyan;
        }
        .dashboard-card--back-title,
        .stat-card__primary-number {
            background-color: $color-charting-cyan;
        }
    }

}

// TODO: Take this out because it's shameful and wrong. Just here as a stopgap,
// since we're replacing the DashboardCards with a grid system soon.
.dashboard-card {
    .chart-wrapper {
        height: 100%;
    }
}

.dashboard-card-row {
    margin-bottom: $spacing-md;

    &--content-right {
        justify-content: right;
    }
    &--content-left {
        justify-content: left;
    }
    &--content-center {
        justify-content: center;
    }

    > * {
        margin-bottom: $spacing-md;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: $fullwidthBreakpoint) {
        > .dashboard-card {
            //using important to override user defined maxwidth because cards should always be 100% at this point
            max-width: 100% !important;
        }
    }

    @media (min-width: $fullwidthBreakpoint) {
        display: flex;
        flex-direction: row;

        align-items: stretch;

        > * {
            flex-grow: 1;

            &.dashboard-card--double {
                flex-grow: 2;
                flex-basis: 1em;
            }

            flex-basis: 0;
            margin-bottom: 0;
            margin-right: $spacing-md;

            &:last-child {
                margin-right: 0;
            }
        }

    }
}

.stat-card {

    &__front-title {
        display: flex;
        flex-grow: 1;

        align-items: center;
        justify-content: center;
    }

    &__primary-number {
        @include standoutText;

        font-size: 40px;
        text-align: center;
        margin: $spacing-md 0 $spacing-lg 50%;
        padding: $spacing-sm 0 $spacing-xs;
        line-height: 1em;
        min-width: 200px;
        transform: translateX(-50%);
    }
    &__description {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
    }

}

.stat-area-card {
    min-height: 280px;

    &__front-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        white-space: nowrap;
        @media (min-width: $condensedBreakpoint), (max-width: $fullwidthBreakpoint) {
            height: 31px;
            margin-bottom: 0;
        }
    }

    &__value {
        display: flex;
        justify-content: center;
        color: $color-neutral-10;
        font-size: 50px;
        line-height: 1em;
        min-height: 1em;
        @include font-pn-semibold;
    }

    &__no-data-message {
        position: absolute;
        bottom: 0;
        left: 15px;
        height: 55px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__chart {
        position: absolute;
        left: 0;
        bottom: 55px;
    }

    &__range-selector {
        position: absolute;
        left: 50%;
        bottom: $spacing-md;
        transform: translateX(-50%);
        margin: 0;
    }
}

.dashboard-card--ghost {
    .dashboard-card__front {
        border-color: $color-neutral-90;
    }
}

.placeholder-card {
    min-height: 307px;

    &__content {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        max-width: 200px;

        svg {
            width: 50px;
        }
        path {
            fill: $color-text;
        }
    }
    &__message {
        margin-top: $spacing-lg;
        color: $color-text;
        text-indent: 5px;
    }
}

.donut-card {
    .dashboard-card__front {
        align-items: center;
    }

    &__center-info {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -165%);
        text-align: center;
        color: $color-neutral-10;
        z-index: 10;
    }

    &__front-line {
        border-bottom: 2px solid $color-neutral-10;
        width: 70px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    &__center-number {
        position: relative;
        font-size: 28px;
        font-weight: 600;
        color: $color-accent-neutral-10;
        top: -15px;
    }

    &__center-label {
        position: relative;
        font-size: 13px;
        bottom: 24px;
        text-transform: uppercase;
    }

    &__back-info {
        display: flex;
        justify-content: space-between;
        height: 30px;
    }

    &__scroll {
        height: 90px;
        width: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
        display: inline-block;
    }

    &__drop-down {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        bottom: 21px;
        margin: inherit;
        .input-container {
            width: 135px;
        }
    }

    &__back-line {
        padding-top: 20px;
        &:after {
            content: '';
            display: block;
            border-bottom: 1px solid $color-neutral-90;
        }
    }

    &__donut-chart {
        top: 10px;
    }
}

.clearstuff {
    opacity: 0.5;
}

.column-chart,
.pie-chart {
    &__tooltip {
        border-width: 1px;
        border-color: $color-neutral-95;
        padding: $spacing-sm;
        background: $color-white;
        border-radius: 3px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.11);
    }
}

.column-chart__line {
    pointer-events: none;
}

.column-chart-wrapper {
    &__rocker {
        margin-top: $spacing-sm;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}

.pie-chart {
    display: flex;
    position: relative;
    margin: -20px 0;
    justify-content: center;

    &__graph {
        display: inline-block;
    }

    &__cell {
        &--hovered {
            animation-name: pulse;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }
    }

    &__tooltip {
        &-top {
            margin-bottom: $spacing-sm;
        }
    }

    &__center {
        &-info {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: $color-neutral-10;
        }

        &-label {
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;
            margin-bottom: $spacing-xs;
        }

        &-value {
            font-size: 26px;
            line-height: 26px;
            font-weight: 600;
            color: $color-accent-neutral-10;
        }
    }
}

.frequency-card {
    overflow: visible;
    display: block;
    min-height: 307px;

    &__title {
        margin-bottom: -24px;
    }

    .recharts-layer.recharts-bar-rectangle .recharts-rectangle {
        stroke-width: 0;
    }

    .recharts-layer.recharts-bar-rectangle .recharts-rectangle:hover {
        cursor: pointer;
        stroke-width: 4;
        transform: translateY(-1px);
        transition: all 0.3s;
    }

    .recharts-layer.recharts-bar {
        .recharts-bar-rectangle:last-child {
            opacity: 0.75;
        }
    }

    .dashboard-card__front {
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .dashboard-card__back {
        position: absolute;
        overflow: visible;
        display: block;
    }


    &__holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 25px;
        justify-content: center;
        align-content: center;
    }

    &__nav-holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__wrapper {
        display: inline-block;
        position: relative;
        flex-grow: 1;
    }

    &__center-info {
        position: absolute;
        top: 95px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: $color-neutral-10;
    }

    &__front-line {
        border-bottom: 2px solid $color-neutral-10;
        width: 70px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    &__center-number {
        position: relative;
        font-size: 35px;
        bottom: 10px;
    }

    &__center-label {
        position: relative;
        font-size: 12px;
        top: 2px;
    }

    &__back-info {
        display: flex;
        justify-content: space-between;
        height: 30px;
    }

    &__drop-down {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        bottom: 21px;
        margin: inherit;

        .input-container {
            width: 135px;
        }
    }

    &__back-line {
        padding-top: 20px;

        &:after {
            content: '';
            display: block;
            border-bottom: 1px solid $color-neutral-90;
        }
    }

    &__donut-chart.recharts-wrapper {
        .recharts-tooltip-wrapper {

            // Make tooltip transparent to mouse events
            pointer-events: none;
        }
    }
}

.legend {
    &__aside {
        margin-left: 40px;
        margin-top: -20px;
        width: 200px;
    }

    &__aside-label {
        font-size: 15px;
        color: $color-neutral-10;
        margin-bottom: 5px;
    }

    &__aside-key-color {
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    &__aside-key-label {
        display: inline-block;
        font-size: 13px;
        line-height: 21px;
        color: $color-neutral-10;
        margin-left: 15px;
        white-space: nowrap;

        // So text doesn't shift when bolded on hover
        &:after {
            display: block;
            content: attr(title);
            font-weight: 600;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

    &__aside-keys {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__aside-keys-revese {
        flex-direction: column-reverse;
    }

    &__aside-key {
        padding: 5px 0;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-content: center;
    }

    &__aside-key-selected {
        font-weight: 600;
    }
}

.frequency-tooltip {
    border-radius: 4px;
    background-color: $color-white;
    border: 1px solid #d5d8db;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 12px;

    &__title {
        color: #4b637c;
        font-weight: bold;
    }

    &__data {
        color: #4b637c;
    }

    &__link {
        color: #4b637c;
    }
}

.heatmap-card {
    position: relative;
    text-align: center;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap-reverse;
        @media (max-width: $fullwidthBreakpoint) {
            justify-content: left;
        }
    }

    &__chart-container {
        max-width: 570px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__chart {
        overflow-x: auto;
    }

    &__data {
        color: $color-neutral-10;
        white-space: initial;
        margin: 0 0 $spacing-lg $spacing-lg;
        @media (max-width: $fullwidthBreakpoint) {
            flex-grow: 1;
        }
    }

    &__value,
    &__subtitle {
        display: inline-block;
        vertical-align: middle;
    }
    &__value {
        font-size: 54px;
        line-height: 54px;
        @include font-pn-semibold;
        text-align: right;
    }
    &__subtitle {
        font-size: 18px;
        line-height: 1.3em;
        text-transform: uppercase;
        text-align: left;
        padding: 0 0 0 $spacing-sm;
    }

    &__range-selector {
        margin: $spacing-lg 0 0;
    }

    .dashboard-card__front {
        overflow-y: hidden;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}


.horizontalBar-card {

    &__horizontal-card {
        padding-right: 50px;
        margin-right: 10px;
    }

    &__number-container {
        display: inline-block;
        transform: translateY(-50%);
    }

    &__total-number {
        margin-bottom: 20px;
        text-align: center;
        font-size: 54px;
        color: $color-neutral-10;
    }

    &__label {
        font-size: 18px;
        text-align: center;
        margin-bottom: 35px;
        text-transform: uppercase;
        color: $color-neutral-10;
    }

    &__drop-down {
        display: flex;
        justify-content: center;
        top: -22px;
        margin: inherit;
    }

    &__back-line {

        &:after {
            content: '';
            display: block;
            border-bottom: 1px solid $color-neutral-10;
            width: 220px;
        }
    }

    &__bar {
        transform: translateX(1.5px);
    }

    &__scroll {
        height: 225px;
        overflow-y: scroll;
        overflow-x: hidden;
        display: inline-block;
    }

    .recharts-label {
        fill: $color-neutral-10;
    }

    .recharts-surface {
        padding-left: 15px;
        overflow: visible;
    }

    .dashboard-card__front {
        min-height: 307px;
        text-align: center;
        white-space: nowrap;
    }

    .recharts-cartesian-axis-line {
        stroke-width: 3px;
    }

    .recharts-cartesian-axis-ticks {
        text-transform: uppercase;
    }

}
