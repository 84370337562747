.column-selector {
    &__search {
        width: 100%;

        & .search {
            width: 50%;
        }
    }

    &__columns {
        display: inline-flex;
        width: 100%;
    }

    &__column {
        flex-grow: 1;
        width: 50%;

        &:first-of-type {
            margin-right: $spacing-md;
        }

        &:not(:first-of-type) {
            margin-left: $spacing-md;
        }
    }

    &__column-children {
        border-top: 1px solid $color-neutral-80;
        margin-top: $spacing-sm;
        padding-top: $spacing-md;

        & > :not(:last-of-type) {
            margin-bottom: $spacing-md;
        }
    }

    &__column-subtitle {
        color: $color-accent-neutral-40;
        font-size: 12px;
        margin-left: $spacing-sm;
        text-transform: none;
    }

    &__column-title {
        color: $color-accent-neutral-20;
        display: inline-flex;
        margin-right: $spacing-sm;
        text-transform: uppercase;

        & > :not(:first-child) {
            margin-left: $spacing-sm;
        }
    }

    &__drop-placeholder {
        height: 100%;
        width: 100%;
    }

    &__row {
        background-color: $color-white;
        flex-grow: 1;

        &--open,
        &--closed {
            position: relative;

            &:hover {
                border-color: $color-neutral-50;
            }
        }

        &--disabled {
            background-color: transparent;
            cursor: default;
        }
    }

    &__row-button {
        margin-left: auto;

        &--expandable {
            background-color: $color-midnight;
            color: $color-white;

            &:hover {
                background-color: $color-slate;
                border-color: $color-slate;
                color: $color-white;
            }
        }
    }

    &__row-container {
        border: 1px solid $color-neutral-80;

        &:hover {
            border-color: $color-strong-neutral-40;
        }
    }

    &__row-content {
        align-items: center;
        display: inline-flex;
        justify-content: flex-start;
        padding: $spacing-sm $spacing-sm $spacing-sm 0;
        width: 100%;

        // This makes sure that custom buttons are aligned correctly.
        & > *:last-child {
            margin-left: auto;
        }
    }

    &__row-options {
        position: relative;

        &:after {
            background-image: linear-gradient(to bottom, $color-neutral-80, $color-white);
            border-top: 1px solid $color-neutral-90;
            content: '';
            height: 5px;
            position: absolute;
            top: 0;
            width: 100%;
        }

        & .column-selector {
            &__row-container {
                border: none;
                border-bottom: 1px solid $color-neutral-80;

                &:last-of-type {
                    border-bottom: none;
                }
            }

            &__row {
                & .column-selector__row-title {
                    font-weight: unset;
                    text-transform: unset;
                }
            }

            &__row-title-icon {
                margin-left: $spacing-xl;
            }
        }
    }

    &__row-subtitle {
        @include break-word;
        color: $color-strong-neutral-60;
    }

    &__row-title {
        @include break-word;
        align-items: center;
        color: $color-strong-neutral-40;
        display: inline-flex;
        font-weight: bold;
        justify-content: flex-start;
        text-transform: uppercase;

        & > *:not(:last-child) {
            margin-right: $spacing-sm;
        }
    }

    &__row-title-icon {
        align-items: center;
        display: flex;
        margin-left: $spacing-xs;
        width: 25px;
        font-size: 15px;
    }

    &__row-titles-panel {
        border-top: solid $color-neutral-80 0.5px;
        margin-top: $spacing-xs;
        padding: $spacing-xs $spacing-md 0 0;
    }

    &__row-toggle {
        font-size: 10px;
    }

    &__row-toggle-container {
        align-items: center;
        background-color: $color-accent-neutral-80;
        cursor: pointer;
        display: flex;
        margin-right: $spacing-md;
        padding: 0 $spacing-xs;
    }
}
