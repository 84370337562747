.image-component {
    width: auto;

    &--auto {
        height: 100%;

        &-square {
            width: 100%;
        }
    }

    &--sm {
        height: 35px;

        &-square {
            width: 35px;
        }
    }

    &--full {
        height: 100%;
        width: 100%;
    }
}
